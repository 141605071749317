import {Theme, Toolbar, Tooltip} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import {createStyles, makeStyles} from "@material-ui/styles";
import React, {useMemo} from "react";
import {StudentId} from "../../DB/entities/entity";
import {StudentInfo} from "../../DB/entities/student";
import useRouter from "../../hooks/useRouter";
import {AdminRoutes} from "../../util/routes/routes";
import BrightButton from "../common/BrightButton";
import DataTable, {Column} from "../common/DataTable";
import {Row} from "../common/Div";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        toolbar: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        },
        dataCount: {
            marginLeft: theme.spacing(2),
        },
    });
});

interface Props {
    students: StudentInfo[];
}

interface StudentDataModel {
    id: StudentId;
    name: string;
    group: string;
    teacher: string;
    phone: string;
    birthday: string;
    auth: React.ReactNode;
}

export default function AdminStudents(props: Props) {
    const classes = useStyles();
    const router = useRouter();

    const studentColumns: Array<Column<StudentDataModel>> = [
        ["name", "Ім'я", true],
        ["group", "Група", true],
        ["teacher", "Вчитель", true],
        ["phone", "Телефон", true],
        ["birthday", "День народження", true],
        ["auth", "Підключений", false],
    ];

    const data: StudentDataModel[] = useMemo(
        () =>
            props.students.map(s => {
                return {
                    id: s.id,
                    name: s.name,
                    group: s.studentGroups.map(g => g.name).join(", "),
                    teacher: s.studentTeachers.map(t => t.name).join(", "),
                    phone: s.phone ? s.phone : "",
                    birthday: s.getBirthdayString(),
                    auth: s.authId ? (
                        <Tooltip title={s.email}>
                            <CheckIcon />
                        </Tooltip>
                    ) : (
                        ""
                    ),
                };
            }),
        [props.students],
    );

    const handleAddStudentClick = () => {
        AdminRoutes.NewStudent.navigate(router);
    };
    const onRowClicked = (rowId: StudentId) => {
        AdminRoutes.EditStudent.navigate(router, {id: rowId});
    };

    return (
        <div>
            <Toolbar className={classes.toolbar}>
                <Row>
                    <Typography id="qa-students-title" variant="h5">
                        Учні
                    </Typography>
                    <Typography id="qa-students-count" variant="h5" color="textSecondary" className={classes.dataCount}>
                        ({data.length})
                    </Typography>
                </Row>

                <BrightButton id="qa-add-student" variant="outlined" onClick={handleAddStudentClick}>
                    Додати учня
                </BrightButton>
            </Toolbar>
            <DataTable rows={data} columns={studentColumns} onRowClicked={onRowClicked} defaultOrderBy="name" />
        </div>
    );
}
