import {createStyles, makeStyles} from "@material-ui/styles";
import React from "react";
import {Redirect, Route, Switch} from "react-router";
import UserTopHeader from "../../components/common/UserTopHeader";
import {StudentModel} from "../../DB/entities/student";
import {StudentRoutes} from "../../util/routes/routes";
import StudentMainContainer from "./StudentMainContainer";

const useStyles = makeStyles(
    createStyles({
        root: {
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
        },
    }),
);

interface Props {
    student: StudentModel;
}

function StudentIndex(props: Props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <UserTopHeader mainText="Bright Student" person={props.student} />
            <Switch>
                <Route exact path={StudentRoutes.Index.route} component={StudentMainContainer} />
                <Redirect to={StudentRoutes.Index.route} />
            </Switch>
        </div>
    );
}

export default StudentIndex;
