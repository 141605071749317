import {Menu, MenuItem, Theme} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/styles";
import React, {MouseEvent, useContext, useState} from "react";
import {DbContext} from "../../context";
import {UserModel} from "../../DB/entities/user";
import useRouter from "../../hooks/useRouter";
import {auth, storage} from "../../init-firebase";
import {Index} from "../../util/routes/routes";
import {AvatarLoad} from "../AvatarLoad";
import ChangePasswordDialog from "../profile/ChangePasswordDialog";
import UserAvatar from "./UserAvatar";
import Compressor from "compressorjs";
import {v4 as uuidv4} from "uuid";

function dataURItoBlob(dataURI: string) {
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    const mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
}

const useStyles = makeStyles(({palette, spacing}: Theme) => {
    return createStyles({
        topHeader: {
            marginLeft: spacing(1),
            marginRight: spacing(1),
            height: spacing(4),
            width: spacing(4),
            backgroundColor: palette.primary.main,
            cursor: "pointer",
        },
        avatar: {
            height: spacing(4),
            width: spacing(4),
        },
    });
});

interface Props {
    user: UserModel;
}

async function updatePassword(email: string, password: string, newPassword: string): Promise<void> {
    const userCredentials = await auth.signInWithEmailAndPassword(email, password);
    if (userCredentials.user) {
        return await userCredentials.user.updatePassword(newPassword);
    }
    return Promise.reject("Cannot update password");
}

export default function UserTopHeaderAvatar(props: Props) {
    const classes = useStyles();
    const router = useRouter();
    const database = useContext(DbContext);

    const [menuAnchorEl, setMenuAnchorEl] = React.useState<Element | null>(null);
    const [dialogState, setDialogState] = useState({
        open: false,
        errorMessage: "",
    });
    const [avatarId, setAvatarId] = useState(props.user.avatar);
    const [avatarLoad, setAvatarLoad] = useState(false);

    async function compressImage(value: string | undefined): Promise<Blob | Uint8Array | ArrayBuffer | undefined> {
        if (value) {
            return await new Promise((resolve, reject) => {
                const blob = dataURItoBlob(value);
                new Compressor(blob, {
                    quality: 0.8,
                    success(result) {
                        resolve(result);
                    },
                    error() {
                        reject();
                    },
                });
            });
        }
    }

    async function saveAvatar(value: string | undefined) {
        setAvatarLoad(false);
        const image = await compressImage(value);
        const uuid = uuidv4();
        const storageRef = storage.ref().child(uuid);
        if (!image) return;
        await storageRef.put(image);
        if (avatarId)
            await storage
                .ref()
                .child(avatarId)
                .delete();
        const user = props.user;
        await database.updateStudent({...user, avatar: uuid});
        setAvatarId(uuid);
    }

    function changePassword(password: string, newPassword: string) {
        updatePassword(props.user.email, password, newPassword)
            .then(() =>
                setDialogState(prevState => {
                    return {...prevState, open: false};
                }),
            )
            .catch(er =>
                setDialogState(prevState => {
                    return {...prevState, errorMessage: er.message};
                }),
            );
    }

    function handleClick(event: MouseEvent) {
        setMenuAnchorEl(event.currentTarget);
    }

    function dialogClose() {
        setDialogState(() => {
            return {errorMessage: "", open: false};
        });
    }

    function handleClose() {
        setMenuAnchorEl(null);
    }

    function handleChangePassword() {
        handleClose();
        setDialogState(prevState => {
            return {...prevState, open: true};
        });
    }

    function handleLogoutClicked() {
        Index.navigate(router);
        auth.signOut();
        handleClose();
    }

    return (
        <>
            <div id="qa-avatar" className={classes.topHeader}>
                <UserAvatar avatar={avatarId} className={classes.avatar} onClick={handleClick} />
                <Menu
                    id="simple-menu"
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={Boolean(menuAnchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem id="qa-change-pwd" onClick={handleChangePassword}>
                        Change password
                    </MenuItem>
                    <MenuItem
                        id="qa-change-avatar"
                        onClick={() => {
                            setAvatarLoad(true);
                            setMenuAnchorEl(null);
                        }}
                    >
                        Change avatar
                    </MenuItem>
                    <MenuItem id="qa-logout" onClick={handleLogoutClicked}>
                        Log out
                    </MenuItem>
                </Menu>
            </div>
            <ChangePasswordDialog
                onPasswordChange={changePassword}
                onClose={dialogClose}
                open={dialogState.open}
                errorMessage={dialogState.errorMessage}
            />

            <AvatarLoad onSave={saveAvatar} open={avatarLoad} avatarId={avatarId} close={() => setAvatarLoad(false)} />
        </>
    );
}
