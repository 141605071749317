import {Theme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/styles";
import React from "react";
import BrightButton from "./BrightButton";

export interface Props {
    message?: string;
    buttonId: string;
    onClick: () => void;
    disabled?: Boolean;
    buttonClasses?: string;
    containerClasses?: string;
}
const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        saveButton: {
            marginLeft: theme.spacing(22),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        saveContainer: {
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
        },
        message: {
            marginLeft: theme.spacing(2),
            color: theme.palette.gray.main,
        },
    });
});

export function SaveButtonWithText(props: Props) {
    const classes = useStyles();
    return (
        <div className={classes.saveContainer}>
            <BrightButton
                id={props.buttonId}
                disabled={!!props.disabled}
                variant="outlined"
                className={classes.saveButton}
                onClick={props.onClick}
            >
                Зберегти
            </BrightButton>
            {props.message && <Typography className={classes.message}>{props.message}</Typography>}
        </div>
    );
}
