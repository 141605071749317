import React from "react";
import {Text, View, StyleSheet} from "@react-pdf/renderer";

const borderColor = "rgba(224, 224, 224, 1)";
const secondaryTextColor = "rgba(0,0,0,0.54)";

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 24,
        borderWidth: 1,
        borderColor: borderColor,
        borderRadius: 8,
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
        borderBottomWidth: 1,
        borderColor: borderColor,
    },
    description: {
        flexDirection: "column",
        flexWrap: "wrap",
        width: "70%",
        borderRightWidth: 1,
        borderColor: borderColor,
        paddingHorizontal: 8,
        paddingVertical: 4,
    },
    value: {
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "stretch",
        width: "30%",
        fontWeight: "bold",
        margin: 4,
        borderRadius: 4,
    },
});

interface Props {
    rows: TableRow[];
}

export interface TableRow {
    description: string;
    secondaryDescription: string;
    value: string;
    color?: string;
}

const TablePdf = (props: Props) => (
    <View style={styles.tableContainer}>
        <TableRows rows={props.rows} />
    </View>
);

const TableRows = (props: {rows: TableRow[]}) => {
    const rows = props.rows.map((row, index) =>
        <View style={index === (props.rows.length - 1) ? {...styles.row, borderBottomWidth: 0} : styles.row}
              key={row.description.toString()}>
            <View style={{...styles.description}}>
                <Text>{row.description}</Text>
                <Text style={{color: secondaryTextColor, fontSize: 8, paddingTop: 1}}>{row.secondaryDescription}</Text>
            </View>
            <View style={{...styles.value, backgroundColor: row.color}}>
                <Text>{row.value}</Text>
            </View>
        </View>,
    );
    return (<>{rows}</>);
};

export default TablePdf;