import {DatePicker} from "@material-ui/pickers";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {Moment} from "moment";
import React from "react";
import {BrightDateTextField} from "../BrightInput";

interface Props {
    selectedDate: Moment;
    onChange?(newDate: Moment): void;
    renderDay?(day: Moment, selectedDay: Moment, dayInCurrentMonth: boolean, dayComponent: JSX.Element): JSX.Element;
}

export default function BrightDatePicker(props: Props) {
    function handleDateChange(date: MaterialUiPickersDate) {
        if (props.onChange) {
            props.onChange(date as Moment);
        }
    }

    function handleRenderDay(
        day: MaterialUiPickersDate,
        selected: MaterialUiPickersDate,
        dayInCurrentMonth: boolean,
        dayComponent: JSX.Element,
    ): JSX.Element {
        return props.renderDay
            ? props.renderDay(day as Moment, selected as Moment, dayInCurrentMonth, dayComponent)
            : dayComponent;
    }

    return (
        <DatePicker
            disableToolbar
            format="D MMMM YYYY"
            autoOk
            TextFieldComponent={BrightDateTextField}
            variant="inline"
            value={props.selectedDate}
            onChange={handleDateChange}
            renderDay={handleRenderDay}
        />
    );
}
