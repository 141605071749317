import {EntityData} from "../../entities/entity";
import {LessonModel} from "../../entities/lesson";
import {FbLessonModelData, ToFbData, ToModel} from "../types";
import {fbTimeToMoment, momentToFbTime, removeUndefinedFields} from "./helpers";

export const fbDataToLesson: ToModel<LessonModel, FbLessonModelData> = data => {
    const res: EntityData<LessonModel> = {
        groupId: data.groupId,
        teacherIds: data.teacherIds,
        studentsAttended: data.studentsAttended,
        workDone: data.workDone,
        homework: data.homework,
        date: fbTimeToMoment(data.date),
    };
    return res;
};

export const lessonToFbData: ToFbData<LessonModel, FbLessonModelData> = lessonData => {
    const res: FbLessonModelData = {
        groupId: lessonData.groupId,
        teacherIds: lessonData.teacherIds,
        studentsAttended: lessonData.studentsAttended,
        workDone: lessonData.workDone,
        homework: lessonData.homework,
        date: momentToFbTime(lessonData.date),
    };
    return removeUndefinedFields(res);
};
