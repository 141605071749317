import {RouteComponentProps} from "react-router";
import {GroupId, Id, StudentId} from "../../DB/entities/entity";

export class Route<TParam = void> {
    public readonly route: string;

    constructor(routeName: string, ...paramSchema: Array<keyof TParam>) {
        const paramString = paramSchema.length > 0 ? `/:${paramSchema.join("/:")}` : "";
        this.route = `/${routeName}${paramString}`;
    }

    public link(params: TParam): string {
        return this.route.replace(new RegExp(":(\\w+)", "g"), (match: string) => {
            const propName = match.substring(1);
            // @ts-ignore
            const param = params[propName];
            return param ? param : match;
        });
    }

    public navigate(props: RouteComponentProps, params: TParam) {
        props.history.push(this.link(params));
    }
}

export interface GroupIdParam {
    id: GroupId;
}

export type TeacherGroupView = "lessons" | "reports";

export interface TeacherGroupParams extends GroupIdParam {
    view: TeacherGroupView
}

export interface StudentIdParam {
    id: StudentId;
}

export interface ReportIdParam {
    id: Id;
}

export const Index = new Route("");

export const EditProgressReport = new Route<ReportIdParam>("edit-progress-report", "id");

export const AuthRoutes = {
    LogIn: new Route("login"),
    ResetPassword: new Route("resetPassword"),
    Action: new Route("_auth/action:mode?"),
};

export const TeacherRoutes = {
    Index,
    Group: new Route<TeacherGroupParams>("group", "id", "view"),
    Student: new Route<StudentIdParam>("student", "id"),
    CreateReport: new Route<GroupIdParam>("create-report", "id"),
};

export const AdminRoutes = {
    Index,
    NewGroup: new Route("new-group"),
    EditGroup: new Route<GroupIdParam>("edit-group", "id"),
    Students: new Route("students"),
    NewStudent: new Route("new-student"),
    EditStudent: new Route<StudentIdParam>("edit-student", "id"),
    Users: new Route("users"),
};

export const StudentRoutes = {
    Index,
};
