import {Dialog, DialogActions, DialogContent, Theme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/styles";
import React, {useEffect, useState} from "react";
import Avatar from "react-avatar-edit";
import {Id} from "../DB/entities/entity";
import {storage} from "../init-firebase";
import BrightButton from "./common/BrightButton";

interface Props {
    open: boolean;
    avatarId: Id | undefined;
    onSave: (value: string | undefined) => void;
    close: () => void;
}

export function AvatarLoad(props: Props) {
    const [avatar, setAvatar] = useState<string>();
    const [isPreviewOn, setPreviewOn] = useState<boolean>(true);
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    const [previousAvatar, setPreviousAvatar] = useState<string>();

    useEffect(() => {
        if (props.avatarId) {
            const storageRef = storage.ref();
            storageRef
                .child(props.avatarId)
                .getDownloadURL()
                .then(function(url) {
                    const xhr = new XMLHttpRequest();
                    xhr.responseType = "blob";
                    xhr.onload = function() {
                        const blob = xhr.response;
                        const urlCreator = window.URL || window.webkitURL;
                        const imageUrl = urlCreator.createObjectURL(blob);
                        setPreviousAvatar(imageUrl);
                    };
                    xhr.open("GET", url);
                    xhr.send();
                })
                .catch(function() {});
        }
    }, [props.avatarId]);

    function onSave() {
        setPreviewOn(true);
        props.onSave(avatar);
    }

    const w = window.innerWidth;
    const h = window.innerHeight;

    return (
        <Dialog open={props.open} fullScreen={isMobile}>
            <DialogContent
                style={{justifyContent: "center", alignItems: "center", flexDirection: "column", display: "flex"}}
                dividers
            >
                {isPreviewOn && previousAvatar && (
                    <div style={{textAlign: "center", verticalAlign: "middle"}}>
                        <img src={previousAvatar} alt="Preview" />
                    </div>
                )}
                <Avatar
                    width={isMobile ? w * 0.85 : 410}
                    imageWidth={isMobile ? w * 0.85 : 410}
                    height={isPreviewOn ? 50 : isMobile ? h * 0.85 : 300}
                    imageHeight={isMobile ? h * 0.85 : 300}
                    onBeforeFileLoad={() => setPreviewOn(false)}
                    onClose={() => setPreviewOn(true)}
                    onCrop={value => setAvatar(value)}
                />
            </DialogContent>
            <DialogActions>
                <BrightButton
                    onClick={() => {
                        props.close();
                    }}
                >
                    Close
                </BrightButton>
                <BrightButton onClick={onSave}> Save</BrightButton>
            </DialogActions>
        </Dialog>
    );
}
