import {Moment} from "moment";
import {DayOfTheWeek, GroupScheduleDayAttendTime} from "./group";

export interface DaySchedule {
    day: DayOfTheWeek;
    time?: GroupScheduleDayAttendTime;
}

export function getDayLocalShortString(day: DayOfTheWeek) {
    switch (day) {
        case DayOfTheWeek.Monday:
            return "Пн";
        case DayOfTheWeek.Tuesday:
            return "Вт";
        case DayOfTheWeek.Wednesday:
            return "Ср";
        case DayOfTheWeek.Thursday:
            return "Чт";
        case DayOfTheWeek.Friday:
            return "Пт";
        case DayOfTheWeek.Saturday:
            return "Сб";
        case DayOfTheWeek.Sunday:
            return "Нд";
        default:
            return "";
    }
}

export class GroupSchedule extends Map<DayOfTheWeek, GroupScheduleDayAttendTime> {
    public static fromObject(o: Record<number, GroupScheduleDayAttendTime>): GroupSchedule {
        const map = new Map<DayOfTheWeek, GroupScheduleDayAttendTime>();

        for (const [day, atime] of Object.entries(o)) {
            const dayInt = parseInt(day, 10);
            map.set(dayInt, atime as GroupScheduleDayAttendTime);
        }

        return new GroupSchedule(map);
    }

    public copy() {
        const map = new Map<DayOfTheWeek, GroupScheduleDayAttendTime>();

        for (const entry of this) {
            map.set(entry[0], entry[1]);
        }

        return new GroupSchedule(map);
    }

    public equalsByDays(schedule: GroupSchedule): boolean {
        if (this.size !== schedule.size) {
            return false;
        }

        for (const day of this.keys()) {
            if (!schedule.has(day)) {
                return false;
            }
        }
        return true;
    }

    public getGroupDays(): string[] {
        return Array.from(this.keys()).map(k => DayOfTheWeek[k]);
    }

    public getGroupTime(): {start: Moment; end: Moment} {
        const days = Array.from(this.values());
        const firstAvailableDay = days[0];
        const start = firstAvailableDay.start;
        const end = start.clone().add(firstAvailableDay.duration);
        return {
            start,
            end,
        };
    }

    public getGroupTimeStr() {
        const {start, end} = this.getGroupTime();

        return `${start.format("HH:mm")} - ${end.format("HH:mm")}`;
    }

    public getDaysLocalShortString(): string {
        const groupDaysLocalShort = [...this.keys()].map(day => {
            return getDayLocalShortString(day);
        });
        return groupDaysLocalShort.join(", ");
    }

    public getEachDayAsArray(): DaySchedule[] {
        const allDays = new Array<DaySchedule>(
            {day: DayOfTheWeek.Monday, time: undefined},
            {day: DayOfTheWeek.Tuesday, time: undefined},
            {day: DayOfTheWeek.Wednesday, time: undefined},
            {day: DayOfTheWeek.Thursday, time: undefined},
            {day: DayOfTheWeek.Friday, time: undefined},
            {day: DayOfTheWeek.Saturday, time: undefined},
            {day: DayOfTheWeek.Sunday, time: undefined},
        );

        for (const v of this) {
            allDays[v[0]] = {
                day: v[0],
                time: v[1],
            };
        }
        return allDays;
    }

    public containsDay(day: Moment): boolean {
        for (const v of this) {
            if (momentDayEquals(day, v[0])) {
                return true;
            }
        }
        return false;
    }
}

function momentDayEquals(date: Moment, dayOfTheWeek: DayOfTheWeek): boolean {
    return momentToDay(date) === dayOfTheWeek;
}

function momentToDay(date: Moment): DayOfTheWeek {
    return date.day() === 0 ? DayOfTheWeek.Sunday : date.day() - 1;
}
