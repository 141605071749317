import {Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import createStyles from "@material-ui/styles/createStyles/createStyles";
import React, {useMemo, useState} from "react";
import {Entity, Id} from "../../DB/entities/entity";

const useStyles = makeStyles(({palette}: Theme) => {
    return createStyles({
        tableRow: {
            cursor: "pointer",
            "&:hover": {
                backgroundColor: palette.secondaryBackground.main,
            },
        },
    });
});

export type Column<T> = [/*field*/ keyof T, /*label*/ string, /*sortable*/ boolean];

interface TableRowSettings {
    color?: string
}

interface Props<T extends Entity<Id>> {
    rows: T[];
    columns: Array<Column<T>>;
    onRowClicked?(rowId: Id): void;

    defaultOrderBy: keyof T;
}

type SortFunc<T> = (v1: T, v2: T) => number;

function compare<T>(order: "asc" | "desc", orderBy: keyof T): SortFunc<T> {
    return (v1: T, v2: T) => {
        let result = 0;
        if (v1[orderBy] < v2[orderBy]) {
            result = 1;
        } else if (v2[orderBy] < v1[orderBy]) {
            result = -1;
        }

        return order === "asc" ? result : -result;
    };
}

function sortRows<T>(rows: T[], sortFunc: SortFunc<T>): T[] {
    const stabilizedThis = rows.map((el, index) => ({el, index}));
    stabilizedThis.sort((a, b) => {
        const order = sortFunc(a.el, b.el);
        if (order !== 0) {
            return order;
        }
        return a.index - b.index;
    });
    return stabilizedThis.map(v => v.el);
}

export default function DataTable<T extends (TableRowSettings & Entity<Id>)>(props: Props<T>) {
    const classes = useStyles();
    const columns = props.columns.map(c => ({
        field: c[0],
        label: c[1],
        sortable: c[2],
    }));

    const [order, setOrder] = useState<"asc" | "desc">("desc");
    const [orderBy, setOrderBy] = useState<keyof T>(props.defaultOrderBy);

    function createSortHandler(sortId: keyof T): () => void {
        return () => {
            const isDesc = orderBy === sortId && order === "desc";
            setOrder(isDesc ? "asc" : "desc");
            setOrderBy(sortId);
        };
    }

    const rows = useMemo(() => {
        return sortRows(props.rows, compare(order, orderBy));
    }, [order, orderBy, props.rows]);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {columns.map(value => (
                        <TableCell key={value.field.toString()} sortDirection={orderBy === value.field ? order : false}>
                            {value.sortable ? (
                                <TableSortLabel
                                    active={orderBy === value.field}
                                    direction={order}
                                    onClick={createSortHandler(value.field)}
                                >
                                    {value.label}
                                </TableSortLabel>
                            ) : (
                                value.label
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map(row => (
                    <TableRow
                        className={classes.tableRow}
                        key={row.id}
                        /* tslint:disable-next-line:jsx-no-lambda */
                        onClick={() => {
                            if (props.onRowClicked) {
                                props.onRowClicked(row.id);
                            }
                        }}
                    >
                        {columns.map((col, colIndex) => (
                            <TableCell style={{color: row.color}} key={colIndex}>{row[col.field]}</TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
