import {Theme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/styles";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {DbContext} from "../../context";
import {StudentId} from "../../DB/entities/entity";
import {LessonModel} from "../../DB/entities/lesson";
import {StudentModel} from "../../DB/entities/student";
import Assignment from "./Assignment";
import BrightButton from "./BrightButton";
import {Expander, Row} from "./Div";
import Splitter from "./Splitter";
import StudentAttendedCircle from "./student/StudentAttendedCircle";
import StudentsList from "./student/StudentsList";

const useStyles = makeStyles(({palette, spacing}: Theme) => {
    const elementsMargin = spacing(4);
    return {
        root: {
            backgroundColor: palette.secondaryBackground.main,
        },
        left: {
            boxSizing: "border-box",
            padding: elementsMargin,
            paddingTop: spacing(4),
        },
        right: {
            boxSizing: "border-box",
            padding: elementsMargin,
            paddingTop: spacing(0),
        },
        saveButton: {
            width: spacing(20),
        },
    };
});

interface Props {
    lesson: LessonModel;
    studentsInGroup: StudentModel[];
    onLessonChanged(lesson: LessonModel): Promise<void>;
}

function compareStudentsByName(s1: StudentModel, s2: StudentModel): number {
    return s1.name < s2.name ? -1 : 1;
}

export default function LessonView(props: Props) {
    const classes = useStyles();

    const db = useContext(DbContext);

    const [studentsAttendedLesson, setStudentsAttendedLesson] = useState<StudentModel[]>([]);
    const [homework, setHomework] = useState(props.lesson.homework);
    const [workDone, setWorkDone] = useState(props.lesson.workDone);

    useEffect(() => {
        db.fetchStudentsById(props.lesson.studentsAttended).then(ss => {
            setStudentsAttendedLesson(ss);
            setHomework(props.lesson.homework);
            setWorkDone(props.lesson.workDone);
        });
    }, [db, props.lesson]);

    const mergedStudents = useMemo(() => {
        return props.studentsInGroup
            .concat(studentsAttendedLesson.filter(sa => !props.studentsInGroup.find(s => s.id === sa.id)))
            .sort(compareStudentsByName);
    }, [props.studentsInGroup, studentsAttendedLesson]);

    function handleStudentAttendedLesson(newValue: boolean, sId: StudentId) {
        if (newValue) {
            const student = mergedStudents.find(s => s.id === sId);
            if (student) {
                setStudentsAttendedLesson([...studentsAttendedLesson, student]);
            }
        } else {
            setStudentsAttendedLesson(studentsAttendedLesson.filter(s => s.id !== sId));
        }
    }

    function handleSave() {
        props.onLessonChanged({
            ...props.lesson,
            homework,
            workDone,
            studentsAttended: studentsAttendedLesson.map(s => s.id),
        });
    }

    const studentAttended = (student: StudentModel) => {
        function attendedChanged(newValue: boolean) {
            return handleStudentAttendedLesson(newValue, student.id);
        }

        return (
            <StudentAttendedCircle
                attended={studentsAttendedLesson.some(s => s.id === student.id)}
                onAttendedChanged={attendedChanged}
            />
        );
    };

    const nothingChanged = useMemo(
        () =>
            props.lesson.homework === homework &&
            props.lesson.workDone === workDone &&
            props.lesson.studentsAttended.length === studentsAttendedLesson.length &&
            studentsAttendedLesson.every(s => props.lesson.studentsAttended.includes(s.id)),
        [props.lesson, homework, workDone, studentsAttendedLesson],
    );

    return (
        <Splitter
            className={classes.root}
            leftArea={
                <div className={classes.left}>
                    <StudentsList students={mergedStudents} rightSideOfStudent={studentAttended} />
                </div>
            }
            rightArea={
                <div className={classes.right}>
                    <Assignment header="Work done:" value={workDone} rows={4} onChange={setWorkDone} />
                    <Assignment header="Homework:" value={homework} rows={4} onChange={setHomework} />
                    <Row>
                        {nothingChanged ? (
                            ""
                        ) : (
                            <Typography variant="subtitle2" color="textSecondary">
                                Don't forget to save your changes :)
                            </Typography>
                        )}

                        <Expander />
                        <BrightButton
                            variant="outlined"
                            onClick={handleSave}
                            className={classes.saveButton}
                            disabled={nothingChanged}
                        >
                            Save
                        </BrightButton>
                    </Row>
                </div>
            }
        />
    );
}
