import {Theme, Typography} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/styles";
import moment from "moment";
import React, {useMemo, useState} from "react";
import BrightSelect from "../common/BrightSelect";
import {Column} from "../common/Div";
import PropWithLabel from "../common/PropWithLabel";
import {LessonsWithGroup} from "./StudentMain";

const MAX_LESSONS_TO_SHOW = 8;

const useStyles = makeStyles(({palette, spacing}: Theme) => {
    return createStyles({
        groupInfo: {
            marginTop: spacing(1),
        },
        groupName: {
            marginBottom: spacing(4),
        },
        lessonSelect: {
            width: spacing(25),
        },
        lessonPicker: {
            marginBottom: spacing(4),
        },
        groupProp: {
            marginBottom: spacing(1),
        },
        assignmentTitle: {
            marginBottom: spacing(1),
        },
        assignmentContainer: {
            whiteSpace: "pre-line",
            boxSizing: "border-box",
            padding: spacing(2),
            borderRadius: spacing(1),
            backgroundColor: palette.primaryBackground.main,
            marginBottom: 24,
        },
    });
});

interface Props {
    lessonsWithGroup: LessonsWithGroup;
}

export default function GroupWithLessons(props: Props) {
    const lessonsWithGroup = props.lessonsWithGroup;
    const classes = useStyles();

    const lessonsBeforeNow = useMemo(() => {
        const today = moment();
        return lessonsWithGroup.lessons.filter(l => l.date.isSameOrBefore(today));
    }, [lessonsWithGroup]);

    const lessonsToShow = useMemo(() => {
        return lessonsBeforeNow.slice(Math.max(lessonsWithGroup.lessons.length - MAX_LESSONS_TO_SHOW - 1, 0));
    }, [lessonsBeforeNow, lessonsWithGroup.lessons.length]);

    const lessonDatesToShow = useMemo(() => {
        return lessonsToShow.map(l => l.date.format("D MMMM YYYY"));
    }, [lessonsToShow]);

    const [selectedLessonIndex, setSelectedLessonIndex] = useState(lessonsToShow.length - 1);

    function handleSelectedLessonChanged(valueIndex: number): void {
        setSelectedLessonIndex(valueIndex);
    }

    const selectedLesson = lessonsToShow.length > 0 ? lessonsToShow[selectedLessonIndex] : undefined;

    const group = lessonsWithGroup.group;

    return (
        <>
            <Typography variant="h4" className={classes.groupName}>
                {group.name}
            </Typography>
            <Column>
                {selectedLesson && (
                    <Column>
                        <PropWithLabel
                            label="Урок"
                            className={classes.lessonPicker}
                            labelProps={{color: "textSecondary"}}
                        >
                            <BrightSelect
                                className={classes.lessonSelect}
                                selectedValueIndex={selectedLessonIndex}
                                values={lessonDatesToShow}
                                onSelectedValueChanged={handleSelectedLessonChanged}
                            />
                        </PropWithLabel>
                        {selectedLesson.workDone && selectedLesson.workDone.trim().length > 0 && (
                            <>
                                <Typography color="textSecondary" className={classes.assignmentTitle}>
                                    Робота на уроці:
                                </Typography>
                                <Typography variant="body2" className={classes.assignmentContainer}>
                                    {selectedLesson.workDone}
                                </Typography>
                            </>
                        )}

                        <Typography color="textSecondary" className={classes.assignmentTitle}>
                            Домашнє завдання:
                        </Typography>
                        {selectedLesson.homework && selectedLesson.homework.trim().length > 0 ? (
                            <Typography variant="body2" className={classes.assignmentContainer}>
                                {selectedLesson.homework}
                            </Typography>
                        ) : (
                            <Typography>Немає домашнього завдання</Typography>
                        )}
                    </Column>
                )}
                <Column className={classes.groupInfo}>
                    <PropWithLabel label="Розклад" className={classes.groupProp} labelProps={{color: "textSecondary"}}>
                        <Typography>
                            {group.schedule.getDaysLocalShortString() + " " + group.schedule.getGroupTimeStr()}
                        </Typography>
                    </PropWithLabel>
                    <PropWithLabel label="Рівень" className={classes.groupProp} labelProps={{color: "textSecondary"}}>
                        <Typography>{group.level}</Typography>
                    </PropWithLabel>
                    <PropWithLabel
                        label="Проведено занять"
                        className={classes.groupProp}
                        labelProps={{color: "textSecondary"}}
                    >
                        <Typography>{lessonsBeforeNow.length}</Typography>
                    </PropWithLabel>
                </Column>
            </Column>
        </>
    );
}
