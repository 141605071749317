import {CircularProgress, IconButton, Theme, Tooltip} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import {createStyles, makeStyles} from "@material-ui/styles";
import {PDFDownloadLink} from "@react-pdf/renderer";
import * as R from "ramda";
import * as React from "react";
import {memo} from "react";
import {ProgressReportPreview} from "./GroupProgressReportsView";
import {periodToString} from "./helper";
import ProgressReportPdf from "./pdf/ProgressReportPdf";

const useDownloadStyles = makeStyles(({spacing}: Theme) => {
    return createStyles({
        icon: {
            marginRight: spacing(1),
        },
    });
});

export const ReportDownloadLink = memo(({reportPreview}: {reportPreview: ProgressReportPreview}) => {
    const classes = useDownloadStyles();
    return (
        <PDFDownloadLink
            document={<ProgressReportPdf report={reportPreview.report} studentName={reportPreview.studentName} />}
            fileName={`${reportPreview.studentName} (${periodToString(reportPreview.report.period)}).pdf`}
        >
            {({blob, url, loading, error}) => {
                return loading ? (
                    <CircularProgress style={{width: 24, height: 24}} />
                ) : (
                    <Tooltip title="Download PDF">
                        <IconButton className={classes.icon} >
                            <GetAppIcon />
                        </IconButton>
                    </Tooltip>
                );
            }}
        </PDFDownloadLink>
    );
}, R.equals);