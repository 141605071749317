import {Button, Dialog, DialogActions, DialogContent, DialogContentText, makeStyles, Theme} from "@material-ui/core";
import MuiDialogTitle, {DialogTitleProps} from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import {useTheme} from "@material-ui/styles";
import clsx from "clsx";
import * as React from "react";

interface Props {
    title?: string;
    text?: React.ReactNode;
    buttons: string[];
    open: boolean;
    error?: boolean;
    closeIcon?: boolean;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
    fullWidth?: boolean;

    onClose(buttonPressed: number | undefined): void;
}

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    errorTitle: {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.main,
    },
}));

const DialogTitleWithClose = (
    props: DialogTitleProps & {
        onClose?: () => void;
        error: boolean;
    },
) => {
    const {children, onClose, error, id} = props;
    const classes = useStyles();
    return (
        <MuiDialogTitle disableTypography className={clsx(classes.root, {[classes.errorTitle]: error})}>
            <Typography variant="h6" id={id}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

export function MessageBox(props: Props) {
    function handle(i: number | undefined) {
        return () => {
            props.onClose(i);
        };
    }

    const theme = useTheme<Theme>();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Dialog
            maxWidth={props.maxWidth}
            id="qa-dialog"
            open={props.open}
            onClose={handle(undefined)}
            fullWidth={props.fullWidth}
            fullScreen={matches}
        >
            <DialogTitleWithClose
                id="qa-dlg-title"
                onClose={props.closeIcon ? handle(undefined) : undefined}
                error={!!props.error}
            >
                {props.title}
            </DialogTitleWithClose>
            <DialogContent>
                {typeof props.text === "string" || props.text instanceof String ? (
                    <DialogContentText>{props.text}</DialogContentText>
                ) : (
                    props.text
                )}
            </DialogContent>
            {props.buttons.length > 0 && (
                <DialogActions>
                    {props.buttons.map((text, i) => (
                        <Button
                            value={i}
                            onClick={handle(i)}
                            autoFocus={i === 0}
                            key={i}
                            id={`qa-dlg-btn-${text.toLowerCase()}`}
                        >
                            {text}
                        </Button>
                    ))}
                </DialogActions>
            )}
        </Dialog>
    );
}
