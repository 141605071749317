import {Id} from "../DB/entities/entity";
import {functions} from "../init-firebase";

export async function createUser(email: string, password: string): Promise<string> {
    const result = (
        await functions.httpsCallable("createUser")({
            email,
            password,
        })
    ).data;

    if (result.authId) {
        return result.authId as string;
    }

    return Promise.reject(result.error);
}

export async function deleteUserAuth(authId: Id): Promise<boolean> {
    return (await functions.httpsCallable("deleteUserAuth")(authId)).data.result === "success";
}
