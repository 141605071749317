import {Theme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/styles";
import {Moment} from "moment";
import * as React from "react";
import GroupList from "../../components/group/GroupList";
import {GroupId} from "../../DB/entities/entity";
import {Group} from "../../DB/entities/groups/group";
import {GroupSchedule} from "../../DB/entities/groups/groupSchedule";
import Splitter from "../common/Splitter";

const useStyles = makeStyles(({palette, spacing}: Theme) => {
    const mainPadding = spacing(3);
    return createStyles({
        root: {
            flexGrow: 1,
        },
        header: {
            paddingLeft: mainPadding,
            paddingBottom: spacing(1),
        },
        noLessons: {
            padding: mainPadding,
            color: palette.gray.main,
        },
        groupDays: {
            paddingLeft: mainPadding,
            paddingTop: mainPadding,
            color: palette.gray.main,
        },
        leftArea: {
            backgroundColor: palette.primaryBackground.main,
            height: "100%",
            padding: mainPadding,
        },
        rightArea: {
            backgroundColor: palette.secondaryBackground.main,
            height: "100%",
            padding: mainPadding,
        },
    });
});

interface Props {
    date: Moment;
    groupClicked: (groupId: GroupId) => void;
    groupsThatDate: Group[];
    groupsBySchedule: Array<[GroupSchedule, Group[]]>;
}

function TeacherAllGroups(props: Props) {
    const classes = useStyles();

    function handleGroupClick(groupId: GroupId) {
        props.groupClicked(groupId);
    }

    return (
        <Splitter
            className={classes.root}
            leftArea={
                <div className={classes.leftArea}>
                    <Typography className={classes.header} variant="h4">
                        {props.date.format("dddd, MMMM D")}
                    </Typography>
                    {props.groupsThatDate.length > 0 ? (
                        <GroupList groups={props.groupsThatDate} onGroupClick={handleGroupClick} showBreaks />
                    ) : (
                        <Typography className={classes.noLessons}>You have no lessons today</Typography>
                    )}
                </div>
            }
            rightArea={
                <div className={classes.rightArea}>
                    <Typography className={classes.header} variant="h4">
                        All Groups
                    </Typography>
                    {props.groupsBySchedule.map((value, index) => {
                        const [schedule, groupList] = value;

                        return (
                            <div key={index}>
                                <Typography className={classes.groupDays}>
                                    {schedule.getGroupDays().join(", ")}
                                </Typography>
                                <GroupList groups={groupList} onGroupClick={handleGroupClick} />
                            </div>
                        );
                    })}
                </div>
            }
        />
    );
}

export default TeacherAllGroups;
