import React, {useContext} from "react";
import {RouteComponentProps} from "react-router";
import AdminGroup from "../../components/admin/AdminGroup";
import PageLoading from "../../components/common/PageLoading";
import {DbContext} from "../../context";
import {GroupModel} from "../../DB/entities/groups/groupModel";
import {useDb} from "../../hooks/useDb";
import {AdminRoutes} from "../../util/routes/routes";

export default function AdminNewGroupContainer(props: RouteComponentProps) {
    const [teachers] = useDb(db => db.fetchTeachers(), []);
    const [allGroups] = useDb(db => db.fetchGroups(), []);
    const database = useContext(DbContext);

    function handleGroupChanged(group: GroupModel) {
        database.createGroup(group).then(() => {
            AdminRoutes.Index.navigate(props);
        });
    }

    return teachers && allGroups ? (
        <AdminGroup teachers={teachers} allGroups={allGroups} onGroupChanged={handleGroupChanged} />
    ) : (
        <PageLoading />
    );
}
