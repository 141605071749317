import {StudentId} from "./entity";
import {Group} from "./groups/group";
import {TeacherModel} from "./teacher";
import {User, UserModel, UserRole} from "./user";

interface Parent {
    name: string;
    phone: string;
}

export interface StudentModel extends UserModel<StudentId> {
    readonly parents?: Parent[];
}

export class StudentInfo extends User<StudentId> implements StudentModel {
    public readonly parents?: Parent[];
    constructor(
        model: StudentModel,
        public readonly studentGroups: Group[],
        public readonly studentTeachers: TeacherModel[],
    ) {
        super(model);
        this.parents = model.parents;
    }

    public getBirthdayString(): string {
        return this.birthDate ? this.birthDate.format("DD.MM.YYYY") : "";
    }
}

export function createNewStudent(): StudentModel {
    return {
        id: "",
        name: "",
        role: UserRole.Student,
        email: "",
    };
}
