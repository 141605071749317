import {Button, Theme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/styles";
import React, {useState} from "react";
import {useKeyPressEvent} from "react-use";
import {PrimaryFullPageContainer} from "../App";
import useRouter from "../hooks/useRouter";
import {auth} from "../init-firebase";
import SunLogo from "../resources/icons/SunLogo";
import {AuthRoutes} from "../util/routes/routes";
import BrightInput from "./common/BrightInput";
import {Row} from "./common/Div";

const useStyles = makeStyles(({spacing, palette}: Theme) => {
    return createStyles({
        icon: {
            marginTop: spacing(8),
            height: spacing(25),
            width: spacing(25),
        },
        input: {
            width: spacing(40),
            marginBottom: spacing(2),
        },
        button: {
            borderRadius: spacing(1),
            borderColor: palette.primaryBackground.main,
            color: palette.primaryBackground.main,
        },
        error: {
            color: palette.secondary.main,
            marginBottom: spacing(2),
            maxWidth: spacing(40),
        },
        buttonsRow: {
            width: spacing(40),
            justifyContent: "space-between",
        },
    });
});

export default function LogIn() {
    const classes = useStyles();
    const router = useRouter();

    const [state, setState] = useState({
        email: "",
        password: "",
        error: undefined,
    });

    useKeyPressEvent("Enter", () => {
        if (!state.error && state.email && state.password) {
            onSubmit();
        }
    });

    const onSubmit = () => {
        auth.signInWithEmailAndPassword(state.email, state.password).catch(r => {
            setState({
                ...state,
                error: r.message,
            });
        });
    };

    function handleEmailChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        setState({
            ...state,
            email: event.target.value.trim(),
            error: undefined,
        });
    }

    function onResetClick() {
        AuthRoutes.ResetPassword.navigate(router);
    }

    function handlePasswordChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        setState({
            ...state,
            password: event.target.value,
            error: undefined,
        });
    }

    return (
        <PrimaryFullPageContainer>
            <SunLogo className={classes.icon} dark />
            <BrightInput placeholder="Email" id="qa-email" className={classes.input} onChange={handleEmailChange} />
            <BrightInput
                placeholder="Password"
                id="qa-password"
                type="password"
                className={classes.input}
                onChange={handlePasswordChange}
            />
            {state.error && <Typography className={classes.error}>{state.error}</Typography>}
            <Row className={classes.buttonsRow}>
                <Button id="qa-reset-btn" className={classes.button} onClick={onResetClick} size="small">
                    Reset password
                </Button>
                <Button id="qa-login-btn" variant="outlined" className={classes.button} onClick={onSubmit}>
                    Log in
                </Button>
            </Row>
        </PrimaryFullPageContainer>
    );
}
