import {StyleSheet, Text, View, Image} from "@react-pdf/renderer";
import * as React from "react";

const secondaryTextColor = "rgba(0,0,0,0.54)";

const styles = StyleSheet.create({
    root: {
        flexDirection: "row",
        justifyContent: "space-between"
    },
    leftContainer: {
        flexDirection: "column",
        fontSize: 10,
        paddingTop: 16,
        paddingLeft: 8,
    },
    rightContainer: {
        flexDirection: "column",
        alignItems: "flex-end"
    },
    row: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    property: {
        width: 48,
        color: secondaryTextColor,
        marginBottom: 6,
    }
});

interface Props {
    studentName: string;
    periodString: string;
}

export default function ReportHeaderPdf(props: Props) {
    return <View style={styles.root}>
        <View style={styles.leftContainer}>
            <View style={styles.row}>
                <Text style={styles.property}>Name:</Text>
                <Text style={{fontWeight: "bold"}}>{props.studentName}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.property}>Period:</Text>
                <Text>{props.periodString}</Text>
            </View>
        </View>
        <View style={styles.rightContainer}>
            <Image style={{width: 72, marginRight: 8}}
                   src={process.env.PUBLIC_URL + "/images/brightlogo.png"}
            />
        </View>
    </View>
}