export enum GroupType {
    PreSchoolKids = "Pre-school kids",
    Kids = "Kids",
    PreTeens = "Pre-teens",
    Teens = "Teens",
    Adults = "Adults",
    Individual = "One-to-one",
}

export function groupTypeToName(type: GroupType): string {
    switch (type) {
        case GroupType.PreSchoolKids:
            return "Blue";
        case GroupType.Kids:
            return "Green";
        case GroupType.PreTeens:
            return "Yellow";
        case GroupType.Teens:
            return "Orange";
        case GroupType.Adults:
            return "Purple";
        case GroupType.Individual:
            return "One-to-one";
    }
}

export function groupTypeToColor(type: GroupType): string {
    switch (type) {
        case GroupType.PreSchoolKids:
            return "#99dbff";
        case GroupType.Kids:
            return "#bbff99";
        case GroupType.PreTeens:
            return "#fffc99";
        case GroupType.Teens:
            return "#ffbe99";
        case GroupType.Adults:
            return "#b999ff";
        case GroupType.Individual:
            return "#99ffdf";
    }
}

export const AllGroupTypes = [
    GroupType.PreSchoolKids,
    GroupType.Kids,
    GroupType.PreTeens,
    GroupType.Teens,
    GroupType.Adults,
    GroupType.Individual,
];
