import {List, ListItem, Typography} from "@material-ui/core";
import moment, {Duration} from "moment";
import {useMemo} from "react";
import * as React from "react";
import {GroupId} from "../../DB/entities/entity";
import {Group} from "../../DB/entities/groups/group";
import {minutesOfDay} from "../../util/Util";
import GroupPreview from "./GroupPreview";

function compareGroupsByTime(a: Group, b: Group): number {
    return minutesOfDay(a.schedule.getGroupTime().start) < minutesOfDay(b.schedule.getGroupTime().start) ? -1 : 1;
}

function durationToString(d: Duration): string {
    const hours = d.hours();
    const minutes = d.minutes();
    let result = "";
    if (hours > 0) {
        result += `${hours} ${hours > 1 ? "hours" : "hour"}`;
    }
    if (minutes > 0) {
        result += ` ${minutes} ${minutes > 1 ? "minutes" : "minute"}`;
    }
    return result;
}

interface Props {
    groups: Group[];
    onGroupClick(groupId: GroupId): void;

    showBreaks?: boolean;
}

function GroupList(props: Props) {
    const groups = useMemo(() => {
        return props.groups.sort(compareGroupsByTime);
    }, [props.groups]);

    const breaks: Record<GroupId, Duration> = useMemo(() => {
        if (!props.showBreaks && groups.length < 2) {
            return {};
        }

        const breaksAfterGroup: Record<GroupId, Duration> = {};
        groups.reduce((prev, cur) => {
            const timeDiffMinutes =
                minutesOfDay(cur.schedule.getGroupTime().start) - minutesOfDay(prev.schedule.getGroupTime().end);
            if (timeDiffMinutes > 0) {
                breaksAfterGroup[prev.id] = moment.duration(timeDiffMinutes, "minutes");
            }
            return cur;
        });
        return breaksAfterGroup;
    }, [groups, props.showBreaks]);

    return (
        <div>
            <List>
                {groups.map(groupItem => [
                    <ListItem key={groupItem.id}>
                        <GroupPreview group={groupItem} onGroupClick={props.onGroupClick} />
                    </ListItem>,
                    props.showBreaks && breaks[groupItem.id] && (
                        <ListItem key={`break_${groupItem.id}`}>
                            <Typography color="textSecondary">
                                {durationToString(breaks[groupItem.id])} break
                            </Typography>
                        </ListItem>
                    ),
                ])}
            </List>
        </div>
    );
}

export default GroupList;
