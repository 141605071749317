import moment, {Moment} from "moment";
import {GroupId, StudentId, TeacherId} from "../entities/entity";
import {DayOfTheWeek, GroupScheduleDayAttendTime} from "../entities/groups/group";
import {GroupLevel} from "../entities/groups/groupLevel";
import {GroupModel} from "../entities/groups/groupModel";
import {GroupSchedule} from "../entities/groups/groupSchedule";
import {GroupType} from "../entities/groups/groupType";
import {createLessonData, LessonModel} from "../entities/lesson";
import {StudentModel} from "../entities/student";
import {TeacherModel} from "../entities/teacher";
import {UserRole} from "../entities/user";
import {generateNextId} from "./StaticDB";

export function createTeacher(n: number, name: string): TeacherModel {
    return {
        id: `teacher${n}`,
        name,
        role: UserRole.Teacher,
        email: "",
        birthDate: moment("1992-02-10"),
        phone: "067 222 22 22",
        avatar: "",
    };
}

export function createStudent(n: number, name: string): StudentModel {
    const lastName: string = name.split(" ")[1];
    return {
        id: `student${n}`,
        name,
        role: UserRole.Student,
        email: "",
        birthDate: moment("1992-02-10"),
        phone: "067 222 22 22",
        avatar: "",
        parents: [
            {
                name: `Elena ${lastName}`,
                phone: "067 333 33 33",
            },
            {
                name: `Victor ${lastName}`,
                phone: "067 333 33 33",
            },
        ],
    };
}

export function day(d: DayOfTheWeek, start: string): Record<number, GroupScheduleDayAttendTime> {
    return {
        [d.valueOf()]: {
            start: moment(start, "HH:mm"),
            duration: moment.duration(2, "hours"),
        },
    };
}

export function createGroup(
    n: number,
    level: GroupLevel,
    type: GroupType,
    nameSuffix: string,
    days: DayOfTheWeek[],
    start: string,
    studyBook: string,
    teacher: TeacherId = "teacher0",
    studentIds: StudentId[] = ["student0", "student1", "student2", "student3"],
): GroupModel {
    return {
        id: `group${n}`,
        level,
        type,
        nameSuffix,
        schedule: GroupSchedule.fromObject(Object.assign({}, ...days.map(d => day(d, start)))),
        studyBook,
        studentIds,
        teacherIds: [teacher],
        inactive: false,
    };
}

export function dayOfLastWeek(days: DayOfTheWeek): Moment {
    return moment()
        .startOf("week")
        .add(days, "day");
}

export function createLesson(lessonDay: DayOfTheWeek, group: GroupId, teachers: TeacherId[]): LessonModel {
    return {
        id: generateNextId("lesson"),
        ...createLessonData(dayOfLastWeek(lessonDay), group, teachers),
    };
}
