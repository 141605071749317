import {Theme} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {createStyles, makeStyles} from "@material-ui/styles";
import React from "react";
import {Id, NamedEntity} from "../../DB/entities/entity";
import BrightSelect from "../common/BrightSelect";
import {Column, Row} from "../common/Div";
import classJ from "../../util/joinClasses";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        icon: {
            marginLeft: theme.spacing(1),
            cursor: "pointer",
        },
        row: {
            marginBottom: theme.spacing(1),
        },
    });
});

interface Props<TId extends Id, T extends NamedEntity<TId>> {
    selectedEntities: TId[];
    allEntities: T[];
    allowEmptySelection?: boolean;

    onChange?(newEntities: TId[]): void;

    className?: string;
}

function addIcon<TId extends Id, T extends NamedEntity<TId>>(
    selected: T[],
    allEntities: T[],
    handleAddClick: () => void,
    className: string,
) {
    return (
        <AddIcon
            color={allEntities.length === selected.length ? "disabled" : "primary"}
            className={classJ(className, "qa-add-icon")}
            onClick={handleAddClick}
        />
    );
}

export default function MultipleEntitiesSelection<TId extends Id, T extends NamedEntity<TId>>(props: Props<TId, T>) {
    const classes = useStyles();

    const allEntities = props.allEntities;

    const selected = allEntities.filter(t => {
        return props.selectedEntities.includes(t.id);
    });

    function handleEntityChange(newSelectedIndex: number, prevSelectedIndex: number) {
        if (props.onChange) {
            const newEntities = allEntities.filter(
                (e, index) => index === newSelectedIndex || (selected.includes(e) && index !== prevSelectedIndex),
            );
            props.onChange(newEntities.map(e => e.id));
        }
    }

    function handleAddClick() {
        if (props.onChange) {
            const newEntities = [...selected];
            const entityToAdd = allEntities.find(e => !selected.includes(e));
            if (entityToAdd) {
                newEntities.push(entityToAdd);
            }
            props.onChange(newEntities.map(t => t.id));
        }
    }

    function handleRemoveClick(index: number) {
        return () => {
            if (props.onChange) {
                props.onChange(selected.filter((_, i) => index !== i).map(e => e.id));
            }
        };
    }

    return (
        <Column className={props.className}>
            {selected.length > 0
                ? selected.map((e, index) => {
                      return (
                          <Row key={index} className={classes.row}>
                              <BrightSelect
                                  selectedValueIndex={allEntities.indexOf(e)}
                                  values={allEntities.map(en => en.name)}
                                  onSelectedValueChanged={handleEntityChange}
                              />
                              {index === 0 ? (
                                  <Row>
                                      {addIcon(selected, allEntities, handleAddClick, classes.icon)}
                                      {props.allowEmptySelection && (
                                          <RemoveIcon
                                              color="secondary"
                                              className={classJ(classes.icon, "qa-remove-icon")}
                                              onClick={handleRemoveClick(index)}
                                          />
                                      )}
                                  </Row>
                              ) : (
                                  <RemoveIcon
                                      color="secondary"
                                      className={classJ(classes.icon, "qa-remove-icon")}
                                      onClick={handleRemoveClick(index)}
                                  />
                              )}
                          </Row>
                      );
                  })
                : addIcon(selected, allEntities, handleAddClick, classes.icon)}
        </Column>
    );
}
