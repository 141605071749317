import React, {useContext, useState} from "react";
import {RouteComponentProps} from "react-router";
import AdminStudent from "../../components/admin/AdminStudent";
import PageLoading from "../../components/common/PageLoading";
import {DbContext} from "../../context";
import {GroupExtended} from "../../DB/entities/groups/group";
import {GroupModel} from "../../DB/entities/groups/groupModel";
import {StudentModel} from "../../DB/entities/student";
import {useDb} from "../../hooks/useDb";
import {AdminRoutes, StudentIdParam} from "../../util/routes/routes";

export default function AdminEditStudentContainer(props: RouteComponentProps<StudentIdParam>) {
    const studentId = props.match.params.id;

    const database = useContext(DbContext);

    const [isLoading, setIsLoading] = useState(false);

    const [model] = useDb(
        async db => {
            const teachers = await db.fetchTeachers();
            const students = await db.fetchStudents();
            const groupModels = await db.fetchGroups();

            const groups = groupModels.map(g => {
                const groupTeachers = teachers.filter(t => g.teacherIds.includes(t.id));
                const groupStudents = students.filter(s => g.studentIds.includes(s.id));
                return new GroupExtended(g, groupTeachers, groupStudents);
            });

            return {
                groups,
                student: students.find(s => s.id === studentId),
            };
        },
        [studentId],
    );

    async function handleStudentChange(s: StudentModel, studentGroups: GroupModel[], prevStudentGroups: GroupModel[]) {
        setIsLoading(true);

        await database.updateStudent(s);

        for (const g of prevStudentGroups) {
            if (!studentGroups.find(g1 => g1.id === g.id)) {
                await database.removeStudentFromGroup(g, s.id);
            }
        }

        for (const g of studentGroups) {
            if (!prevStudentGroups.find(g1 => g1.id === g.id)) {
                await database.addStudentToGroup(g, s.id);
            }
        }

        AdminRoutes.Students.navigate(props);
    }

    async function handleStudentDeleted(s: StudentModel) {
        setIsLoading(true);

        await database.deleteStudent(s);
        if (model) {
            await Promise.all(
                model.groups
                    .filter(g => g.studentIds.includes(s.id))
                    .map(g => database.removeStudentFromGroup(g, s.id)),
            );
        }

        AdminRoutes.Students.navigate(props);
    }

    async function handleStudentReset(s: StudentModel) {
        setIsLoading(true);
        await database.resetUser(s);
        setIsLoading(false);
    }

    return model && model.student && !isLoading ? (
        <AdminStudent
            allGroups={model.groups}
            student={model.student}
            onStudentChanged={handleStudentChange}
            onStudentDeleted={handleStudentDeleted}
            onStudentReset={handleStudentReset}
        />
    ) : (
        <PageLoading />
    );
}
