import React, {ReactNode, useState} from "react";
import BrightButton from "./BrightButton";
import {MessageBox} from "./MessageBox";

interface Props {
    confirmationText: string;
    onClick?(): void;
    disabled?: boolean;
    id?: string;
    className?: string;
    children?: ReactNode;
}

export default function ButtonWithConfirmation(props: Props) {
    const [openDialog, setOpenDialog] = useState(false);

    function handleClick() {
        setOpenDialog(true);
    }

    function onClose(buttonPressed: number | undefined) {
        setOpenDialog(false);
        if (props.onClick && buttonPressed === 1) props.onClick();
    }

    return (
        <>
            <BrightButton
                id={props.id}
                onClick={handleClick}
                disabled={props.disabled}
                variant="outlined"
                className={props.className}
            >
                {props.children}
            </BrightButton>
            <MessageBox
                title={props.confirmationText}
                fullWidth
                maxWidth="xs"
                buttons={["No", "Yes"]}
                open={openDialog}
                onClose={onClose}
            />
        </>
    );
}
