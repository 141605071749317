import {Tabs} from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import React, {useContext} from "react";
import {Redirect, Route, Switch} from "react-router";
import TopHeader from "../../components/common/TopHeader";
import UserTopHeaderAvatar from "../../components/common/UserTopHeaderAvatar";
import {UserContext} from "../../context";
import useRouter from "../../hooks/useRouter";
import {AdminRoutes, Route as RouteModel, EditProgressReport} from "../../util/routes/routes";
import {EditProgressReportContainer} from "../EditProgressReportContainer";
import AdminEditGroupContainer from "./AdminEditGroupContainer";
import AdminEditStudentContainer from "./AdminEditStudentContainer";
import AdminGroupsContainer from "./AdminGroupsContainer";
import AdminNewGroupContainer from "./AdminNewGroupContainer";
import AdminNewStudentContainer from "./AdminNewStudentContainer";
import AdminStudentsContainer from "./AdminStudentsContainer";

const adminPages: Array<{route: RouteModel; label: string; id: string}> = [
    {route: AdminRoutes.Index, label: "Групи", id: "qa_tab_groups"},
    {route: AdminRoutes.Students, label: "Учні", id: "qa_tab_students"},
];

export default function AdminIndex() {
    const user = useContext(UserContext);
    const router = useRouter();

    const selectedPage = adminPages.find(p => p.route.route === router.location.pathname);
    const tabIndex = selectedPage ? adminPages.indexOf(selectedPage) : false;

    function handleChange(event: React.ChangeEvent<{}>, newValue: any) {
        const adminPage = adminPages[newValue];
        if (adminPage) {
            adminPage.route.navigate(router);
        }
    }

    return (
        <div>
            <TopHeader mainText="Bright Admin">
                <>
                    <Tabs value={tabIndex} onChange={handleChange}>
                        {adminPages.map((page, index) => (
                            <Tab key={index} label={page.label} id={page.id} />
                        ))}
                    </Tabs>
                    {user && <UserTopHeaderAvatar user={user} />}
                </>
            </TopHeader>
            <Switch>
                <Route exact path={AdminRoutes.Index.route} component={AdminGroupsContainer} />
                <Route exact path={AdminRoutes.NewGroup.route} component={AdminNewGroupContainer} />
                <Route exact path={AdminRoutes.EditGroup.route} component={AdminEditGroupContainer} />

                <Route exact path={AdminRoutes.Students.route} component={AdminStudentsContainer} />
                <Route exact path={AdminRoutes.NewStudent.route} component={AdminNewStudentContainer} />
                <Route exact path={AdminRoutes.EditStudent.route} component={AdminEditStudentContainer} />

                <Route exact path={EditProgressReport.route} component={EditProgressReportContainer} />

                <Redirect to={AdminRoutes.Index.route} />
            </Switch>
        </div>
    );
}
