import {InputBase, TextField, Theme, Typography} from "@material-ui/core";
import {InputProps} from "@material-ui/core/Input";
import {InputBaseProps} from "@material-ui/core/InputBase";
import transitions from "@material-ui/core/styles/transitions";
import {TextFieldProps} from "@material-ui/core/TextField";
import {createStyles, makeStyles, WithStyles, withStyles} from "@material-ui/styles";
import {ClassKeyInferable} from "@material-ui/styles/withStyles/withStyles";
import React from "react";
import {Column} from "./Div";

const styles = ({palette, spacing}: Theme) =>
    createStyles({
        input: {
            borderRadius: spacing(1),
            position: "relative",
            border: "1px solid transparent",
            backgroundColor: palette.primaryBackground.main,
            fontSize: 16,
            fontWeight: "bold",
            minHeight: spacing(5),
            textAlignVertical: "center",
            //width: 'auto',
            padding: "10px 12px 10px 12px",
            boxSizing: "border-box",
            transition: transitions.create(["border-color", "box-shadow"]),
            borderColor: palette.divider,

            "&:hover": {
                borderColor: "#80bdff",
            },

            "&:focus": {
                borderColor: "#80bdff",
                boxShadow: "0 0 0 0.2rem rgba(0,123,255,.2)",
            },
        },
        error: {
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: palette.secondary.main,
            borderRadius: spacing(1),
        },
    });

const useStyles = makeStyles(({spacing}: Theme) => {
    return createStyles({
        errorMessageLabel: {
            marginTop: spacing(1),
        },
    });
});

export interface BrightInputProps extends InputBaseProps {
    errorMessage?: string;
}

function BrightInput({errorMessage, ...inputProps}: BrightInputProps & WithStyles<ClassKeyInferable<any, any>>) {
    const classes = useStyles();
    return (
        <Column>
            <InputBase {...inputProps}/>
            {errorMessage && inputProps.error && (
                <Typography variant="body2" color="secondary" className={classes.errorMessageLabel}>
                    {errorMessage}
                </Typography>
            )}
        </Column>
    );
}

export default withStyles(styles)(BrightInput);

const useDateTextFieldStyles = makeStyles((theme: Theme) => {
    return createStyles({
        ...styles(theme),
        disabled: {
            color: "#000",
        },
    });
});

export function BrightDateTextField(props: TextFieldProps) {
    const classes = useDateTextFieldStyles();
    const inputProps: Partial<InputProps> = {
        classes,
        disableUnderline: true,
    };
    return <TextField {...props} InputProps={inputProps} />;
}
