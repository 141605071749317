import {Button, Theme} from "@material-ui/core";
import {createStyles, withStyles} from "@material-ui/styles";

const styles = ({palette, spacing}: Theme) =>
    createStyles({
        root: {
            borderRadius: spacing(1),
            backgroundColor: palette.primaryBackground.main,
        },
        disabled: {
            backgroundColor: "transparent",
            borderColor: palette.gray.main,
            color: palette.gray.main,
        },
    });

export default withStyles(styles)(Button);
