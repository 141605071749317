import {Theme} from "@material-ui/core";
import {createStyles, CSSProperties, makeStyles} from "@material-ui/styles";
import * as React from "react";
import classJ from "../../util/joinClasses";

const useStyles = makeStyles(({breakpoints}: Theme) => {
    const subArea: CSSProperties = {
        width: "50%",
        [breakpoints.down("sm")]: {
            width: "100%",
        },
        boxSizing: "border-box",
    };
    return createStyles({
        root: {
            display: "flex",
            flexDirection: "row",
            [breakpoints.down("sm")]: {
                flexDirection: "column",
            },
            alignItems: "stretch",
        },

        leftSubArea: {
            ...subArea,
        },

        rightSubArea: {
            flex: 1,
            ...subArea,
        },
    });
});

interface Props {
    leftArea: React.ReactNode;
    rightArea: React.ReactNode;
    className?: string;
}

function Splitter(props: Props) {
    const classes = useStyles();
    return (
        <div className={classJ(classes.root, props.className)}>
            <div className={classes.leftSubArea}>{props.leftArea}</div>
            <div className={classJ(classes.rightSubArea)}>{props.rightArea}</div>
        </div>
    );
}

export default Splitter;
