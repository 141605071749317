import {IconButton, Theme} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import {createStyles, CSSProperties, makeStyles, useTheme} from "@material-ui/styles";
import React, {useEffect, useState} from "react";
import Button from "../common/BrightButton";
import {Expander, Row} from "../common/Div";

enum ButtonSize {
    medium = "medium",
    large = "large",
}

const useStyles = makeStyles((theme: Theme) => {
    const dialogPadding: CSSProperties = {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    };
    return createStyles({
        dialogArea: {
            boxSizing: "border-box",
            ...dialogPadding,
        },
        dialogTitle: {
            boxSizing: "border-box",
            ...dialogPadding,
        },
        dialogActions: {
            boxSizing: "border-box",
            marginBottom: theme.spacing(2),
            ...dialogPadding,
        },
        dialogActionWidth: {
            width: "100%",
        },
        row: {
            marginBottom: theme.spacing(2),
            border: theme.spacing(1),
        },
        input: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    borderRadius: theme.spacing(1),
                },
            },
        },
    });
});

interface Props {
    onPasswordChange?(password: string, newPassword: string): void;

    onClose?(): void;

    open: boolean;
    errorMessage: string;
}

interface ChangePasswordModel {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export default function ChangePasswordDialog(props: Props) {
    const classes = useStyles();
    const open = props.open;
    const errorMessage = props.errorMessage;
    const [changeEnable, setChangeEnable] = useState(false);
    const onClose = props.onClose;
    const [changePasswordModel, setChangePasswordModel] = useState<ChangePasswordModel>({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });

    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        if (
            changePasswordModel.oldPassword &&
            changePasswordModel.newPassword &&
            changePasswordModel.confirmPassword &&
            changePasswordModel.oldPassword.length > 0 &&
            changePasswordModel.newPassword.length > 0 &&
            changePasswordModel.newPassword === changePasswordModel.confirmPassword
        ) {
            setChangeEnable(true);
        } else {
            setChangeEnable(false);
        }
    }, [changePasswordModel]);

    function onModelChange(property: Partial<ChangePasswordModel>) {
        setChangePasswordModel(prevState => {
            return {...prevState, ...property};
        });
    }

    function onPasswordChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        onModelChange({oldPassword: event.target.value.trim()});
    }

    function onNewPasswordChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        onModelChange({newPassword: event.target.value.trim()});
    }

    function onConfirmPasswordChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        onModelChange({confirmPassword: event.target.value.trim()});
    }

    function handleChange() {
        if (props.onPasswordChange) {
            props.onPasswordChange(changePasswordModel.oldPassword, changePasswordModel.newPassword);
            setChangeEnable(false);
        }
    }

    const handleClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    return (
        <div>
            <Dialog open={open} fullScreen={isMobile} fullWidth={true} maxWidth="sm" onClose={handleClose}>
                <DialogTitle className={classes.dialogTitle}>
                    <Row>
                        <Typography variant="h5"> Change password </Typography>
                        <Expander />
                        {onClose ? (
                            <IconButton aria-label="close" onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        ) : null}
                    </Row>
                </DialogTitle>
                <DialogContent className={classes.dialogArea}>
                    <TextField
                        label="Password"
                        variant={"outlined"}
                        className={classes.input}
                        type="password"
                        onChange={onPasswordChange}
                        fullWidth
                    />
                    <TextField
                        label="New password"
                        variant={"outlined"}
                        className={classes.input}
                        type="password"
                        onChange={onNewPasswordChange}
                        fullWidth
                    />
                    <TextField
                        label="Confirm password"
                        variant={"outlined"}
                        className={classes.input}
                        type="password"
                        onChange={onConfirmPasswordChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Row className={classes.dialogActionWidth}>
                        {errorMessage ? (
                            <Typography variant="body2" color="textSecondary">
                                {errorMessage}
                            </Typography>
                        ) : null}
                        <Expander />

                        <Button
                            disabled={!changeEnable}
                            variant="outlined"
                            onClick={handleChange}
                            size={isMobile ? ButtonSize.large : ButtonSize.medium}
                        >
                            Save
                        </Button>
                    </Row>
                </DialogActions>
            </Dialog>
        </div>
    );
}
