import {CircularProgress, Theme} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import {createStyles, makeStyles, useTheme} from "@material-ui/styles";
import {useState} from "react";
import * as React from "react";

const useStyles = makeStyles(() => {
    return createStyles({
        progress: {
            marginLeft: "auto",
            padding: 10,
        },
        iconButton: {
            marginLeft: "auto",
        },

        iconButtonRoot: {
            padding: 6,
        },
    });
});

interface Props {
    attended: boolean;
    onAttendedChanged: (newValue: boolean) => Promise<void> | void;
}

function StudentAttendedCircle(props: Props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const theme = useTheme<Theme>();

    async function handleClick() {
        setLoading(true);
        await props.onAttendedChanged(!props.attended);
        setLoading(false);
    }

    return loading ? (
        <CircularProgress className={classes.progress} color="secondary" size={theme.spacing(2)} />
    ) : (
        <IconButton
            className={classes.iconButton}
            classes={{
                root: classes.iconButtonRoot,
            }}
            onClick={handleClick}
        >
            <SvgIcon viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="40" stroke="#A0A0A0" strokeWidth="8" fill="transparent" />
                {props.attended ? <circle cx="50" cy="50" r="25" fill="#5AE083" /> : null}
            </SvgIcon>
        </IconButton>
    );
}

export default StudentAttendedCircle;
