import {Theme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/styles";
import {Moment} from "moment";
import React from "react";
import {GroupSchedule} from "../../DB/entities/groups/groupSchedule";
import {LessonModel} from "../../DB/entities/lesson";
import BrightDatePicker from "./time/BrightDatePicker";
import LessonDay from "./time/LessonDay";

const useStyles = makeStyles(({spacing}: Theme) => {
    return createStyles({
        selectLesson: {
            display: "flex",
            alignItems: "center",
        },
        selectLessonText: {
            marginRight: spacing(2),
        },
    });
});

interface Props {
    selectedDate: Moment;
    onChange?(newDate: Moment): void;
    schedule?: GroupSchedule;
    pastLessons?: LessonModel[];
}

export default function LessonDatePicker(props: Props) {
    const classes = useStyles();

    function renderDay(day: Moment, selectedDay: Moment, dayInCurrentMonth: boolean, dayComponent: JSX.Element) {
        return (
            <LessonDay
                day={day}
                selectedDay={selectedDay}
                schedule={props.schedule}
                pastLessons={props.pastLessons}
                dayInCurrentMonth={dayInCurrentMonth}
                dayProps={dayComponent.props}
            />
        );
    }

    return (
        <div className={classes.selectLesson}>
            <Typography className={classes.selectLessonText} variant="subtitle1" color="textSecondary">
                Lesson:
            </Typography>
            <BrightDatePicker selectedDate={props.selectedDate} onChange={props.onChange} renderDay={renderDay} />
        </div>
    );
}
