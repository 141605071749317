import {Moment} from "moment";
import {Id, NamedEntity, UserId} from "./entity";

export enum UserRole {
    Admin,
    Teacher,
    Student,
}

export interface UserModel<T extends UserId = UserId> extends NamedEntity<T> {
    readonly role: UserRole;
    readonly name: string;

    readonly email: string;
    authId?: Id;

    readonly phone?: string;
    readonly avatar?: Id;
    readonly birthDate?: Moment;
    readonly comment?: string;
}

export class User<T extends UserId> implements UserModel<T> {
    public readonly id: T;
    public readonly name: string;
    public readonly role: UserRole;
    public readonly email: string;
    public readonly authId?: string;

    public readonly phone?: string;
    public readonly avatar?: string;
    public readonly birthDate?: Moment;
    public readonly comment?: string;

    constructor(model: UserModel<T>) {
        this.id = model.id;
        this.name = model.name;
        this.role = model.role;
        this.email = model.email;
        this.authId = model.authId;
        this.phone = model.phone;
        this.avatar = model.avatar;
        this.birthDate = model.birthDate;
        this.comment = model.comment;
    }
}
