import {Theme, Typography} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/styles";
import React from "react";
import {GroupType, groupTypeToColor, groupTypeToName} from "../../DB/entities/groups/groupType";
import BrightSelect from "../common/BrightSelect";
import {Row} from "../common/Div";

const useStyles = makeStyles(({spacing}: Theme) => {
    return createStyles({
        colorBar: {
            width: spacing(6),
            height: spacing(2.5),
            marginRight: spacing(1),
        },
    });
});

function GroupTypeValue(props: {groupType: GroupType}) {
    const classes = useStyles();

    const groupAltName = props.groupType === GroupType.Individual ? undefined : props.groupType;
    const groupName = groupTypeToName(props.groupType) + (groupAltName ? ` (${groupAltName})` : "");

    return (
        <Row>
            <div style={{backgroundColor: groupTypeToColor(props.groupType)}} className={classes.colorBar} />
            <Typography>{groupName}</Typography>
        </Row>
    );
}

interface Props {
    selectedType: GroupType;
    allGroupTypes: GroupType[];
    onChange?(newSelectedType: GroupType): void;
    className?: string;
}

export default function GroupTypeSelection(props: Props) {
    function handleValueChange(newSelectedIndex: number) {
        if (props.onChange) {
            props.onChange(props.allGroupTypes[newSelectedIndex]);
        }
    }

    return (
        <BrightSelect
            className={props.className}
            selectedValueIndex={props.allGroupTypes.indexOf(props.selectedType)}
            values={props.allGroupTypes.map(g => (
                <GroupTypeValue key={g} groupType={g} />
            ))}
            onSelectedValueChanged={handleValueChange}
        />
    );
}
