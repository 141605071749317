export enum Mark {
    A = 6,
    B = 5,
    C = 4,
    D = 3,
    E = 2,
    F = 1,
    NA = 0,
}

export function markToColor(mark: Mark): string {
    switch (mark) {
        case Mark.A:
            return "#4fff5e";
        case Mark.B:
            return "#c4ff4d";
        case Mark.C:
            return "#ffff4d";
        case Mark.D:
            return "#ffd34f";
        case Mark.E:
            return "#ff984f";
        case Mark.F:
            return "#ff644d";
        case Mark.NA:
            return "#fff";
    }
}

export function allRealMarks(): Mark[] {
    return [Mark.A, Mark.B, Mark.C, Mark.D, Mark.E, Mark.F];
}

export function markToString(mark: Mark): string {
    return Mark[mark];
}

export function stringToMark(value: string): Mark {
    return Mark[value as keyof typeof Mark];
}
