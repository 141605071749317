import {Theme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/styles";
import createStyles from "@material-ui/styles/createStyles";
import * as React from "react";
import {Group} from "../../DB/entities/groups/group";

const useStyles = makeStyles(({palette, spacing}: Theme) => {
    const mainPadding = spacing(2);
    return createStyles({
        root: {
            position: "relative",
            boxSizing: "border-box",
            width: "auto",
            padding: mainPadding,
            borderColor: palette.gray.main,
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: spacing(1),
            overflow: "hidden",
        },
        backgroundOverlay: {
            backgroundColor: palette.primaryBackground.main,
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: mainPadding,
            height: "auto",
            width: "auto",
        },
        secondaryTable: {
            color: palette.gray.main,
            marginTop: spacing(1),
            border: "none",
            borderCollapse: "collapse",
            width: "100%",
        },
    });
});

interface Props {
    group: Group;
    className?: string;
}

export default function GroupOverview(props: Props) {
    const classes = useStyles();
    const className = classes.root + " " + props.className;
    const group = props.group;

    return (
        <div className={className}>
            {!props.group ? <div className={classes.backgroundOverlay} /> : <div />}
            <Typography variant="h4">{group.name}</Typography>
            <table className={classes.secondaryTable}>
                <tbody>
                    <tr>
                        <td>
                            <Typography color="inherit">
                                {group.schedule.getGroupDays().join(", ")}
                            </Typography>
                        </td>
                        <td>
                            <Typography color="inherit">
                                {group.schedule.getGroupTimeStr()}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography color="inherit">
                                {group.level}
                            </Typography>
                        </td>
                        <td>
                            <Typography color="inherit">
                                {group.studyBook}
                            </Typography>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
