import {Moment} from "moment";
import {Entity, EntityData, GroupId, LessonId, StudentId, TeacherId} from "./entity";

export interface LessonModel extends Entity<LessonId> {
    readonly date: Moment;
    readonly groupId: GroupId;
    readonly teacherIds: TeacherId[];
    readonly studentsAttended: StudentId[];
    workDone: string;
    homework: string;
}

export function createLessonData(date: Moment, group: GroupId, teachers: TeacherId[]): EntityData<LessonModel> {
    return {
        date,
        groupId: group,
        teacherIds: teachers,
        homework: "",
        workDone: "",
        studentsAttended: [],
    };
}
