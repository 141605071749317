export enum GroupLevel {
    preA1 = "Pre A1",
    A1 = "A1",
    A1plus = "A1+",
    A2 = "A2",
    A2plus = "A2+",
    B1 = "B1",
    B1plus = "B1+",
    B2 = "B2",
    B2plus = "B2+",
    C1 = "C1",
    C2 = "C2",
}

export function groupLevels(): GroupLevel[] {
    return [
        GroupLevel.preA1,
        GroupLevel.A1,
        GroupLevel.A1plus,
        GroupLevel.A2,
        GroupLevel.A2plus,
        GroupLevel.B1,
        GroupLevel.B1plus,
        GroupLevel.B2,
        GroupLevel.B2plus,
        GroupLevel.C1,
        GroupLevel.C2,
    ];
}
