import {DayOfTheWeek} from "../entities/groups/group";
import {GroupLevel} from "../entities/groups/groupLevel";
import {GroupModel} from "../entities/groups/groupModel";
import {GroupType} from "../entities/groups/groupType";
import {LessonModel} from "../entities/lesson";
import {StudentModel} from "../entities/student";
import {TeacherModel} from "../entities/teacher";
import {createGroup, createLesson, createStudent, createTeacher} from "./helpers";

export function generateTeachers(): TeacherModel[] {
    return [createTeacher(0, "Valentyna Hadion"), createTeacher(1, "Ryan Gosling")];
}

export function generateStudents(): StudentModel[] {
    return [
        createStudent(0, "Andrew Gadion"),
        createStudent(1, "Tetiana Hrybok"),
        createStudent(2, "Pavel Grab"),
        createStudent(3, "Maksim Chuzhyh"),
        createStudent(4, "Vitya Morzh"),
        createStudent(5, "Petya Lukas"),
        createStudent(6, "Oleg Vinnyk"),
        createStudent(7, "Viktor Pavlik"),
    ];
}

export function generateGroups(): GroupModel[] {
    return [
        createGroup(
            0,
            GroupLevel.B2,
            GroupType.PreTeens,
            "",
            [DayOfTheWeek.Monday, DayOfTheWeek.Wednesday],
            "17:00",
            "Kid's Box 5",
        ),
        createGroup(
            1,
            GroupLevel.C1,
            GroupType.Teens,
            "",
            [DayOfTheWeek.Tuesday, DayOfTheWeek.Thursday],
            "15:00",
            "Speakout 3",
        ),
        createGroup(
            2,
            GroupLevel.B1,
            GroupType.Adults,
            "",
            [DayOfTheWeek.Monday, DayOfTheWeek.Wednesday, DayOfTheWeek.Friday],
            "19:00",
            "Speakout 2",
            "teacher0",
            ["student1", "student2", "student5", "student6", "student7"],
        ),
        createGroup(
            3,
            GroupLevel.A1,
            GroupType.Adults,
            "",
            [DayOfTheWeek.Tuesday, DayOfTheWeek.Thursday],
            "19:00",
            "Speakout 1",
        ),
        createGroup(
            4,
            GroupLevel.C1,
            GroupType.Teens,
            "1",
            [DayOfTheWeek.Tuesday, DayOfTheWeek.Thursday],
            "15:00",
            "Speakout 3",
            "teacher1",
            ["student4", "student5", "student6", "student7"],
        ),
        createGroup(
            5,
            GroupLevel.B1,
            GroupType.Adults,
            "1",
            [DayOfTheWeek.Monday, DayOfTheWeek.Wednesday, DayOfTheWeek.Friday],
            "19:00",
            "Speakout 2",
            "teacher1",
            ["student4", "student7"],
        ),
        createGroup(
            6,
            GroupLevel.A1,
            GroupType.Adults,
            "1",
            [DayOfTheWeek.Tuesday, DayOfTheWeek.Thursday],
            "19:00",
            "Speakout 1",
            "teacher1",
            ["student4", "student6", "student7"],
        ),
    ];
}

export function generateLessons(): LessonModel[] {
    return [
        createLesson(DayOfTheWeek.Monday, "group0", ["teacher0"]),
        createLesson(DayOfTheWeek.Wednesday, "group0", ["teacher0"]),

        createLesson(DayOfTheWeek.Tuesday, "group1", ["teacher0"]),
        createLesson(DayOfTheWeek.Thursday, "group1", ["teacher0"]),

        createLesson(DayOfTheWeek.Monday, "group2", ["teacher0"]),
        createLesson(DayOfTheWeek.Wednesday, "group2", ["teacher0"]),
        createLesson(DayOfTheWeek.Friday, "group2", ["teacher0"]),

        createLesson(DayOfTheWeek.Tuesday, "group3", ["teacher0"]),
        createLesson(DayOfTheWeek.Thursday, "group3", ["teacher0"]),

        createLesson(DayOfTheWeek.Monday, "group4", ["teacher1"]),
        createLesson(DayOfTheWeek.Wednesday, "group4", ["teacher1"]),

        createLesson(DayOfTheWeek.Tuesday, "group5", ["teacher1"]),
        createLesson(DayOfTheWeek.Thursday, "group5", ["teacher1"]),

        createLesson(DayOfTheWeek.Monday, "group6", ["teacher1"]),
        createLesson(DayOfTheWeek.Wednesday, "group6", ["teacher1"]),
        createLesson(DayOfTheWeek.Friday, "group6", ["teacher1"]),
    ];
}
