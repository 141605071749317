import {Box, Theme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/styles";
import {Moment} from "moment";
import * as React from "react";
import {Period} from "../../util/Range";
import {Row} from "../common/Div";
import BrightDatePicker from "../common/time/BrightDatePicker";

const useStyles = makeStyles(({spacing}: Theme) => {
    return createStyles({
        label: {
            marginRight: spacing(1),
        },
        titleRow: {
            marginBottom: spacing(1),
        },
    });
});

interface Props {
    title: string;

    period: Period;
    onPeriodChange?(period: Period): void;

    className?: string;
}

export function ProgressReportHeader(props: Props) {
    const classes = useStyles();

    function handlePeriodStartChange(newDate: Moment) {
        if (props.onPeriodChange)
            props.onPeriodChange({
                ...props.period,
                start: newDate,
            });
    }
    function handlePeriodEndChange(newDate: Moment) {
        if (props.onPeriodChange)
            props.onPeriodChange({
                ...props.period,
                end: newDate,
            });
    }

    return (
        <div className={props.className}>
            <Row className={classes.titleRow}>
                <Typography>{props.title}</Typography>
            </Row>
            <Row>
                <Typography className={classes.label} color="textSecondary">
                    From:
                </Typography>
                <Box mr={1}>
                    <BrightDatePicker selectedDate={props.period.start} onChange={handlePeriodStartChange} />
                </Box>
                <Typography className={classes.label} color="textSecondary">
                    To:
                </Typography>
                <BrightDatePicker selectedDate={props.period.end} onChange={handlePeriodEndChange} />
            </Row>
        </div>
    );
}
