import {Duration, Moment} from "moment";
import {GroupId, NamedEntity, StudentId, TeacherId} from "../entity";
import {StudentModel} from "../student";
import {TeacherModel} from "../teacher";
import {GroupLevel} from "./groupLevel";
import {GroupModel} from "./groupModel";
import {GroupSchedule} from "./groupSchedule";
import {GroupType, groupTypeToName} from "./groupType";

export interface GroupScheduleDayAttendTime {
    start: Moment;
    duration: Duration;
}

export enum DayOfTheWeek {
    Monday = 0,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday,
}

export class Group implements GroupModel, NamedEntity<GroupId> {
    public type: GroupType;
    public nameSuffix: string;
    public level: GroupLevel;
    public name: string;
    public schedule: GroupSchedule;
    public studyBook: string;
    public id: GroupId;
    public teacherIds: TeacherId[];
    public studentIds: StudentId[];
    public inactive: boolean;

    constructor(model: GroupModel, oneToOneStudent?: string) {
        this.type = model.type;
        this.nameSuffix = model.nameSuffix;
        this.level = model.level;
        this.schedule = model.schedule;
        this.studyBook = model.studyBook;
        this.id = model.id;

        this.teacherIds = model.teacherIds;
        this.studentIds = model.studentIds;

        this.name = generateNameWithSuffix(
            model.type,
            isOneToOne(model.type) ? (oneToOneStudent ? oneToOneStudent : "") : model.nameSuffix,
            model.level,
        );
        this.inactive = model.inactive;
    }
}

export function generateNameWithSuffix(type: GroupType, nameSuffix: string, level: GroupLevel): string {
    return generateName(type, level) + (nameSuffix ? ` (${nameSuffix})` : "");
}

export function generateName(type: GroupType, level: GroupLevel): string {
    return groupTypeToName(type) + " " + level;
}

export function isOneToOne<T>(type: GroupType, students?: T[]): boolean {
    return type === GroupType.Individual && (students ? students.length === 1 : true);
}

export class GroupExtended extends Group {
    constructor(model: GroupModel, public readonly teachers: TeacherModel[], public readonly students: StudentModel[]) {
        super(model);

        if (isOneToOne(model.type, this.students)) {
            this.name = generateNameWithSuffix(model.type, this.students[0].name, model.level);
        }
    }
}
