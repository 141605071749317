import {IconButton, Tooltip} from "@material-ui/core";
import {SvgIconComponent} from "@material-ui/icons";
import React, {useState} from "react";
import {MessageBox} from "./MessageBox";

interface Props {
    text: string;
    disabled?: boolean;
    tooltip?: string;
    onClick?(): void;
    icon: SvgIconComponent;
    id: string;
}

export default function IconButtonWithConfirmation(props: Props) {
    const [openDialog, setOpenDialog] = useState(false);

    function handleClick() {
        setOpenDialog(true);
    }

    function onClose(buttonPressed: number | undefined) {
        setOpenDialog(false);
        if (props.onClick && buttonPressed === 1) props.onClick();
    }

    return (
        <>
            <Tooltip title={props.tooltip ?? ""}>
                <IconButton id={props.id} aria-label="delete" onClick={handleClick} disabled={props.disabled}>
                    {<props.icon />}
                </IconButton>
            </Tooltip>
            <MessageBox
                title={props.text}
                fullWidth
                maxWidth="xs"
                buttons={["No", "Yes"]}
                open={openDialog}
                onClose={onClose}
            />
        </>
    );
}
