import {alpha, Theme} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/styles";
import {Property} from "csstype";
import * as React from "react";
import {ReactNode} from "react";
import {Column, Row} from "./Div";

export interface Option {
    value: ReactNode;
    color: string;
}

interface SelectOptionsColoredProps {
    options: Option[];
    selectedIndex: number | undefined;
    onSelectedChange: (selectedIndex: number | undefined) => void;
    readOnly?: boolean;
    column?: boolean;
}

interface OptionStyleProps {
    color: string;
    selected: boolean;
    opacity?: number;
    pointerEvents?: Property.PointerEvents;
}

const useOptionStyles = makeStyles<Theme, OptionStyleProps>((theme: Theme) => {
    return createStyles({
        container: props => ({
            boxSizing: "border-box",
            cursor: "pointer",
            backgroundColor: props.selected ? props.color : undefined,
            margin: theme.spacing(0.4),
            padding: theme.spacing(0.5),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            borderRadius: theme.spacing(1),
            pointerEvents: props.pointerEvents,
            opacity: props.opacity,
            "&:hover": {
                backgroundColor: props.selected ? props.color : alpha(theme.palette.secondaryBackground.main, 0.7),
            },
        }),
    });
});

function OptionComponent(props: {opt: Option; selected: boolean; readOnly?: boolean, onSelect: () => void}) {
    const classes = useOptionStyles({
        color: props.opt.color,
        selected: props.selected,
        opacity: props.readOnly ? 0.25 : 1,
        pointerEvents: props.readOnly ? "none" : "inherit",
    });
    return (
        <div className={classes.container} onClick={props.onSelect}>
            {props.opt.value}
        </div>
    );
}

export default function SelectOptionsColored(props: SelectOptionsColoredProps) {
    const Container = props.column ? Column : Row;
    return (
        <Container>
            {props.options.map((o, i) => (
                <OptionComponent
                    key={i}
                    opt={o}
                    readOnly={props.readOnly}
                    selected={i === props.selectedIndex}
                    onSelect={() => props.onSelectedChange(props.selectedIndex === i ? undefined : i)}
                />
            ))}
        </Container>
    );
}
