import firebase from "firebase/app";
import moment, {Duration, Moment} from "moment";

export function removeUndefinedFields<T>(obj: T): Partial<T> {
    return Object.entries(obj).reduce<Partial<T>>((newObj, [key, val]) => {
        if (val !== undefined && val !== null) {
            // @ts-ignore
            newObj[key] = val;
        }

        return newObj;
    }, {});
}

export function momentToFbTime(m: Moment): firebase.firestore.Timestamp {
    return new firebase.firestore.Timestamp(m.unix(), 0);
}

export function fbTimeToMoment(t: firebase.firestore.Timestamp): Moment {
    return moment.unix(t.seconds);
}

export function durationToMillis(d: Duration): number {
    return d.asMilliseconds();
}

export function millisToDuration(millis: number): Duration {
    return moment.duration(millis);
}
