import {DependencyList, useCallback, useEffect, useState} from "react";
import {useMountedRef} from "./useMountedRef";

/**
 * @return [data, error]
 */
export function useAsync<T>(callback: () => Promise<T> | T, deps?: DependencyList): [T | undefined, boolean] {
    const isMountedRef = useMountedRef();

    const [result, setResult] = useState<T>();
    const [error, setError] = useState(false);

    const handleResult = useCallback(
        (res: T) => {
            if (isMountedRef.current) setResult(res);
        },
        [isMountedRef],
    );

    const handleError = useCallback(() => {
        if (isMountedRef.current) setError(true);
    }, [isMountedRef]);

    const refresh = useCallback(() => {
        setError(false);
        Promise.resolve(callback())
            .then(handleResult)
            .catch(handleError);
    }, [callback, handleError, handleResult]);

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    return [result, error];
}
