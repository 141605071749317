import {Avatar} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import React, {MouseEvent, useEffect, useState} from "react";
import {Id} from "../../DB/entities/entity";
import {storage} from "../../init-firebase";

interface Props {
    avatar: Id | undefined;
    onClick?(event: MouseEvent): void;

    className?: string;
}

export default function UserAvatar(props: Props) {
    const avatarId = props.avatar;

    const [avatar, setAvatar] = useState<string>();

    useEffect(() => {
        if (avatarId) {
            const storageRef = storage.ref();
            storageRef
                .child(avatarId)
                .getDownloadURL()
                .then(function(url) {
                    const xhr = new XMLHttpRequest();
                    xhr.responseType = "blob";
                    xhr.onload = function() {
                        const blob = xhr.response;
                        const urlCreator = window.URL || window.webkitURL;
                        const imageUrl = urlCreator.createObjectURL(blob);
                        setAvatar(imageUrl);
                    };
                    xhr.open("GET", url);
                    xhr.send();
                })
                .catch(function() {});
        }
    }, [avatarId]);

    if (avatarId && avatarId !== "" && avatar) {
        return <Avatar className={props.className} src={avatar} onClick={props.onClick} />;
    } else {
        return <AccountCircleIcon className={props.className} onClick={props.onClick} />;
    }
}
