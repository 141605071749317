import {Theme} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/styles";
import React, {useContext} from "react";
import {UserContext} from "../../context";
import {StudentModel} from "../../DB/entities/student";
import GroupWithLessons from "./GroupWithLessons";
import {ProgressReportViewer} from "./ProgressReportViewer";
import Splitter from "../common/Splitter";
import {LessonsWithGroup} from "./StudentMain";

const useStyles = makeStyles(({palette, spacing}: Theme) => {
    const mainPadding = spacing(3);

    return createStyles({
        root: {
            flexGrow: 1,
        },
        leftArea: {
            backgroundColor: palette.secondaryBackground.main,
            height: "100%",
            boxSizing: "border-box",
            padding: mainPadding,
        },
        rightArea: {
            backgroundColor: palette.primaryBackground.main,
            padding: mainPadding,
        },
    });
});

interface Props {
    lessonsWithGroup: LessonsWithGroup;
}

export default function StudentGroupView({lessonsWithGroup}: Props) {
    const classes = useStyles();
    const student = useContext(UserContext) as StudentModel;

    return (
        <Splitter
            className={classes.root}
            leftArea={
                <div className={classes.leftArea}>
                    <GroupWithLessons lessonsWithGroup={lessonsWithGroup} />
                </div>
            }
            rightArea={
                <div className={classes.rightArea}>
                    <ProgressReportViewer groupId={lessonsWithGroup.group.id} studentId={student.id} />
                </div>
            }
        />
    );
}
