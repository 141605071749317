import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import BrightInput from "./BrightInput";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 250,
        },
    },
};

interface Props<T> {
    selectedValueIndex: number;
    values: T[];
    onSelectedValueChanged?(valueIndex: number, prevSelectedIndex: number): void;
    className?: string;
    inputClassName?: string;
}

export default function BrightSelect<T>(props: Props<T>) {
    function handleChange(event: React.ChangeEvent<{name?: string; value: unknown}>) {
        if (props.onSelectedValueChanged) {
            props.onSelectedValueChanged(event.target.value as number, props.selectedValueIndex);
        }
    }

    const selectValues = props.values.map((value, index) => (
        <MenuItem value={index} key={index}>
            {value}
        </MenuItem>
    ));

    return (
        <Select
            fullWidth
            MenuProps={MenuProps}
            className={props.className}
            value={props.selectedValueIndex}
            input={<BrightInput inputProps={{className: props.inputClassName}} />}
            onChange={handleChange}
        >
            {selectValues}
        </Select>
    );
}
