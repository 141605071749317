import {Theme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import BrightInput from "./BrightInput";

const useStyles = makeStyles(({spacing}: Theme) => {
    return createStyles({
        assignment: {
            marginBottom: spacing(2),
        },
    });
});

interface Props {
    header: string;
    value: string;
    rows?: number;
    readonly?: boolean;
    onChange?(value: string): void;
}

function Assignment(props: Props) {
    const classes = useStyles();

    function handleChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        if (props.onChange) {
            props.onChange(event.target.value);
        }
    }

    return (
        <div className={classes.assignment}>
            <Typography color="textSecondary">
                {props.header}
            </Typography>
            <BrightInput multiline fullWidth
                         rows={props.rows}
                         value={props.value}
                         disabled={props.readonly}
                         onChange={handleChange} />
        </div>
    );
}

export default Assignment;
