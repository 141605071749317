import {Theme} from "@material-ui/core";
import {Delete} from "@material-ui/icons";
import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {LessonModel} from "../../DB/entities/lesson";
import {ProgressReportModel} from "../../DB/entities/reports/report";
import {StudentModel} from "../../DB/entities/student";
import {Period} from "../../util/Range";
import {Row} from "../common/Div";
import IconButtonWithConfirmation from "../common/IconButtonWithConfirmation";
import {ProgressReport} from "./helper";
import ProgressReportEditor from "./ProgressReportEditor";
import {ReportDownloadLink} from "./ReportDownloadLink";

const useStyles = makeStyles(({spacing, palette}: Theme) => {
    return createStyles({
        root: {
            backgroundColor: palette.secondaryBackground.main,
            padding: spacing(3),
            paddingLeft: spacing(4),
            minWidth: spacing(40),
            justifyContent: "space-between",
            alignItems: "flex-start",
        },
        reportEditor: {
            flexGrow: 1,
        },
        publishReportButton: {
            marginLeft: spacing(2),
            "&:hover": {
                backgroundColor: palette.alternate.main,
                color: "white",
            },
        },
    });
});

interface Props {
    report: ProgressReportModel;
    student: StudentModel;
    lessons: LessonModel[];

    onReportSave?(report: ProgressReportModel): Promise<void>;
    onReportDelete?(report: ProgressReportModel): Promise<void>;
}

export default function EditProgressReport(props: Props) {
    const classes = useStyles();

    function handleReportsSave(reports: ProgressReport[], period: Period, draft: boolean) {
        if (props.onReportSave) {
            return props.onReportSave({...props.report, ...reports[0], period, draft});
        }
        return Promise.resolve();
    }

    function handleDelete() {
        if (props.onReportDelete)
            return props.onReportDelete(props.report);
    }

    return (
        <Row className={classes.root}>
            <div className={classes.reportEditor}>
                <ProgressReportEditor
                    title=""
                    period={props.report.period}
                    reports={[{...props.report, student: props.student}]}
                    onReportsSave={handleReportsSave}
                    lessons={props.lessons}
                />
            </div>
            <Row>
                <ReportDownloadLink reportPreview={{report: props.report, studentName: props.student.name}} />
                <IconButtonWithConfirmation
                    id="qa-delete-button"
                    icon={Delete}
                    tooltip="Delete report"
                    text="Do you really want to delete this report?"
                    onClick={handleDelete}
                />
            </Row>
        </Row>
    );
}
