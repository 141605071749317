import firebase from "firebase/app";
import {useContext, useEffect, useState} from "react";
import {DbContext} from "../context";
import {UserModel} from "../DB/entities/user";
import {auth} from "../init-firebase";

interface AuthStatus {
    user: UserModel | undefined;
    isLoading: boolean;
}

function useAuthentication(): AuthStatus {
    const [authStatus, setAuthStatus] = useState<AuthStatus>({
        user: undefined,
        isLoading: true,
    });

    const db = useContext(DbContext);
    useEffect(() => {
        return auth.onAuthStateChanged(async (user: firebase.User | null) => {
            if (user) {
                try {
                    setAuthStatus({
                        user: await db.fetchUserByAuthId(user.uid),
                        isLoading: false,
                    });
                    return;
                } catch (e) {
                    auth.signOut();
                }
            }

            setAuthStatus({
                user: undefined,
                isLoading: false,
            });
        });
    }, [db]);

    return authStatus;
}

export default useAuthentication;
