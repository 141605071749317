import {Theme} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/styles";
import {useCallback, useEffect, useMemo, useState} from "react";
import * as React from "react";
import {LessonModel} from "../../DB/entities/lesson";
import {Period} from "../../util/Range";
import {sortLessons} from "../../util/Util";
import BrightButton from "../common/BrightButton";
import {Row} from "../common/Div";
import {calculateAttendance, ProgressReport} from "./helper";
import {ProgressReportHeader} from "./ProgressReportHeader";
import {StudentProgressReportCard} from "./StudentProgressReportCard";

const useStyles = makeStyles(({palette, spacing}: Theme) => {
    return createStyles({
        header: {
            marginBottom: spacing(3),
        },
        studentCardContainer: {
            marginBottom: spacing(2),
        },
        saveDraftButton: {
            marginRight: spacing(2),
        },
        publishReportButton: {
            "&:hover": {
                backgroundColor: palette.alternate.main,
                color: "white",
            },
        },
    });
});

interface Props {
    title: string;
    period: Period;
    reports: ProgressReport[];
    lessons: LessonModel[];

    onReportsSave?(reports: ProgressReport[], period: Period, draft: boolean): Promise<void>;
}

export default function ProgressReportEditor(props: Props) {
    const classes = useStyles();

    const lessons = useMemo(() => {
        const ls = [...props.lessons];
        sortLessons(ls);
        return ls;
    }, [props.lessons]);

    const [period, setPeriod] = useState<Period>(props.period);
    const [reports, setReports] = useState(props.reports);

    useEffect(() => {
        setReports(prevState => {
            return prevState.map(report => ({
                ...report,
                attendance: calculateAttendance(report.student.id, period, lessons),
            }));
        });
    }, [lessons, period]);

    const handleReportChange = useCallback((newReport: ProgressReport) => {
        setReports(prevState => {
            const newReports = [...prevState];
            for (let i = 0; i < newReports.length; i++) {
                if (newReports[i].student.id === newReport.student.id) newReports[i] = newReport;
            }
            return newReports;
        });
    }, []);

    const onReportsSave = props.onReportsSave;

    const handlePublishReports = useCallback(() => {
        if (onReportsSave) return onReportsSave(reports, period, false);
    }, [period, onReportsSave, reports]);

    const handleSaveDraft = useCallback(() => {
        if (onReportsSave) return onReportsSave(reports, period, true);
    }, [onReportsSave, period, reports]);

    return (
        <>
            <ProgressReportHeader
                title={props.title}
                period={period}
                onPeriodChange={setPeriod}
                className={classes.header}
            />
            {reports.map(report => (
                <StudentProgressReportCard
                    key={report.student.id}
                    report={report}
                    onReportChange={handleReportChange}
                    className={classes.studentCardContainer}
                />
            ))}

            <Row>
                {reports.some(r => r.draft) && (
                    <BrightButton variant="outlined" className={classes.saveDraftButton} onClick={handleSaveDraft}>
                        Save draft
                    </BrightButton>
                )}
                <BrightButton variant="outlined" className={classes.publishReportButton} onClick={handlePublishReports}>
                    Publish report
                </BrightButton>
            </Row>
        </>
    );
}
