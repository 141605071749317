import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";

interface Props {
    dark: boolean;
    className: string;
}

function SunLogo(props: Props) {
    const variantColor = props.dark ? "#fff" : "#344da1";
    const redColor = "#ee2828";

    return (
        <SvgIcon className={props.className} viewBox="0 0 671.58 388.52">
            <path d="M276.17,330.43a58.09,58.09,0,1,1,58.08,58.09A58.08,58.08,0,0,1,276.17,330.43Z" fill={redColor} />
            <path d="M287,183.69a48.91,48.91,0,1,1,48.9,48.91A48.91,48.91,0,0,1,287,183.69Z" fill={variantColor} />
            <path
                d="M482.52,381.24a48.92,48.92,0,1,1,48.92-48.91A48.92,48.92,0,0,1,482.52,381.24Z"
                fill={variantColor}
            />
            <path d="M336.11,120.66a31.23,31.23,0,1,1,31-31.43A31.23,31.23,0,0,1,336.11,120.66Z" fill={variantColor} />
            <path d="M616.76,332.11a31.23,31.23,0,1,1-31.44-31A31.23,31.23,0,0,1,616.76,332.11Z" fill={variantColor} />
            <path d="M116.83,332.78a31.23,31.23,0,1,1-31.44-31A31.24,31.24,0,0,1,116.83,332.78Z" fill={variantColor} />
            <path d="M342.63,35.7a18.48,18.48,0,1,1,10.48-24A18.49,18.49,0,0,1,342.63,35.7Z" fill={redColor} />
            <path d="M670.3,325.58a18.5,18.5,0,1,1-24-10.47A18.49,18.49,0,0,1,670.3,325.58Z" fill={redColor} />
            <path d="M35.7,326.25a18.48,18.48,0,1,1-24-10.47A18.5,18.5,0,0,1,35.7,326.25Z" fill={redColor} />
            <path d="M127.94,123.57a18.48,18.48,0,1,1-9.53-24.34A18.47,18.47,0,0,1,127.94,123.57Z" fill={redColor} />
            <path d="M575,100.19a18.48,18.48,0,1,1-24.34,9.52A18.49,18.49,0,0,1,575,100.19Z" fill={redColor} />
            <path d="M184.18,184a31.23,31.23,0,1,1-.3-44.15A31.22,31.22,0,0,1,184.18,184Z" fill={variantColor} />
            <path d="M534.85,137.44a31.22,31.22,0,1,1-44.16.31A31.22,31.22,0,0,1,534.85,137.44Z" fill={variantColor} />
            <path d="M186,381.92A48.92,48.92,0,1,1,234.9,333,48.92,48.92,0,0,1,186,381.92Z" fill={variantColor} />
            <path d="M194.83,261.71a48.91,48.91,0,1,1,69.17,0A48.91,48.91,0,0,1,194.83,261.71Z" fill={redColor} />
            <path d="M473.69,261.71a48.91,48.91,0,1,1,0-69.18A48.93,48.93,0,0,1,473.69,261.71Z" fill={redColor} />
        </SvgIcon>
    );
}

export default SunLogo;
