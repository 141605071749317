import {Theme, Typography} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/styles";
import React from "react";
import {Skills} from "../../DB/entities/reports/report";
import {useDb} from "../../hooks/useDb";
import {periodToString} from "../reporting/helper";
import BrightSelect from "../common/BrightSelect";
import {Column} from "../common/Div";
import {FilledDiv, MarkFilledDiv} from "../common/FilledDiv";
import SchoolOutlinedIcon from "@material-ui/icons/SchoolOutlined";

interface Props {
    groupId: string;
    studentId: string;
}

const useStyles = makeStyles(({spacing, palette}: Theme) => {
    return createStyles({
        select: {
            marginBottom: spacing(2),
        },
        title: {
            marginBottom: spacing(4),
        },
        bars: {
            padding: spacing(1),
        },
        filledDiv: {
            marginBottom: spacing(1),
        },
        testScoreFilledDiv: {
            marginTop: spacing(1),
        },
        comment: {
            marginTop: spacing(2),
            padding: spacing(2),
            border: "1px solid",
            borderRadius: spacing(1),
            borderColor: "rgba(0, 0, 0, 0.15)",
        },
        noReports: {
            marginTop: spacing(8),
            alignItems: "center",
            color: palette.gray.main,
            marginBottom: spacing(8),
        },
        noReportsIcon: {
            width: spacing(12),
            height: spacing(12),
            marginBottom: spacing(2),
        }
    });
});

export function ProgressReportViewer({groupId, studentId}: Props) {
    const classes = useStyles();

    const [progressReports] = useDb(
        async db => {
            let reports = await db.fetchProgressReports(studentId, groupId);
            return reports.filter(item => !item.draft);
        },
        [groupId, studentId],
    );

    const [selectedProgressReportIndex, setSelectedProgressReportIndex] = React.useState<number>(0);

    function handleProgressReportChange(newSelectedIndex: number) {
        setSelectedProgressReportIndex(newSelectedIndex);
    }
    const currentReport = progressReports && progressReports[selectedProgressReportIndex];

    return progressReports && progressReports.length > 0 ? (
        <>
            <Typography variant="h4" className={classes.title}>
                Оцінки
            </Typography>
            <BrightSelect
                values={progressReports.map(item => periodToString(item.period))}
                selectedValueIndex={selectedProgressReportIndex}
                onSelectedValueChanged={handleProgressReportChange}
                className={classes.select}
            />

            {currentReport && (
                <Column className={classes.bars}>
                    <MarkFilledDiv
                        mark={currentReport.reading}
                        label={Skills.reading.ua}
                        className={classes.filledDiv}
                    />
                    <MarkFilledDiv
                        mark={currentReport.listening}
                        label={Skills.listening.ua}
                        className={classes.filledDiv}
                    />
                    <MarkFilledDiv
                        mark={currentReport.writing}
                        label={Skills.writing.ua}
                        className={classes.filledDiv}
                    />
                    <MarkFilledDiv
                        mark={currentReport.speaking}
                        label={Skills.speaking.ua}
                        className={classes.filledDiv}
                    />
                    <MarkFilledDiv
                        mark={currentReport.homework}
                        label={Skills.homework.ua}
                        className={classes.filledDiv}
                    />
                    <MarkFilledDiv
                        mark={currentReport.participation}
                        label={Skills.participation.ua}
                        className={classes.filledDiv}
                    />
                    {currentReport.testScore && (
                        <FilledDiv
                            label="Тест"
                            value={currentReport.testScore}
                            maxValue={100}
                            description={`${currentReport.testScore}`}
                            color="#80bdff"
                            className={classes.testScoreFilledDiv}
                        />
                    )}
                    <Typography variant="body2" className={classes.comment}>
                        {currentReport.comment}
                    </Typography>
                </Column>
            )}
        </>
    ) : (
        <Column className={classes.noReports}>
            <SchoolOutlinedIcon className={classes.noReportsIcon} />
            <Typography>Тут зʼявляться звіти про навчання</Typography>
        </Column>
    );
}
