import {Theme} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles, useTheme} from "@material-ui/styles";
import {ReactNode} from "react";
import * as React from "react";
import useRouter from "../../hooks/useRouter";
import SunLogo from "../../resources/icons/SunLogo";
import {Index} from "../../util/routes/routes";

const useStyles = makeStyles(({palette, spacing}: Theme) => {
    return createStyles({
        root: {
            position: "relative",
            width: "100%",
            top: 0,
            left: 0,

            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        logoContainer: {
            display: "flex",
            padding: spacing(1),
            cursor: "pointer",
            alignItems: "center",
        },
        icon: {
            width: spacing(5),
        },
        iconText: {
            marginLeft: spacing(1),
            color: "inherit",
        },
        rightBlock: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
        },
    });
});

interface Props {
    mainText: string;
    isLoading?: boolean;
    children?: ReactNode;
}

export default function TopHeader(props: Props) {
    const classes = useStyles();
    const router = useRouter();

    const theme = useTheme<Theme>();
    const onLogoClicked = () => {
        Index.navigate(router);
    };
    return (
        <div className={classes.root}>
            <div className={classes.logoContainer} onClick={onLogoClicked}>
                {props.isLoading ? (
                    <CircularProgress color="secondary" size={theme.spacing(3.5)} />
                ) : (
                    <SunLogo dark={true} className={classes.icon} />
                )}
                <Typography id="qa-user-role" variant="subtitle1" className={classes.iconText}>
                    {props.mainText}
                </Typography>
            </div>
            <div className={classes.rightBlock}>{props.children}</div>
        </div>
    );
}
