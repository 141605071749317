import {Theme} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {Delete} from "@material-ui/icons";
import {createStyles, makeStyles} from "@material-ui/styles";
import React, {useMemo, useState} from "react";
import {StudentId, TeacherId} from "../../DB/entities/entity";
import {generateName, isOneToOne} from "../../DB/entities/groups/group";
import {groupLevels} from "../../DB/entities/groups/groupLevel";
import {createNewGroup, GroupModel} from "../../DB/entities/groups/groupModel";
import {GroupSchedule} from "../../DB/entities/groups/groupSchedule";
import {AllGroupTypes, GroupType} from "../../DB/entities/groups/groupType";
import {TeacherModel} from "../../DB/entities/teacher";
import {useDb} from "../../hooks/useDb";
import useRouter from "../../hooks/useRouter";
import {AdminRoutes} from "../../util/routes/routes";
import BrightButton from "../common/BrightButton";
import BrightInput from "../common/BrightInput";
import BrightSelect from "../common/BrightSelect";
import ButtonWithConfirmation from "../common/ButtonWithConfirmation";
import IconButtonWithConfirmation from "../common/IconButtonWithConfirmation";
import {Column, Row} from "../common/Div";
import PropWithLabel from "../common/PropWithLabel";
import {SaveButtonWithText} from "../common/SaveButtonWithText";
import Splitter from "../common/Splitter";
import Schedule from "../common/time/Schedule";
import UserWithAvatar from "../common/UserWithAvatar";
import GroupTypeSelection from "./GroupTypeSelection";
import MultipleEntitiesSelection from "./MultipleEntitiesSelection";
import classJ from "../../util/joinClasses";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.palette.secondaryBackground.main,
        },
        mainArea: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(5),
        },
        row: {
            marginBottom: theme.spacing(2),
        },
        field: {
            width: theme.spacing(40),
        },
        studentRow: {
            width: "50%",
        },
        groupName: {
            marginRight: theme.spacing(1),
        },
        groupNameSuffix: {
            width: theme.spacing(20),
        },
        marginRight: {
            marginRight: theme.spacing(1),
        },
        studentName: {
            "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
            },
        },
    });
});

interface Props {
    group?: GroupModel;
    teachers: TeacherModel[];
    allGroups: GroupModel[];
    onGroupChanged?(group: GroupModel): void;
    message?: string;
    onEdited?(): void;
    onGroupDeleted?(group: GroupModel): void;
    onDeleteAllLessons?(): void;
}

export default function AdminGroup(props: Props) {
    const classes = useStyles();
    const router = useRouter();

    const [groupModel, setGroupModel] = useState(props.group ? props.group : createNewGroup(props.teachers[0].id));

    const levels = useMemo(() => groupLevels(), []);

    const [students] = useDb(db => (props.group ? db.fetchStudentsById(props.group.studentIds) : []), []);

    const [saveEnabled, setSaveEnabled] = useState(false);

    const [lessons] = useDb(db => (props.group ? db.fetchLessonsByGroupId(props.group.id) : []), []);

    function change(groupProps: Partial<GroupModel>) {
        setGroupModel(prevState => ({
            ...prevState,
            ...groupProps,
        }));
        setSaveEnabled(true);
        if (props.onEdited) {
            props.onEdited();
        }
    }

    function handleGroupTypeChange(newGroupType: GroupType) {
        change({type: newGroupType});
    }

    function handleLevelChange(levelIndex: number) {
        const newLevel = levels[levelIndex];
        change({level: newLevel});
    }

    function handleScheduleChange(newSchedule: GroupSchedule): void {
        change({schedule: newSchedule});
    }

    function handleBookChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        change({studyBook: event.target.value});
    }

    function handleGroupNameSuffixChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        change({nameSuffix: event.target.value});
    }

    const onRowClicked = (rowId: StudentId) => {
        AdminRoutes.EditStudent.navigate(router, {id: rowId});
    };

    function handleSave() {
        if (props.onGroupChanged) {
            props.onGroupChanged(groupModel);
            setSaveEnabled(false);
        }
    }

    function handleTeachersChange(newTeachers: TeacherId[]) {
        change({teacherIds: newTeachers});
    }

    function handleDelete() {
        if (props.onGroupDeleted && props.group) {
            props.onGroupDeleted(props.group);
        }
    }

    function handleInactiveChanged() {
        change({inactive: !groupModel.inactive});
    }

    return (
        <div className={classes.root}>
            <Toolbar>
                <Typography variant="h5">{props.group ? "Редагувати" : "Додати групу"}</Typography>

                <SaveButtonWithText
                    disabled={!saveEnabled}
                    buttonId={"qa-save-group-btn"}
                    message={props.message}
                    onClick={handleSave}
                />

                {props.group && (
                    <Row>
                        {props.onDeleteAllLessons && (
                            <ButtonWithConfirmation
                                confirmationText="Ви дійсно бажаєте стерти всі уроки групи?"
                                onClick={props.onDeleteAllLessons}
                                disabled={lessons && lessons.length === 0}
                                className={classes.marginRight}
                            >
                                Стерти дані уроків {lessons ? `(${lessons.length})` : ""}
                            </ButtonWithConfirmation>
                        )}
                        <BrightButton
                            variant="outlined"
                            onClick={handleInactiveChanged}
                            className={classes.marginRight}
                        >
                            {groupModel.inactive ? "Активувати" : "Перевести в неактив"}
                        </BrightButton>
                        <IconButtonWithConfirmation
                            id="qa-delete-button"
                            icon={Delete}
                            text="Ви дійсно бажаєте видалити групу?"
                            onClick={handleDelete}
                        />
                    </Row>
                )}
            </Toolbar>
            <Splitter
                className={classes.mainArea}
                leftArea={
                    <Column>
                        <PropWithLabel label="Тип" className={classes.row}>
                            <GroupTypeSelection
                                className={classJ(classes.field, "qa-group-selection")}
                                selectedType={groupModel.type}
                                allGroupTypes={AllGroupTypes}
                                onChange={handleGroupTypeChange}
                            />
                        </PropWithLabel>
                        <PropWithLabel label="Рівень" className={classes.row}>
                            <BrightSelect
                                className={classJ(classes.field, "qa-level-selection")}
                                selectedValueIndex={levels.indexOf(groupModel.level)}
                                values={levels}
                                onSelectedValueChanged={handleLevelChange}
                            />
                        </PropWithLabel>
                        <PropWithLabel label="Назва" className={classes.row}>
                            <Row>
                                <Typography color="textSecondary" id="qa-group-name" className={classes.groupName}>
                                    {generateName(groupModel.type, groupModel.level)}
                                </Typography>
                                {!isOneToOne(groupModel.type) && (
                                    <BrightInput
                                        className={classes.groupNameSuffix}
                                        placeholder="Суфікс"
                                        value={groupModel.nameSuffix}
                                        onChange={handleGroupNameSuffixChange}
                                    />
                                )}
                            </Row>
                        </PropWithLabel>
                        <PropWithLabel
                            label={groupModel.teacherIds.length > 1 ? "Вчителі" : "Вчитель"}
                            className={classes.row}
                        >
                            <MultipleEntitiesSelection
                                className={classJ(classes.field, "qa-teacher-selection")}
                                selectedEntities={groupModel.teacherIds}
                                allEntities={props.teachers}
                                onChange={handleTeachersChange}
                                allowEmptySelection
                            />
                        </PropWithLabel>
                        <PropWithLabel label="Розклад" className={classes.row}>
                            <Schedule
                                schedule={groupModel.schedule}
                                className={classes.field}
                                onScheduleChange={handleScheduleChange}
                            />
                        </PropWithLabel>
                        <PropWithLabel label="Книжка" className={classes.row}>
                            <BrightInput
                                className={classes.field}
                                id="qa-group-book"
                                placeholder="Книжка"
                                value={groupModel.studyBook}
                                onChange={handleBookChange}
                            />
                        </PropWithLabel>
                    </Column>
                }
                rightArea={
                    students &&
                    students.length > 0 && (
                        <Column className={classes.studentRow}>
                            <Typography className={classes.row}>Студенти:</Typography>

                            {students.map((st, index) => (
                                <div onClick={() => onRowClicked(st.id)} key={index} >
                                    <UserWithAvatar key={index} person={st} userNameStyle={classes.studentName} />
                                </div>
                            ))}
                        </Column>
                    )
                }
            />
        </div>
    );
}
