import * as React from "react";
import {useCallback, useContext, useState} from "react";
import {RouteComponentProps} from "react-router";
import PageLoading from "../../components/common/PageLoading";
import {ProgressReport} from "../../components/reporting/helper";
import {TeacherCreateReport} from "../../components/teacher/TeacherCreateReport";
import {DbContext, UserContext} from "../../context";
import {Group} from "../../DB/entities/groups/group";
import {TeacherModel} from "../../DB/entities/teacher";
import {useAsync} from "../../hooks/useAsync";
import {Period} from "../../util/Range";
import {GroupIdParam, TeacherRoutes} from "../../util/routes/routes";

export function TeacherCreateReportContainer(props: RouteComponentProps<GroupIdParam>) {
    const teacher = useContext(UserContext) as TeacherModel;
    const groupId = props.match.params.id;

    const db = useContext(DbContext);

    const [isLoading, setIsLoading] = useState(false);

    const [state] = useAsync(async () => {
        const group = await db.fetchGroup(groupId);
        const studentsInGroup = await db.fetchStudentsById(group.studentIds);
        const lessons = await db.fetchLessonsByGroupId(groupId);
        return {
            group: new Group(group),
            lessons,
            studentsInGroup,
        };
    }, [db]);

    const handleCreateReports = useCallback(
        async (reports: ProgressReport[], period: Period, draft: boolean) => {
            setIsLoading(true);
            await Promise.all(reports.map(report =>
                db.createProgressReport({
                    ...report,
                    studentId: report.student.id,
                    period,
                    draft
                })));
            setIsLoading(false);
            TeacherRoutes.Group.navigate(props, { id: groupId, view: "reports"});
        },
        [db, groupId, props],
    );

    return state && !isLoading ? (
        <TeacherCreateReport
            teacher={teacher}
            group={state.group}
            lessons={state.lessons}
            studentsInGroup={state.studentsInGroup}
            onCreateReports={handleCreateReports}
        />
    ) : (
        <PageLoading />
    );
}
