import * as React from "react";
import {StyleSheet, View, Text} from "@react-pdf/renderer";

const borderColor = "rgba(224, 224, 224, 1)";

const styles = StyleSheet.create({
    root: {
        marginTop: 8,
        padding: 8,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: borderColor,
        fontSize: 8,
        backgroundColor: "rgba(224, 224, 224, 0.4)",
    },
});

interface Props {
    text: string;
}

export function ReportCommentPdf(props: Props) {
    return <View style={styles.root}>
        <Text>{props.text}</Text>
    </View>;
}