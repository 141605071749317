import {CircularProgress, Theme} from "@material-ui/core";
import {useTheme} from "@material-ui/styles";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default function PageLoading() {
    const theme = useTheme<Theme>();

    return (
        <Container>
            <CircularProgress color="secondary" size={theme.spacing(5)} />
        </Container>
    );
}
