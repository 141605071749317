import {EntityData} from "../../entities/entity";
import {ProgressReportModel} from "../../entities/reports/report";
import {FbProgressReportModelData, ToFbData, ToModel} from "../types";
import {fbTimeToMoment, momentToFbTime, removeUndefinedFields} from "./helpers";

export const fbDataToProgressReportModel: ToModel<ProgressReportModel, FbProgressReportModelData> = fbData => {
    const res: EntityData<ProgressReportModel> = {
        attendance: fbData.attendance,
        comment: fbData.comment,
        groupId: fbData.groupId,
        listening: fbData.listening,
        period: {
            start: fbTimeToMoment(fbData.periodStart),
            end: fbTimeToMoment(fbData.periodEnd)
        },
        reading: fbData.reading,
        speaking: fbData.speaking,
        studentId: fbData.studentId,
        testScore: fbData.testScore,
        writing: fbData.writing,
        homework: fbData.homework,
        participation: fbData.participation,
        draft: fbData.draft,
    }

    return res;
}

export const progressReportToFbData: ToFbData<ProgressReportModel, FbProgressReportModelData> = progressReport => {
    const res: FbProgressReportModelData = {
        attendance: progressReport.attendance,
        comment: progressReport.comment,
        groupId: progressReport.groupId,
        listening: progressReport.listening,
        periodStart: momentToFbTime(progressReport.period.start),
        periodEnd: momentToFbTime(progressReport.period.end),
        reading: progressReport.reading,
        speaking: progressReport.speaking,
        studentId: progressReport.studentId,
        testScore: progressReport.testScore,
        writing: progressReport.writing,
        homework: progressReport.homework,
        participation: progressReport.participation,
        draft: progressReport.draft,
    }

    return removeUndefinedFields(res);
}