import moment from "moment";
import {Entity, GroupId, StudentId, TeacherId} from "../entity";
import {DayOfTheWeek} from "./group";
import {GroupLevel} from "./groupLevel";
import {GroupSchedule} from "./groupSchedule";
import {GroupType} from "./groupType";

export interface GroupModel extends Entity<GroupId> {
    readonly type: GroupType;
    readonly nameSuffix: string;
    readonly level: GroupLevel;
    readonly schedule: GroupSchedule;
    readonly studyBook: string;
    readonly studentIds: StudentId[];
    readonly teacherIds: TeacherId[];
    readonly inactive: boolean;
}

export function createNewGroup(teacher: TeacherId): GroupModel {
    return {
        id: "",
        type: GroupType.Adults,
        nameSuffix: "",
        level: GroupLevel.A1,
        schedule: new GroupSchedule([
            [
                DayOfTheWeek.Monday,
                {
                    start: moment().hour(12).minute(0),
                    duration: moment.duration(2, "hours"),
                },
            ],
        ]),
        studyBook: "",
        studentIds: [],
        teacherIds: [teacher],
        inactive: false,
    };
}
