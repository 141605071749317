import {Typography} from "@material-ui/core";
import * as React from "react";
import {useMemo} from "react";
import {allRealMarks, Mark, markToColor, markToString} from "../../DB/entities/reports/mark";
import SelectOptionsColored from "./SelectOptionsColored";

interface MarkColoredSelectionProps {
    selectedMark: Mark;

    onSelectedMarkChange?(mark: Mark): void;

    readOnly?: boolean;
}

export default function MarkColoredSelection(props: MarkColoredSelectionProps) {
    const marks = useMemo(allRealMarks, []);

    const markOptions = useMemo(() => {
        return marks.map(mark => ({
            value: <Typography>{markToString(mark)}</Typography>,
            color: markToColor(mark),
        }));
    }, [marks]);

    const selectedIndex = useMemo(() => marks.indexOf(props.selectedMark), [marks, props.selectedMark]);

    function handleSelected(selectedIndex: number | undefined) {
        if (props.onSelectedMarkChange)
            props.onSelectedMarkChange(selectedIndex === undefined ? Mark.NA : marks[selectedIndex]);
    }

    return (
        <SelectOptionsColored
            selectedIndex={selectedIndex} options={markOptions}
            onSelectedChange={handleSelected}
            readOnly={props.readOnly} />
    );
}
