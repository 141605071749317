import {useCallback, useMemo, useState} from "react";
import {BrightInputProps} from "../components/common/BrightInput";

type FieldValidationProps = "error" | "errorMessage" | "onBlur";

export function useInputValidation<TForm extends {[P in keyof TForm]: any}, TValidatedField extends keyof TForm>(
    formState: TForm,
    validations: {
        [field in TValidatedField]: (value: TForm[field], state: TForm) => string | undefined;
    },
): {
    validations: {
        [P in keyof TForm]: Pick<BrightInputProps, FieldValidationProps>;
    };
    isValid: boolean;
    validate: () => boolean;
} {
    const [touched, setTouched] = useState<TValidatedField[]>([]);

    const validateAll = useCallback(
        (touchedFields: TValidatedField[]) => {
            const validatedFields = Object.keys(validations) as TValidatedField[];

            const validationResults = Object.assign(
                {},
                ...validatedFields.map(field => {
                    const validationFun = validations[field];

                    if (!validationFun) {
                        return {};
                    }

                    const validationResult = validationFun(formState[field], formState);
                    const wasTouched = touchedFields.includes(field);
                    const errorMessage = wasTouched ? validationResult || " " : " ";

                    const error = !!validationResult && wasTouched;

                    return {
                        [field]: {
                            error,
                            errorMessage,
                            onBlur: () => {
                                setTouched(t => [...t, field]);
                            },
                        },
                    };
                }),
            );

            const isValid = Object.entries<Pick<BrightInputProps, FieldValidationProps>>(validationResults).every(
                ([_, value]) => !value.error,
            );

            return {
                isValid,
                validations: validationResults,
            };
        },
        [formState, validations],
    );

    const results = useMemo(() => {
        const res = validateAll(touched);

        return {
            validations: {
                ...res.validations,
            },
            isValid: res.isValid,
        };
    }, [touched, validateAll]);

    const validateNow = (): boolean => {
        const allFields = Object.keys(validations) as TValidatedField[];
        setTouched(allFields);
        return validateAll(allFields).isValid;
    };

    return {
        ...results,
        validate: validateNow,
    };
}
