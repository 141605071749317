import {Theme, Typography} from "@material-ui/core";
import transitions from "@material-ui/core/styles/transitions";
import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {GroupId} from "../../DB/entities/entity";
import {Group} from "../../DB/entities/groups/group";
import {groupTypeToColor} from "../../DB/entities/groups/groupType";

const useStyles = makeStyles(({palette, spacing}: Theme) => {
    const previewHeight = spacing(15);
    const previewPadding = spacing(2);
    return createStyles({
        root: {
            width: spacing(60),
            height: previewHeight,
            overflow: "hidden",
            borderColor: palette.gray.main,
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: spacing(1),
            backgroundColor: palette.primaryBackground.main,
            boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            transition: transitions.create(["box-shadow"]),
            "&:hover": {
                boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
            },
            cursor: "pointer",
        },
        topArea: {
            boxSizing: "border-box",
            padding: previewPadding,
            height: (previewHeight / 3) * 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        timeString: {
            fontSize: spacing(3.5),
        },
        groupName: {
            color: palette.gray.main,
            fontSize: spacing(2),
        },
        bottomArea: {
            height: previewHeight / 3,
            paddingLeft: previewPadding,
            paddingRight: previewPadding,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        bottomString: {
            fontSize: spacing(2),
        },
    });
});

interface Props {
    group: Group;
    onGroupClick(id: GroupId): void;
}

function GroupPreview(props: Props) {
    const classes = useStyles();

    function handleClick() {
        props.onGroupClick(props.group.id);
    }

    return (
        <div className={classes.root} onClick={handleClick}>
            <div className={classes.topArea}>
                <Typography className={classes.timeString}>{props.group.schedule.getGroupTimeStr()}</Typography>
                <Typography className={classes.groupName}>{props.group.name}</Typography>
            </div>
            <div className={classes.bottomArea} style={{backgroundColor: groupTypeToColor(props.group.type)}}>
                <Typography className={classes.bottomString}>{props.group.level}</Typography>
                <Typography className={classes.bottomString}>{props.group.studyBook}</Typography>
            </div>
        </div>
    );
}

export default GroupPreview;
