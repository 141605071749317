import {List, ListItem, Theme} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {StudentModel} from "../../../DB/entities/student";
import UserWithAvatar from "../UserWithAvatar";

const useStyles = makeStyles(({spacing}: Theme) => {
    return createStyles({
        studentCard: {
            marginBottom: spacing(2),
            boxSizing: "border-box",
            padding: spacing(0.75),
            alignItems: "center",
            borderRadius: spacing(1),
            display: "flex",
            backgroundColor: "white",
        },
    });
});

interface Props {
    students: StudentModel[];
    rightSideOfStudent?: (student: StudentModel) => React.ReactNode;
    onClick?: (student: StudentModel) => void;
}

export default function StudentsList(props: Props) {
    const classes = useStyles();
    return (
        <List>
            {props.students.map(student => (
                <ListItem
                    button={!!props.onClick as any}
                    key={student.id}
                    className={classes.studentCard}
                    onClick={() => props.onClick && props.onClick(student)}
                >
                    <UserWithAvatar person={student} />
                    {props.rightSideOfStudent && props.rightSideOfStudent(student)}
                </ListItem>
            ))}
        </List>
    );
}
