import moment from "moment";
import * as React from "react";
import {useContext} from "react";
import {RouteComponentProps} from "react-router";
import PageLoading from "../../components/common/PageLoading";
import TeacherAllGroups from "../../components/teacher/TeacherAllGroups";
import {UserContext} from "../../context";
import {GroupId} from "../../DB/entities/entity";
import {Group, isOneToOne} from "../../DB/entities/groups/group";
import {TeacherModel} from "../../DB/entities/teacher";
import {useDb} from "../../hooks/useDb";
import {TeacherRoutes} from "../../util/routes/routes";
import {groupBySchedule} from "../../util/Util";

function TeacherAllGroupsContainer(props: RouteComponentProps) {
    const teacher = useContext(UserContext) as TeacherModel;

    const date = moment();
    const [groups] = useDb(async db => {
        const teacherGroups = (await db.fetchGroupsByTeacherId(teacher.id)).filter(g => !g.inactive);
        return Promise.all(
            teacherGroups.map(async g => {
                if (isOneToOne(g.type, g.studentIds)) {
                    const studentName = (await db.fetchStudent(g.studentIds[0])).name;
                    return new Group(g, studentName);
                }
                return new Group(g);
            }),
        );
    });

    if (groups) {
        const groupsToday = groups.filter(group => group.schedule.has(date.weekday()));

        const handleGroupClick = (groupId: GroupId) => {
            TeacherRoutes.Group.navigate(props, {id: groupId, view: "lessons"});
        };

        const allGroupsBySchedule = Array.from(groupBySchedule(groups));

        return (
            <TeacherAllGroups
                date={date}
                groupClicked={handleGroupClick}
                groupsThatDate={groupsToday}
                groupsBySchedule={allGroupsBySchedule}
            />
        );
    }

    return <PageLoading />;
}

export default TeacherAllGroupsContainer;
