import {delay} from "q";
import DB from "../db";
import {EntityData, GroupId, Id, LessonId, StudentId, TeacherId, UserId} from "../entities/entity";
import {GroupModel} from "../entities/groups/groupModel";
import {LessonModel} from "../entities/lesson";
import {ProgressReportModel} from "../entities/reports/report";
import {StudentModel} from "../entities/student";
import {TeacherModel} from "../entities/teacher";
import {UserModel, UserRole} from "../entities/user";
import {generateGroups, generateLessons, generateStudents, generateTeachers} from "./generators";

let counter = 100;
export function generateNextId(prefix?: string): Id {
    return `${prefix}${counter++}`;
}

export class StaticDB implements DB {
    private readonly DELAY: number = 0;

    private readonly teachers: TeacherModel[];
    private readonly students: StudentModel[];
    private readonly groups: GroupModel[];
    private lessons: LessonModel[];
    private readonly progressReports: ProgressReportModel[];

    constructor() {
        this.teachers = generateTeachers();
        this.students = generateStudents();
        this.groups = generateGroups();
        this.lessons = generateLessons();
        this.progressReports = [];
    }

    private async res<T>(value: T): Promise<T> {
        await delay(this.DELAY);
        return value;
    }

    public async fetchGroup(id: GroupId): Promise<GroupModel> {
        return this.res(this.groups.find(value => value.id === id)!!);
    }

    public async fetchGroups(): Promise<GroupModel[]> {
        return this.res(this.groups);
    }

    public fetchGroupsByTeacherId(id: TeacherId): Promise<GroupModel[]> {
        return this.res(this.groups.filter(value => value.teacherIds.includes(id)));
    }

    public fetchGroupsByStudentId(id: StudentId): Promise<GroupModel[]> {
        return this.res(this.groups.filter(g => g.studentIds.includes(id)));
    }

    public fetchLesson(id: LessonId): Promise<LessonModel> {
        return this.res(this.lessons.find(value => value.id === id)!!);
    }

    public fetchLessons(): Promise<LessonModel[]> {
        return this.res(this.lessons);
    }

    public fetchLessonsByGroupId(id: GroupId): Promise<LessonModel[]> {
        const res = this.lessons.filter(value => value.groupId === id);
        return this.res(res);
    }

    public fetchStudent(id: StudentId): Promise<StudentModel> {
        return this.res(this.students.find(value => value.id === id)!!);
    }

    public fetchStudents(): Promise<StudentModel[]> {
        return this.res(this.students);
    }

    public fetchStudentsById(ids: StudentId[]): Promise<StudentModel[]> {
        return this.res(this.students.filter(s => ids.includes(s.id)));
    }

    public fetchTeachers(): Promise<TeacherModel[]> {
        return this.res(this.teachers);
    }

    public fetchUser(id: UserId): Promise<UserModel> {
        return this.res({
            id: "teacher0",
            name: "Andrew Gadion",
            role: UserRole.Teacher,
            email: "",
        });
    }

    public resetUser(student: StudentModel): Promise<void> {
        return Promise.reject();
    }

    public fetchUserByAuthId(authId: Id): Promise<UserModel> {
        return this.fetchUser(authId);
    }

    public createGroup(group: EntityData<GroupModel>): Promise<GroupModel> {
        const newGroup = {...group, id: generateNextId("group")};
        this.groups.push(newGroup);
        return Promise.resolve(newGroup);
    }

    public updateGroup(group: GroupModel): Promise<GroupModel> {
        const existingGroupIndex = this.groups.findIndex(v => v.id === group.id);
        if (existingGroupIndex >= 0) {
            this.groups[existingGroupIndex] = group;
            return Promise.resolve(group);
        }

        return Promise.reject();
    }

    public deleteGroup(groupId: GroupId): Promise<void> {
        const groupToDeleteIndex = this.groups.findIndex(g => g.id === groupId);
        if (groupToDeleteIndex >= 0) {
            this.groups.splice(groupToDeleteIndex, 1);
        }

        return this.res(undefined);
    }

    public addStudentToGroup(groupModel: GroupModel, studentId: StudentId): Promise<void> {
        const group = this.groups.find(v => v.id === groupModel.id);
        if (group) {
            const existingStudent = group.studentIds.find(s => s === studentId);
            if (!existingStudent) {
                group.studentIds.push(studentId);
            }
        }

        return Promise.resolve();
    }

    public removeStudentFromGroup(groupModel: GroupModel, studentId: StudentId): Promise<void> {
        const group = this.groups.find(v => v.id === groupModel.id);
        if (group) {
            const existingStudentIndex = group.studentIds.findIndex(s => s === studentId);
            if (existingStudentIndex >= 0) {
                group.studentIds.splice(existingStudentIndex, 1);
            }
        }

        return Promise.resolve();
    }

    public createStudent(student: EntityData<StudentModel>): Promise<StudentModel> {
        const newStudent = {...student, id: generateNextId("student")};
        this.students.push(newStudent);
        return Promise.resolve(newStudent);
    }

    public updateStudent(student: StudentModel): Promise<StudentModel> {
        const existingStudentIndex = this.students.findIndex(v => v.id === student.id);
        if (existingStudentIndex >= 0) {
            this.students[existingStudentIndex] = student;
            return Promise.resolve(student);
        }
        return Promise.reject();
    }

    public deleteStudent(student: StudentModel): Promise<void> {
        const studentToDeleteIndex = this.students.findIndex(s => s.id === student.id);
        if (studentToDeleteIndex >= 0) {
            this.students.splice(studentToDeleteIndex, 1);
        }

        return this.res(undefined);
    }

    public createLesson(lessonData: EntityData<LessonModel>): Promise<LessonModel> {
        const nextId = generateNextId("lesson");
        const newLesson = {
            id: nextId,
            ...lessonData,
        };
        this.lessons.push(newLesson);
        return this.res(newLesson);
    }

    public deleteLesson(lesson: LessonModel): Promise<void> {
        const lessonToDeleteIndex = this.lessons.findIndex(l => l.id === lesson.id);
        if (lessonToDeleteIndex >= 0) {
            this.lessons.splice(lessonToDeleteIndex, 1);
        }

        return this.res(undefined);
    }

    public deleteLessonsForGroup(groupId: GroupId): Promise<void> {
        this.lessons = this.lessons.filter(l => l.groupId !== groupId);
        return this.res(undefined);
    }

    public updateLesson(lesson: LessonModel): Promise<LessonModel> {
        const existingLessonIndex = this.lessons.findIndex(l => l.id === lesson.id);
        if (existingLessonIndex >= 0) {
            this.lessons[existingLessonIndex] = lesson;
            return this.res(lesson);
        }
        return Promise.reject();
    }

    createProgressReport(reportData: EntityData<ProgressReportModel>): Promise<ProgressReportModel> {
        const nextId = generateNextId("p_report");
        const newReport = {
            id: nextId,
            ...reportData,
        };
        this.progressReports.push(newReport);
        return this.res(newReport);
    }

    updateProgressReport(report: ProgressReportModel): Promise<void> {
        const reportToUpdateIndex = this.progressReports.findIndex(r => r.id === report.id);
        if (reportToUpdateIndex >= 0) {
            this.progressReports[reportToUpdateIndex] = report;
        }

        return this.res(undefined);
    }

    deleteProgressReport(report: ProgressReportModel): Promise<void> {
        const reportToDeleteIndex = this.progressReports.findIndex(r => r.id === report.id);
        if (reportToDeleteIndex >= 0) {
            this.progressReports.splice(reportToDeleteIndex, 1);
        }

        return this.res(undefined);
    }

    fetchProgressReports(id: StudentId, groupId: GroupId): Promise<ProgressReportModel[]> {
        return this.res(this.progressReports.filter(r => id === r.id && r.groupId === groupId));
    }

    fetchProgressReport(reportId: Id): Promise<ProgressReportModel> {
        return this.res(this.progressReports.find(r => r.id === reportId)!!);
    }
}
