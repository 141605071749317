import {Button, Theme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/styles";
import * as qs from "query-string";
import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {PrimaryFullPageContainer} from "../App";
import {auth} from "../init-firebase";
import SunLogo from "../resources/icons/SunLogo";
import {Index} from "../util/routes/routes";
import BrightInput from "./common/BrightInput";
import PageLoading from "./common/PageLoading";

interface AuthActionParams {
    mode: string;
    oobCode: string;
    apiKey: string;
    lang: string;
}

const useStyles = makeStyles(({spacing, palette}: Theme) => {
    return createStyles({
        icon: {
            marginTop: spacing(8),
            height: spacing(25),
            width: spacing(25),
        },
        input: {
            width: spacing(40),
            marginBottom: spacing(2),
        },
        button: {
            borderRadius: spacing(1),
            borderColor: palette.primaryBackground.main,
            color: palette.primaryBackground.main,
        },
        buttonRoot: {
            borderRadius: spacing(1),
            borderColor: palette.primaryBackground.main,
            color: palette.primaryBackground.main,
        },
        buttonDisabled: {
            backgroundColor: palette.primaryBackground.main,
            opacity: 0.7,
        },

        error: {
            color: palette.secondary.main,
            marginBottom: spacing(2),
            maxWidth: spacing(80),
        },
        message: {
            color: palette.primaryBackground.main,
            marginBottom: spacing(2),
            maxWidth: spacing(40),
        },
    });
});

export default function AuthAction(props: RouteComponentProps) {
    const classes = useStyles();
    const parsed = qs.parse(props.location.search);
    const [isLoading, setIsLoading] = useState(true);
    const [globalError, setGlobalError] = useState("");
    const [changeEnable, setChangeEnable] = useState(false);

    const [state, setState] = useState({
        password: "",
        confirmPassword: "",
        error: undefined,
    });

    useEffect(() => {
        if (
            state.password &&
            state.confirmPassword &&
            state.password.length > 0 &&
            state.confirmPassword.length > 0 &&
            state.password === state.confirmPassword
        ) {
            setChangeEnable(true);
        } else {
            setChangeEnable(false);
        }
    }, [state]);

    function onPasswordChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        setState({
            ...state,
            password: event.target.value,
            error: undefined,
        });
    }

    function onConfirmPasswordChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        setState(prevState => ({
            ...prevState,
            confirmPassword: event.target.value,
            error: undefined,
        }));
    }

    let action = {} as AuthActionParams;

    if (
        !(typeof parsed.mode === "string") ||
        !(typeof parsed.oobCode === "string") ||
        !(typeof parsed.apiKey === "string") ||
        !(typeof parsed.lang === "string")
    ) {
        Index.navigate(props);
    } else {
        action = (parsed as any) as AuthActionParams;
        if (action.mode !== "resetPassword") {
            Index.navigate(props);
        }
        auth.verifyPasswordResetCode(action.oobCode)
            .then(() => setIsLoading(false))
            .catch(error => {
                setGlobalError(error.message);
                setIsLoading(false);
            });
    }

    function onSubmit() {
        auth.confirmPasswordReset(action.oobCode, state.password).then(() => Index.navigate(props));
    }

    return (
        <PrimaryFullPageContainer>
            {isLoading ? (
                <PageLoading />
            ) : (
                <>
                    <SunLogo className={classes.icon} dark />
                    <Typography className={classes.message}>Create your new password</Typography>
                    {globalError ? (
                        <Typography className={classes.error}>{globalError} </Typography>
                    ) : (
                        <>
                            <BrightInput
                                placeholder="New password"
                                type="password"
                                className={classes.input}
                                onChange={onPasswordChange}
                            />

                            <BrightInput
                                placeholder="Confirm password"
                                type="password"
                                className={classes.input}
                                onChange={onConfirmPasswordChange}
                            />

                            {state.error && <Typography className={classes.message}>{state.error}</Typography>}
                            <Button
                                variant="outlined"
                                disabled={!changeEnable}
                                classes={{
                                    root: classes.buttonRoot,
                                    disabled: classes.buttonDisabled,
                                }}
                                onClick={onSubmit}
                            >
                                Submit
                            </Button>
                        </>
                    )}
                </>
            )}
        </PrimaryFullPageContainer>
    );
}
