import {useContext} from "react";
import {__RouterContext, RouteComponentProps} from "react-router";

/**
 * A public hook to access the imperative API
 */
export default function useRouter<T = {}>(): RouteComponentProps<T> {
    const context = useContext(__RouterContext);

    return context as RouteComponentProps<T>;
}
