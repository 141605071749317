import React, {useContext, useState} from "react";
import {RouteComponentProps} from "react-router";
import AdminGroup from "../../components/admin/AdminGroup";
import PageLoading from "../../components/common/PageLoading";
import {DbContext} from "../../context";
import {GroupModel} from "../../DB/entities/groups/groupModel";
import {useDb} from "../../hooks/useDb";
import {AdminRoutes, GroupIdParam} from "../../util/routes/routes";

export default function AdminEditGroupContainer(props: RouteComponentProps<GroupIdParam>) {
    const groupId = props.match.params.id;

    const database = useContext(DbContext);

    const [teachers] = useDb(db => db.fetchTeachers(), []);
    const [allGroups] = useDb(db => db.fetchGroups(), []);
    const group = allGroups && allGroups.find(g => g.id === groupId);

    const [message, setMessage] = useState<string | undefined>(undefined);
    const [progress, setProgress] = useState(false);

    function handleGroupChanged(groupModel: GroupModel) {
        database.updateGroup(groupModel).then(() => {
            setMessage("Збережено");
        });
    }

    function handleGroupEdited() {
        setMessage(undefined);
    }

    async function handleGroupDeleted(g: GroupModel) {
        await database.deleteGroup(g.id);
        AdminRoutes.Index.navigate(props);
    }

    async function handleDeleteAllLessons() {
        setProgress(true);
        await database.deleteLessonsForGroup(groupId);
        setMessage("Дані уроків видалено");
        setProgress(false);
    }

    return group && teachers && allGroups && !progress ? (
        <AdminGroup
            group={group}
            allGroups={allGroups}
            teachers={teachers}
            onGroupChanged={handleGroupChanged}
            message={message}
            onEdited={handleGroupEdited}
            onGroupDeleted={handleGroupDeleted}
            onDeleteAllLessons={handleDeleteAllLessons}
        />
    ) : (
        <PageLoading />
    );
}
