import {Tab, Tabs, Theme} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/styles";
import {Moment} from "moment";
import * as React from "react";
import {Group, GroupExtended} from "../../DB/entities/groups/group";
import {LessonModel} from "../../DB/entities/lesson";
import useRouter from "../../hooks/useRouter";
import {TeacherRoutes} from "../../util/routes/routes";
import {Column, Row} from "../common/Div";
import GroupLessonsView from "../lesson/GroupLessonsView";
import GroupOverview from "../group/GroupOverview";
import GroupProgressReportsView from "../reporting/GroupProgressReportsView";

const useStyles = makeStyles(({palette, spacing, breakpoints}: Theme) => {
    return createStyles({
        root: {
            flexGrow: 1,
        },
        top: {
            backgroundColor: palette.primaryBackground.main,
            padding: spacing(4),
        },
        groupOverviewContainer: {
            width: "50%",
            boxSizing: "border-box",
            [breakpoints.down("sm")]: {
                width: "100%",
            },
        },
    });
});

export enum GroupTab {
    LESSONS,
    REPORTS,
}

interface Props {
    group: GroupExtended;
    onCreateLesson(date: Moment, group: Group): Promise<LessonModel>;
    onDeleteLesson(lesson: LessonModel): Promise<void>;

    openedTab?: GroupTab;
}

export default function TeacherOneGroup(props: Props) {
    const classes = useStyles();

    const router = useRouter();
    function handleGenerateReport() {
        TeacherRoutes.CreateReport.navigate(router, {id: props.group.id});
    }

    const [selectedTab, setSelectedTab] = React.useState(props.openedTab ?? GroupTab.LESSONS);

    const handleSelectedTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
        TeacherRoutes.Group.navigate(router, {
            id: props.group.id,
            view: newValue === GroupTab.LESSONS ? "lessons" : "reports",
        });
    };

    return (
        <Column className={classes.root}>
            <Row className={classes.top}>
                <div className={classes.groupOverviewContainer}>
                    <GroupOverview group={props.group} />
                </div>
            </Row>
            <Tabs
                value={selectedTab}
                onChange={handleSelectedTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                <Tab label="Lessons" />
                <Tab label="Reports" />
            </Tabs>

            {selectedTab === 0 && (
                <GroupLessonsView
                    group={props.group}
                    onCreateLesson={props.onCreateLesson}
                    onDeleteLesson={props.onDeleteLesson}
                />
            )}
            {selectedTab === 1 && (
                <GroupProgressReportsView group={props.group} onGenerateReportClick={handleGenerateReport} />
            )}
        </Column>
    );
}
