import React, {useContext} from "react";
import PageLoading from "../../components/common/PageLoading";
import StudentMain from "../../components/student/StudentMain";
import {UserContext} from "../../context";
import {Group} from "../../DB/entities/groups/group";
import {StudentInfo, StudentModel} from "../../DB/entities/student";
import {useDb} from "../../hooks/useDb";
import {sortLessons} from "../../util/Util";

export default function StudentMainContainer() {
    const student = useContext(UserContext) as StudentModel;

    const [studentInfo] = useDb(async db => {
        const teacherPromise = db.fetchTeachers();
        const studentGroups = (await db.fetchGroupsByStudentId(student.id)).map(g => new Group(g));
        return new StudentInfo(
            student,
            studentGroups,
            (await teacherPromise).filter(t => studentGroups.find(g => g.teacherIds.includes(t.id))),
        );
    }, []);

    const [studentLessonsWithGroup] = useDb(
        async db => {
            if (studentInfo) {
                return Promise.all(
                    studentInfo.studentGroups
                        .map(g => ({
                            group: g,
                            lessonsPromise: db.fetchLessonsByGroupId(g.id),
                        }))
                        .map(async i => {
                            const lessons = await i.lessonsPromise;
                            sortLessons(lessons);
                            return {
                                group: i.group,
                                lessons,
                            };
                        }),
                );
            }
            return [];
        },
        [studentInfo],
    );

    return studentInfo && studentLessonsWithGroup ? (
        <StudentMain studentInfo={studentInfo} studentLessonsWithGroup={studentLessonsWithGroup} />
    ) : (
        <PageLoading />
    );
}
