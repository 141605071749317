import {Theme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/styles";
import moment from "moment";
import * as React from "react";
import {useMemo} from "react";
import {Group} from "../../DB/entities/groups/group";
import {LessonModel} from "../../DB/entities/lesson";
import {StudentModel} from "../../DB/entities/student";
import {TeacherModel} from "../../DB/entities/teacher";
import {Period} from "../../util/Range";
import {createEmptyProgressReport, ProgressReport} from "../reporting/helper";
import ProgressReportEditor from "../reporting/ProgressReportEditor";

const useStyles = makeStyles(({spacing, palette}: Theme) => {
    return createStyles({
        root: {
            backgroundColor: palette.secondaryBackground.main,
            padding: spacing(3),
            paddingLeft: spacing(4),
            minWidth: spacing(40),
        },
        header: {
            marginBottom: spacing(2),
        },
    });
});

interface Props {
    teacher: TeacherModel;
    group: Group;
    studentsInGroup: StudentModel[];
    lessons: LessonModel[];

    onCreateReports?(reports: ProgressReport[], period: Period, draft: boolean): Promise<void>;
}

export function TeacherCreateReport(props: Props) {
    const classes = useStyles();

    const period = useMemo(
        () => ({
            start: moment().subtract(1, "month"),
            end: moment(),
        }),
        [],
    );

    const reports = useMemo(
        () => props.studentsInGroup.map(student => createEmptyProgressReport(student, props.group.id)),
        [props.group.id, props.studentsInGroup],
    );

    return (
        <div className={classes.root}>
            <Typography variant="h4" className={classes.header}>
                Create report
            </Typography>

            <ProgressReportEditor
                title={props.group.name}
                period={period}
                reports={reports}
                lessons={props.lessons}
                onReportsSave={props.onCreateReports}
            />
        </div>
    );
}
