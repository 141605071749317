import {InputBaseProps} from "@material-ui/core/InputBase";
import moment, {Moment} from "moment";
import React, {useState} from "react";
import BrightInput from "./BrightInput";

interface Props extends Pick<InputBaseProps, "placeholder"> {
    date?: Moment;
    onChange?(newDate?: Moment): void;

    className: string;
}

export default function DateInput(props: Props) {
    const [dateStr, setDateStr] = useState(props.date ? props.date.format("DD.MM.YYYY") : "");

    function handleDateChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        setDateStr(event.target.value);
    }

    function handleOnBlur() {
        const date = moment(dateStr, "DD.MM.YYYY");

        if (props.onChange && date.isValid()) {
            props.onChange(date);
        }
    }

    return (
        <BrightInput
            className={props.className}
            placeholder={props.placeholder}
            value={dateStr}
            onChange={handleDateChange}
            onBlur={handleOnBlur}
        />
    );
}
