import {Theme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/styles";
import React from "react";
import {UserModel} from "../../DB/entities/user";
import classJ from "../../util/joinClasses";
import {Row} from "./Div";
import UserAvatar from "./UserAvatar";

interface StylesProps {
    avatarSize: ComponentSize;
}

const useStyles = makeStyles<Theme, StylesProps>(({palette, spacing}: Theme) => {
    return createStyles({
        studentCardAvatar: {
            color: palette.gray.main,
            width: props => spacing(compSizeToAvatarUnits(props.avatarSize)),
            height: props => spacing(compSizeToAvatarUnits(props.avatarSize)),
            marginRight: spacing(1),
        },
        studentName: {
            fontWeight: "bold",
        },
    });
});

type ComponentSize = "small" | "medium" | "large";

function compSizeToAvatarUnits(size: ComponentSize): number {
    switch (size) {
        case "small":
            return 4;
        case "medium":
            return 4.6;
        case "large":
            return 6;
    }
    return 0;
}

function compSizeToNameVariant(size: ComponentSize) {
    switch (size) {
        case "small":
            return "body2";
        case "medium":
            return "body1";
        case "large":
            return "h6";
    }
    return "body2";
}

interface Props {
    person: UserModel;
    onAvatarClick?(person: UserModel): void;
    onRowClick?(person: UserModel): void;

    size?: ComponentSize;
    className?: string;
    userNameStyle?: string;
}

export default function UserWithAvatar(props: Props) {
    const size: ComponentSize = props.size ? props.size : "small";
    const classes = useStyles({avatarSize: size});

    function handleRowClicked() {
        if (props.onRowClick) {
            props.onRowClick(props.person);
        }
    }

    function handleAvatarClicked() {
        if (props.onAvatarClick) {
            props.onAvatarClick(props.person);
        }
    }

    return (
        <Row className={props.className} onClick={handleRowClicked}>
            <UserAvatar
                avatar={props.person.avatar}
                className={classes.studentCardAvatar}
                onClick={handleAvatarClicked}
            />
            <Typography
                variant={compSizeToNameVariant(size)}
                className={classJ(props.userNameStyle, classes.studentName)}
            >
                {props.person.name}
            </Typography>
        </Row>
    );
}
