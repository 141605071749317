import {Checkbox, FormControlLabel, Theme} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/styles";
import moment from "moment";
import React from "react";
import {DayOfTheWeek, GroupScheduleDayAttendTime} from "../../../DB/entities/groups/group";
import {getDayLocalShortString, GroupSchedule} from "../../../DB/entities/groups/groupSchedule";
import {Column, Row} from "../Div";
import LessonTime from "./LessonTime";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        checkbox: {
            width: theme.spacing(8),
        },
        row: {
            marginBottom: theme.spacing(0),
        },
    });
});

interface Props {
    schedule: GroupSchedule;

    onScheduleChange?(newSchedule: GroupSchedule): void;

    className?: string;
}

export default function Schedule(props: Props) {
    const classes = useStyles();

    const getDayScheduleChangedHandler = (day: DayOfTheWeek) => {
        function handleDayScheduleChanged(time: GroupScheduleDayAttendTime): void {
            if (props.onScheduleChange) {
                const newSchedule = props.schedule.copy();
                newSchedule.set(day, time);
                props.onScheduleChange(newSchedule);
            }
        }
        return handleDayScheduleChanged;
    };

    const getDayCheckedChangedHandler = (day: DayOfTheWeek) => {
        function handleCheckedChanged(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
            if (props.onScheduleChange) {
                const newSchedule = props.schedule.copy();
                if (checked) {
                    const firstDaySchedule = newSchedule.values().next().value;
                    const newTime = {
                        start: firstDaySchedule
                            ? firstDaySchedule.start.clone()
                            : moment()
                                  .hours(12)
                                  .minutes(0),
                        duration: firstDaySchedule ? firstDaySchedule.duration.clone() : moment.duration(1, "hours"),
                    };
                    newSchedule.set(day, newTime);
                } else {
                    newSchedule.delete(day);
                }
                props.onScheduleChange(newSchedule);
            }
        }

        return handleCheckedChanged;
    };

    const scheduleAsArray = props.schedule.getEachDayAsArray();
    const lastTimeLeft = scheduleAsArray.filter(s => s.time !== undefined).length <= 1;

    return (
        <div className={props.className}>
            <Column>
                {scheduleAsArray.map((v, i) => {
                    return (
                        <Row className={classes.row} key={i}>
                            <FormControlLabel
                                className={classes.checkbox}
                                control={
                                    <Checkbox
                                        checked={!!v.time}
                                        value="checkedB"
                                        disabled={v.time && lastTimeLeft}
                                        onChange={getDayCheckedChangedHandler(v.day)}
                                    />
                                }
                                label={getDayLocalShortString(v.day)}
                            />
                            {v.time && <LessonTime time={v.time} onChange={getDayScheduleChangedHandler(v.day)} />}
                        </Row>
                    );
                })}
            </Column>
        </div>
    );
}
