import {Divider, Theme, Typography} from "@material-ui/core";
import transitions from "@material-ui/core/styles/transitions";
import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import {Id} from "../../DB/entities/entity";
import useRouter from "../../hooks/useRouter";
import {EditProgressReport} from "../../util/routes/routes";
import {Column, Expander, Row} from "../common/Div";
import {ReportsForPeriod} from "./GroupProgressReportsView";
import {periodToString} from "./helper";

const useStyles = makeStyles(({palette, spacing}: Theme) => {
    return createStyles({
        root: {
            padding: spacing(1),
            overflow: "hidden",
            borderColor: palette.gray.main,
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: spacing(1),
            backgroundColor: palette.primaryBackground.main,
            boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            transition: transitions.create(["box-shadow"]),
            // "&:hover": {
            //     boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
            // },
        },
        icon: {
            marginRight: spacing(1),
            color: palette.gray.main,
            width: spacing(4),
            height: spacing(4),
        },
        studentRow: {
            marginTop: spacing(1),
            marginLeft: spacing(1),
        },
        studentName: {
            fontWeight: "bold",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
            },
        },
        draftText: {
            marginLeft: spacing(1),
            color: palette.secondary.main,
        },
    });
});

interface Props {
    reportsPreview: ReportsForPeriod;

    onClick?(report: ReportsForPeriod): void;
}

export default function ReportsPreview({reportsPreview, onClick}: Props) {
    const classes = useStyles();
    const router = useRouter();
    function handleClick() {
        if (onClick) onClick(reportsPreview);
    }

    const periodString = periodToString(reportsPreview.period);

    function navigateToReport(id: Id) {
        return () => {
            EditProgressReport.navigate(router, {id});
        };
    }

    return (
        <Column className={classes.root}>
            <Row onClick={handleClick}>
                <AssessmentOutlinedIcon className={classes.icon} />
                <Typography color="textSecondary">{periodString}</Typography>
            </Row>

            {reportsPreview.reports.map(reportPreview => (
                <Column key={reportPreview.report.id}>
                    <Row className={classes.studentRow}>
                        <Typography className={classes.studentName} onClick={navigateToReport(reportPreview.report.id)}>
                            {reportPreview.studentName}
                        </Typography>
                        <Expander />
                        {reportPreview.report.draft && <Typography className={classes.draftText}>draft</Typography>}
                    </Row>
                    <Divider />
                </Column>
            ))}
        </Column>
    );
}
