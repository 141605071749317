import {Theme} from "@material-ui/core";
import Day, {DayProps} from "@material-ui/pickers/views/Calendar/Day";
import {createStyles, makeStyles} from "@material-ui/styles";
import moment, {Moment} from "moment";
import React from "react";
import {GroupSchedule} from "../../../DB/entities/groups/groupSchedule";
import {LessonModel} from "../../../DB/entities/lesson";

interface LessonDayProps {
    day: Moment;
    selectedDay: Moment;
    schedule?: GroupSchedule;
    pastLessons?: LessonModel[];
    dayInCurrentMonth: boolean;
    dayProps: DayProps;
}

interface LessonStylingProps {
    onSchedule?: boolean;
    existing?: boolean;
    today?: boolean;
}

const useStyles = makeStyles<Theme, LessonStylingProps>((theme: Theme) => {
    return createStyles({
        dayContainer: (props: LessonStylingProps) => ({
            background: props.existing
                ? "rgba(141,255,139,0.5)"
                : props.onSchedule
                ? "rgba(73,236,255,0.15)"
                : "transparent",
            "& .MuiPickersDay-day": {
                color: props.today ? theme.palette.secondary.main : undefined,
            },
            "& .MuiTypography-body2": {
                fontWeight: props.today ? "bold" : undefined,
            },
        }),
    });
});
export default function LessonDay({dayProps, ...props}: LessonDayProps) {
    const existing =
        !dayProps.hidden &&
        props.pastLessons &&
        props.pastLessons.find(l => l.date.isSame(props.day, "day")) !== undefined;

    const onSchedule = !dayProps.hidden && props.schedule && props.schedule.containsDay(props.day);

    const classes = useStyles({
        existing,
        onSchedule,
        today: props.day.isSame(moment(), "day"),
    });

    return (
        <div className={classes.dayContainer}>
            <Day {...dayProps} />
        </div>
    );
}
