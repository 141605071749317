import {Theme, Toolbar} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles, useTheme} from "@material-ui/styles";
import React, {useEffect, useMemo, useState} from "react";
import {GroupId} from "../../DB/entities/entity";
import {GroupExtended} from "../../DB/entities/groups/group";
import useRouter from "../../hooks/useRouter";
import {AdminRoutes} from "../../util/routes/routes";
import BrightButton from "../common/BrightButton";
import BrightSelect from "../common/BrightSelect";
import DataTable, {Column} from "../common/DataTable";
import {Expander, Row} from "../common/Div";
import * as R from "ramda";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        toolbar: {
            display: "flex",
            flexDirection: "row",
        },
        dataCount: {
            marginLeft: theme.spacing(2),
        },
        showInactiveCheckbox: {
            marginRight: theme.spacing(4),
        },
        formControlLevel: {
            margin: theme.spacing(1),
            minWidth: theme.spacing(14),
            maxWidth: theme.spacing(14),
        },
        formControlStatus: {
            margin: theme.spacing(1),
            minWidth: theme.spacing(20),
            maxWidth: theme.spacing(20),
        },
        formControlTeacher: {
            margin: theme.spacing(1),
            minWidth: theme.spacing(22),
            maxWidth: theme.spacing(22),
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        selectInputFont: {
            fontWeight: "normal",
        },
    });
});

interface GroupDataModel {
    id: GroupId;
    name: string;
    days: string;
    time: string;
    teacher: string;
    level: string;
    book: string;
    studentCount: number;
}

interface Props {
    groups: GroupExtended[];
}

const groupColumns: Array<Column<GroupDataModel>> = [
    ["name", "Назва", true],
    ["days", "Дні", true],
    ["time", "Час", true],
    ["teacher", "Вчитель", true],
    ["level", "Рівень", true],
    ["book", "Книга", true],
    ["studentCount", "Учні", true],
];

const StatusOptions = ["Всі статуси", "Активні", "Не активні"];

export default function AdminGroups(props: Props) {
    const classes = useStyles();
    const router = useRouter();
    const theme = useTheme<Theme>();

    const [teachers, setTeachers] = useState<string[]>([]);
    const [levels, setLevels] = useState<string[]>([]);

    const [selectedStatusIndex, setSelectedStatusIndex] = React.useState<number>(0);
    const [selectedTeacherIndex, setSelectedTeacherIndex] = React.useState<number>(0);
    const [selectedLevelIndex, setSelectedLevelIndex] = React.useState<number>(0);

    function handleStatusChange(newSelectedIndex: number) {
        setSelectedStatusIndex(newSelectedIndex);
    }

    function handleLevelChange(newSelectedIndex: number) {
        setSelectedLevelIndex(newSelectedIndex);
    }
    function handleTeacherChange(newSelectedIndex: number) {
        setSelectedTeacherIndex(newSelectedIndex);
    }

    useEffect(() => {
        setTeachers(["Всі вчителі", ...R.uniq(props.groups.flatMap(g => g.teachers).map(teacher => teacher.name))]);
        setLevels(["Всі рівні", ...R.uniq(props.groups.flatMap(g => g.level))]);
    }, [props.groups]);

    const data = useMemo(() => {
        let allGroups = props.groups;
        if (selectedStatusIndex === 1) allGroups = allGroups.filter(g => !g.inactive);
        if (selectedStatusIndex === 2) allGroups = allGroups.filter(g => g.inactive);

        if (selectedLevelIndex !== 0) allGroups = allGroups.filter(g => g.level === levels[selectedLevelIndex]);
        if (selectedTeacherIndex !== 0)
            allGroups = allGroups.filter(g => g.teachers.map(t => t.name).includes(teachers[selectedTeacherIndex]));

        return allGroups.map(g => {
            return {
                id: g.id,
                name: g.name,
                days: g.schedule.getDaysLocalShortString(),
                time: g.schedule.getGroupTimeStr(),
                teacher: g.teachers.map(t => t.name).join(", "),
                level: g.level.toString(),
                book: g.studyBook,
                studentCount: g.students.length,

                color: g.inactive ? theme.palette.text.secondary : undefined,
            };
        });
    }, [
        props.groups,
        selectedStatusIndex,
        selectedLevelIndex,
        selectedTeacherIndex,
        levels,
        teachers,
        theme.palette.text.secondary,
    ]);

    const onRowClicked = (rowId: GroupId) => {
        AdminRoutes.EditGroup.navigate(router, {id: rowId});
    };

    const handleAddGroupClick = () => {
        AdminRoutes.NewGroup.navigate(router);
    };

    return (
        <div>
            <Toolbar className={classes.toolbar}>
                <Row>
                    <Typography id="qa-groups-title" variant="h5">
                        Групи
                    </Typography>
                    <Typography id="qa-groups-count" variant="h5" color="textSecondary" className={classes.dataCount}>
                        ({data.length})
                    </Typography>
                </Row>
                <Expander />
                <Row>
                    <div className={classes.formControlTeacher}>
                        <BrightSelect
                            values={teachers}
                            selectedValueIndex={selectedTeacherIndex}
                            onSelectedValueChanged={handleTeacherChange}
                            inputClassName={classes.selectInputFont}
                            className={classes.selectInputFont}
                        />
                    </div>
                    <div className={classes.formControlLevel}>
                        <BrightSelect
                            values={levels}
                            selectedValueIndex={selectedLevelIndex}
                            onSelectedValueChanged={handleLevelChange}
                            inputClassName={classes.selectInputFont}
                            className={classes.selectInputFont}
                        />
                    </div>
                    <div className={classes.formControlStatus}>
                        <BrightSelect
                            values={StatusOptions}
                            selectedValueIndex={selectedStatusIndex}
                            onSelectedValueChanged={handleStatusChange}
                            inputClassName={classes.selectInputFont}
                            className={classes.selectInputFont}
                        />
                    </div>
                    <BrightButton id="qa-add-group" variant="outlined" onClick={handleAddGroupClick}>
                        Додати групу
                    </BrightButton>
                </Row>
            </Toolbar>
            <DataTable rows={data} columns={groupColumns} onRowClicked={onRowClicked} defaultOrderBy="name" />
        </div>
    );
}
