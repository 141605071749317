import {Moment} from "moment";
import {Group} from "../DB/entities/groups/group";
import {GroupSchedule} from "../DB/entities/groups/groupSchedule";
import {LessonModel} from "../DB/entities/lesson";

export function groupBySchedule(groups: Group[]): Map<GroupSchedule, Group[]> {
    const groupsMap: Map<GroupSchedule, Group[]> = new Map<GroupSchedule, Group[]>();

    const addGroupToMap = (group: Group) => {
        for (const entry of groupsMap) {
            const schedule = entry[0];
            const value = entry[1];
            if (schedule.equalsByDays(group.schedule)) {
                value.push(group);
                return;
            }
        }
        groupsMap.set(group.schedule, [group]);
    };

    groups.forEach(value => {
        addGroupToMap(value);
    });

    return groupsMap;
}

export function sortLessons(lessons: LessonModel[]) {
    lessons.sort((l1, l2) => {
        return l1.date.isAfter(l2.date) ? 1 : -1;
    });
}

export function generatePassword(length: number): string {
    let result = "";
    const characters = "ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz23456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const EMAIL_REGEXP =
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

export function isValidEmail(email: string): boolean {
    return EMAIL_REGEXP.test(email);
}

export function minutesOfDay(m: Moment) {
    return m.minutes() + m.hours() * 60;
}

export function isSameDate(m1: Moment, m2: Moment): boolean {
    return m1.isSame(m2, "day");
}

export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
