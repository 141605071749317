import { createTheme } from '@material-ui/core/styles';

declare module "@material-ui/core/styles/createPalette" {
    interface Palette {
        alternate: PaletteColor;
        gray: PaletteColor;
        primaryBackground: PaletteColor;
        secondaryBackground: PaletteColor;
    }

    interface PaletteOptions {
        alternate?: PaletteColorOptions;
        gray?: PaletteColorOptions;
        primaryBackground?: PaletteColorOptions;
        secondaryBackground?: PaletteColorOptions;
    }
}

export default function createBrightTheme() {
    return createTheme({
        typography: {
            fontFamily: "Comfortaa, sans-serif",
        },
        palette: {
            primary: {
                main: "#09263F",
            },
            secondary: {
                main: "#ED2629",
            },
            alternate: {
                main: "#3CAE5F",
            },
            gray: {
                main: "#A0A0A0",
            },
            primaryBackground: {
                main: "#FFFFFF",
            },
            secondaryBackground: {
                main: "#E7E7E7",
            },
        },
    });
}
