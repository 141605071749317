import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {Redirect, Route, Switch} from "react-router";
import UserTopHeader from "../../components/common/UserTopHeader";
import {TeacherModel} from "../../DB/entities/teacher";
import {TeacherRoutes, EditProgressReport} from "../../util/routes/routes";
import {EditProgressReportContainer} from "../EditProgressReportContainer";
import TeacherAllGroupsContainer from "./TeacherAllGroupsContainer";
import {TeacherCreateReportContainer} from "./TeacherCreateReportContainer";
import TeacherOneGroupContainer from "./TeacherOneGroupContainer";

const useStyles = makeStyles(
    createStyles({
        root: {
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
        },
    }),
);

interface Props {
    teacher: TeacherModel;
}

export default function TeacherIndex(props: Props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <UserTopHeader mainText="Bright Teacher" person={props.teacher} />
            <Switch>
                <Route exact path={TeacherRoutes.Index.route} component={TeacherAllGroupsContainer} />

                <Route exact path={TeacherRoutes.Group.route} component={TeacherOneGroupContainer} />

                <Route exact path={TeacherRoutes.CreateReport.route} component={TeacherCreateReportContainer} />

                <Route exact path={EditProgressReport.route} component={EditProgressReportContainer} />

                <Redirect to={TeacherRoutes.Index.route} />
            </Switch>
        </div>
    );
}
