import {Document, Font, Page, StyleSheet} from "@react-pdf/renderer";
import * as React from "react";
import {Mark, markToColor, markToString} from "../../../DB/entities/reports/mark";
import {ProgressReportModel, Skills} from "../../../DB/entities/reports/report";
import {periodToString} from "../helper";
import {ReportCommentPdf} from "./ReportCommentPdf";
import ReportHeaderPdf from "./ReportHeaderPdf";
import TablePdf, {TableRow} from "./TablePdf";

Font.register({
    family: "Comfortaa",
    fonts: [
        {src: process.env.PUBLIC_URL + "/fonts/Comfortaa-Regular.ttf"},
        {src: process.env.PUBLIC_URL + "/fonts/Comfortaa-Bold.ttf", fontWeight: "700"},
    ],
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontFamily: "Comfortaa",
        color: "black",
        fontSize: 12,
    },
});

interface Props {
    report: ProgressReportModel;
    studentName: string;
}

export default function ProgressReportPdf(props: Props) {
    const {report, studentName} = props;
    const reportTitle = `${studentName} (${periodToString(report.period)})`;

    const tableRows = [
        markToTableRow(Skills.reading, report.reading),
        markToTableRow(Skills.listening, report.listening),
        markToTableRow(Skills.writing, report.writing),
        markToTableRow(Skills.speaking, report.speaking),
        markToTableRow(Skills.homework, report.homework),
        markToTableRow(Skills.participation, report.participation),
    ];

    if (report.testScore !== undefined) {
        tableRows.push({
            description: "Test score",
            secondaryDescription: "Результат тесту",
            value: `${report.testScore}%`,
        });
    }
    return (
        <Document title={reportTitle}>
            <Page style={styles.body} size="A5">
                <ReportHeaderPdf studentName={studentName} periodString={periodToString(report.period)} />
                <TablePdf rows={tableRows} />
                {report.comment ? <ReportCommentPdf text={report.comment} /> : <></>}
            </Page>
        </Document>
    );
}

function markToTableRow(value: {en: string, ua: string}, mark: Mark): TableRow {
    return {
        description: value.en,
        secondaryDescription: value.ua,
        value: markToString(mark),
        color: markToColor(mark),
    };
}
