import {Grid, Theme, Typography} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {GroupExtended} from "../../DB/entities/groups/group";
import {ProgressReportModel} from "../../DB/entities/reports/report";
import {useDb} from "../../hooks/useDb";
import {Period} from "../../util/Range";
import {isSameDate} from "../../util/Util";
import BrightButton from "../common/BrightButton";
import {Column, Expander, Row} from "../common/Div";
import {periodToString} from "./helper";
import ReportsPreview from "./ReportsPreview";

const useStyles = makeStyles(({palette, spacing}: Theme) => {
    return createStyles({
        root: {
            flexGrow: 1,
            paddingTop: spacing(2),
            paddingLeft: spacing(4),
            paddingBottom: spacing(2),
            paddingRight: spacing(4),
            background: palette.secondaryBackground.main,
        },
        toolbar: {
            marginBottom: spacing(4),
        },
        reportsContainer: {},
    });
});

interface Props {
    group: GroupExtended;
    onGenerateReportClick(): void;
}

export interface ReportsForPeriod {
    period: Period;
    reports: ProgressReportPreview[];
}

export interface ProgressReportPreview {
    report: ProgressReportModel;
    studentName: string;
}

export default function GroupProgressReportsView(props: Props) {
    const classes = useStyles();
    const [reports] = useDb(async db => {

        const allStudentReports = (await Promise.all(
            props.group.students
                .map(s => db.fetchProgressReports(s.id, props.group.id))
        )).reduce((acc, cur) => [...acc, ...cur]);

        return allStudentReports
            .reduce((acc, cur) => {
                const reportPreview = {
                    report: cur,
                    studentName: props.group.students.find(s => s.id === cur.studentId)!!.name,
                };

                const reportsForPeriod = acc.find(
                    r => isSameDate(r.period.start, cur.period.start) && isSameDate(r.period.end, cur.period.end),
                );

                if (reportsForPeriod) {
                    reportsForPeriod.reports.push(reportPreview);
                    reportsForPeriod.reports.sort((a: ProgressReportPreview, b: ProgressReportPreview) =>
                        a.studentName.localeCompare(b.studentName),
                    );
                    return acc;
                }

                return [
                    ...acc,
                    {
                        period: cur.period,
                        reports: [reportPreview],
                    },
                ];
            }, new Array<ReportsForPeriod>())
            .sort((a: ReportsForPeriod, b: ReportsForPeriod) => (a.period.start.isBefore(b.period.start) ? 1 : -1));
    }, []);

    return (
        <Column className={classes.root}>
            <Row className={classes.toolbar}>
                <Typography variant="h6">History</Typography>
                <Expander />
                <BrightButton variant="outlined" onClick={props.onGenerateReportClick}>
                    Create report
                </BrightButton>
            </Row>
            <Grid spacing={2} container className={classes.reportsContainer}>
                {reports &&
                    reports.map(r => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={periodToString(r.period)}>
                            <ReportsPreview reportsPreview={r} />
                        </Grid>
                    ))}
            </Grid>
        </Column>
    );
}
