import React, {useContext, useState} from "react";
import {RouteComponentProps} from "react-router";
import AdminStudent from "../../components/admin/AdminStudent";
import PageLoading from "../../components/common/PageLoading";
import {DbContext} from "../../context";
import {GroupExtended} from "../../DB/entities/groups/group";
import {GroupModel} from "../../DB/entities/groups/groupModel";
import {StudentModel} from "../../DB/entities/student";
import {useDb} from "../../hooks/useDb";
import {AdminRoutes} from "../../util/routes/routes";

export default function AdminNewStudentContainer(props: RouteComponentProps) {
    const database = useContext(DbContext);

    const [isLoading, setIsLoading] = useState(false);
    const [groups] = useDb(async db => {
        const teachers = await db.fetchTeachers();
        const students = await db.fetchStudents();
        const groupModels = await db.fetchGroups();

        return groupModels.map(g => {
            const groupTeachers = teachers.filter(t => g.teacherIds.includes(t.id));
            const groupStudents = students.filter(s => g.studentIds.includes(s.id));
            return new GroupExtended(g, groupTeachers, groupStudents);
        });
    }, []);

    async function handleStudentChange(s: StudentModel, studentGroups: GroupModel[]) {
        setIsLoading(true);

        const newStudent = await database.createStudent(s);
        for (const g of studentGroups) {
            await database.addStudentToGroup(g, newStudent.id);
        }

        AdminRoutes.Students.navigate(props);
    }

    return groups && !isLoading ? (
        <AdminStudent allGroups={groups} onStudentChanged={handleStudentChange} />
    ) : (
        <PageLoading />
    );
}
