import {EntityData} from "../../entities/entity";
import {GroupScheduleDayAttendTime} from "../../entities/groups/group";
import {GroupModel} from "../../entities/groups/groupModel";
import {GroupSchedule} from "../../entities/groups/groupSchedule";
import {FbGroupModelData, FbGroupScheduleDayAttendTime, ToFbData, ToModel} from "../types";
import {durationToMillis, fbTimeToMoment, millisToDuration, momentToFbTime, removeUndefinedFields} from "./helpers";

export const fbDataToGroup: ToModel<GroupModel, FbGroupModelData> = data => {
    const schedule: Record<number, GroupScheduleDayAttendTime> = {};
    for (const [day, atime] of Object.entries<FbGroupScheduleDayAttendTime>(data.schedule)) {
        schedule[parseInt(day)] = {
            start: fbTimeToMoment(atime.start),
            duration: millisToDuration(atime.duration),
        };
    }
    const res: EntityData<GroupModel> = {
        type: data.type,
        nameSuffix: data.nameSuffix,
        level: data.level,
        studentIds: data.studentIds,
        teacherIds: data.teacherIds,
        studyBook: data.studyBook,
        schedule: GroupSchedule.fromObject(schedule),
        inactive: data.inactive,
    };
    return res;
};

export const groupToFbData: ToFbData<GroupModel, FbGroupModelData> = groupData => {
    const schedule: Record<number, FbGroupScheduleDayAttendTime> = {};

    for (const [day, atime] of groupData.schedule) {
        schedule[day] = {
            start: momentToFbTime(atime.start),
            duration: durationToMillis(atime.duration),
        };
    }
    const res: FbGroupModelData = {
        type: groupData.type,
        nameSuffix: groupData.nameSuffix,
        level: groupData.level,
        studentIds: groupData.studentIds,
        teacherIds: groupData.teacherIds,
        studyBook: groupData.studyBook,
        schedule,
        inactive: groupData.inactive
    };
    return removeUndefinedFields(res);
};
