import {Box, Card, IconButton, Theme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {memo, useCallback} from "react";
import {PickByValue} from "utility-types";
import {Mark} from "../../DB/entities/reports/mark";
import {Skills} from "../../DB/entities/reports/report";
import classJ from "../../util/joinClasses";
import Assignment from "../common/Assignment";
import BrightInput from "../common/BrightInput";
import {Column} from "../common/Div";
import MarkColoredSelection from "../common/MarkColoredSelection";
import PropWithLabel from "../common/PropWithLabel";
import Splitter from "../common/Splitter";
import UserWithAvatar from "../common/UserWithAvatar";
import {ProgressReport} from "./helper";

const useStyles = makeStyles(({spacing, breakpoints}: Theme) => {
    return createStyles({
        root: {
            maxWidth: spacing(100),
            padding: spacing(2),
        },
        topSplitter: {
            alignItems: "center",
            marginBottom: spacing(2),
        },
        avatar: {
            [breakpoints.down("sm")]: {
                marginBottom: spacing(1),
            },
        },
        marksColumn: {
            marginBottom: spacing(2),
        },
        testScore: {
            marginTop: spacing(2),
        },
        testScoreText: {
            marginLeft: spacing(1),
            maxWidth: spacing(23),
        }
    });
});

type ProgressMarks = PickByValue<Omit<ProgressReport, "testScore">, Mark>;
const marksKeys = (Object.keys(Skills) as unknown) as (keyof ProgressMarks)[];

const Marks = memo(
    (props: {report: ProgressReport; readonly?: boolean; onMarkChange: (markProp: keyof ProgressMarks, value: Mark) => void}) => {
        const {report, onMarkChange} = props;
        return (
            <>
                {marksKeys.map(markProp => {
                    return (
                        <PropWithLabel key={markProp} label={Skills[markProp].en} labelProps={{color: "textSecondary"}}>
                            <MarkColoredSelection
                                selectedMark={report[markProp]}
                                onSelectedMarkChange={newMark => onMarkChange(markProp, newMark)}
                                readOnly={props.readonly}
                            />
                        </PropWithLabel>
                    );
                })}
            </>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.onMarkChange === nextProps.onMarkChange &&
            marksKeys.every(key => prevProps.report[key] === nextProps.report[key])
        );
    },
);

interface Props {
    report: ProgressReport;

    onReportChange?(newReport: ProgressReport): void;

    className?: string;
}

function StudentProgressReportCardInternal(props: Props) {
    const classes = useStyles();
    const {report, onReportChange, className} = props;
    const change = useCallback(
        (prop: keyof ProgressReport, value: ProgressReport[typeof prop]) => {
            if (onReportChange)
                onReportChange({...report, [prop]: value});
        },
        [onReportChange, report],
    );

    const handleMarkChange = useCallback(
        (markProp: keyof ProgressMarks, value: Mark) => {
            change(markProp, value);
        },
        [change],
    );

    function handleAddTestScore() {
        change("testScore", 0);
    }

    function handleTestScoreChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        const inputTestScore = parseInt(event.target.value);

        const newTestScore = isNaN(inputTestScore) ? 0 : inputTestScore;
        if (newTestScore <= 100) change("testScore", newTestScore);
    }

    function handleCommentChange(value: string) {
        change("comment", value);
    }

    return (
        <Card className={classJ(classes.root, className)}>
            <Column>
                <Splitter
                    className={classes.topSplitter}
                    leftArea={<UserWithAvatar person={report.student} size="large" className={classes.avatar} />}
                    rightArea={
                        <PropWithLabel label="Attendance" labelProps={{color: "textSecondary"}}>
                            <Typography>
                                {report.attendance.attended} / {report.attendance.all}
                            </Typography>
                        </PropWithLabel>
                    }
                />

                <Splitter
                    leftArea={
                        <Column className={classes.marksColumn}>
                            <Marks report={report} onMarkChange={handleMarkChange} />
                            <PropWithLabel className={classes.testScore}
                                           label="Test score"
                                           labelProps={{color: "textSecondary"}}>
                                {report.testScore !== undefined && report.testScore >= 0 ? (
                                    <BrightInput
                                        className={classes.testScoreText}
                                        placeholder="Test score"
                                        value={report.testScore}
                                        onChange={handleTestScoreChange}
                                    />
                                ) : (
                                    <IconButton onClick={handleAddTestScore} size="small">
                                        <AddIcon color="primary" />
                                    </IconButton>
                                )}
                            </PropWithLabel>
                        </Column>
                    }
                    rightArea={
                        <Column>
                            <Box maxWidth={400}>
                                <Assignment header="Comment:" value={report.comment} rows={12} onChange={handleCommentChange} />
                            </Box>

                        </Column>
                    }
                />
            </Column>
        </Card>
    );
}

export const StudentProgressReportCard = memo(StudentProgressReportCardInternal);
