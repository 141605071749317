import {Theme} from "@material-ui/core";
import Typography, {TypographyProps} from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/styles";
import React, {ReactNode} from "react";
import classJ from "../../util/joinClasses";
import {Row} from "./Div";

const useStyles = makeStyles(({spacing}: Theme) => {
    return createStyles({
        label: {
            marginRight: spacing(1),
            width: spacing(16),
        },
    });
});

interface Props {
    label: string;
    children: ReactNode;
    className?: string;
    required?: boolean;

    labelProps?: Partial<TypographyProps>;
    labelClassName?: string;
}

export default function PropWithLabel(props: Props) {
    const classes = useStyles();
    return (
        <Row className={props.className}>
            <Typography className={classJ(classes.label, props.labelClassName)} {...props.labelProps}>
                {props.label}:{props.required && " *"}
            </Typography>
            {props.children}
        </Row>
    );
}
