import {useCallback, useContext, useState} from "react";
import * as React from "react";
import {RouteComponentProps} from "react-router";
import PageLoading from "../components/common/PageLoading";
import EditProgressReport from "../components/reporting/EditProgressReport";
import {DbContext} from "../context";
import {ProgressReportModel} from "../DB/entities/reports/report";
import {useDb} from "../hooks/useDb";
import {ReportIdParam, TeacherRoutes} from "../util/routes/routes";

export function EditProgressReportContainer(props: RouteComponentProps<ReportIdParam>) {
    const reportId = props.match.params.id;

    const db = useContext(DbContext);

    const [isLoading, setIsLoading] = useState(false);

    const [state] = useDb(
        async db => {
            const reportModel = await db.fetchProgressReport(reportId);
            const student = await db.fetchStudent(reportModel.studentId);
            const lessons = await db.fetchLessonsByGroupId(reportModel.groupId);
            return {
                report: reportModel,
                student,
                lessons,
            };
        },
        [reportId],
    );

    const handleReportSave = useCallback(
        async (report: ProgressReportModel) => {
            setIsLoading(true);
            await db.updateProgressReport(report);
            setIsLoading(false);
            TeacherRoutes.Group.navigate(props, {id: report.groupId, view: "reports"});
        },
        [db, props],
    );

    const handleReportDelete = useCallback(async (report: ProgressReportModel) => {
        await db.deleteProgressReport(report);
        TeacherRoutes.Group.navigate(props, {id: report.groupId, view: "reports"});
    }, [db, props]);

    return state && !isLoading ? (
        <EditProgressReport
            report={state.report}
            student={state.student}
            lessons={state.lessons}
            onReportSave={handleReportSave}
            onReportDelete={handleReportDelete}
        />
    ) : (
        <PageLoading />
    );
}
