import {Theme, Typography} from "@material-ui/core";
import {Delete} from "@material-ui/icons";
import {createStyles, makeStyles} from "@material-ui/styles";
import moment, {Moment} from "moment";
import {useContext, useEffect, useState} from "react";
import * as React from "react";
import {DbContext} from "../../context";
import {Group, GroupExtended} from "../../DB/entities/groups/group";
import {LessonModel} from "../../DB/entities/lesson";
import {sortLessons} from "../../util/Util";
import BrightButton from "../common/BrightButton";
import {Row} from "../common/Div";
import IconButtonWithConfirmation from "../common/IconButtonWithConfirmation";
import LessonDatePicker from "../common/LessonDatePicker";
import LessonView from "../common/LessonView";
import PageLoading from "../common/PageLoading";

const useStyles = makeStyles(({palette, spacing}: Theme) => {
    return createStyles({
        root: {
            background: palette.secondaryBackground.main,
            flexGrow: 1,
        },
        lessonSelector: {
            boxSizing: "border-box",
            paddingTop: spacing(2),
            paddingLeft: spacing(4),
            paddingBottom: spacing(2),
            paddingRight: spacing(4),
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
        },
        createLessonButtonContainer: {
            marginTop: "1px",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",

            padding: spacing(1),
        },
        createLessonButton: {
            marginTop: spacing(2),
            marginBottom: spacing(2),
        },
        lessonNumber: {
            marginLeft: spacing(2),
            fontWeight: "bold",
        },
    });
});

interface Props {
    group: GroupExtended;

    onCreateLesson(date: Moment, group: Group): Promise<LessonModel>;
    onDeleteLesson(lesson: LessonModel): Promise<void>;
}

interface State {
    date: Moment;
    lesson: LessonModel | undefined;
    pastLessons: LessonModel[] | undefined;
}

export default function GroupLessonsView(props: Props) {
    const classes = useStyles();
    const [state, setState] = useState<State>({
        date: moment(),
        lesson: undefined,
        pastLessons: undefined,
    });

    const db = useContext(DbContext);

    useEffect(() => {
        db.fetchLessonsByGroupId(props.group.id).then(lessons => {
            sortLessons(lessons);
            setState(p => {
                let subState = {date: p.date, lesson: p.lesson};
                if (lessons.length > 0) {
                    const lastLesson = lessons[lessons.length - 1];
                    subState = {
                        date: lastLesson.date,
                        lesson: lastLesson,
                    };
                }
                return {...subState, pastLessons: lessons};
            });
        });
    }, [db, props.group]);

    function handleDateChange(date: Moment) {
        const lesson = state.pastLessons ? state.pastLessons.find(v => v.date.isSame(date, "day")) : undefined;

        setState({...state, date, lesson});
    }

    const selectedLessonNumber =
        state.pastLessons && state.lesson ? state.pastLessons.indexOf(state.lesson) + 1 : undefined;

    async function handleLessonChanged(lessonModel: LessonModel) {
        if (state.lesson) {
            const newLessonModel = await db.updateLesson(lessonModel);
            if (state.pastLessons) {
                setState({
                    ...state,
                    pastLessons: state.pastLessons.map(l => (l.id === newLessonModel.id ? newLessonModel : l)),
                    lesson: newLessonModel,
                });
            }
        }
    }

    async function handleCreateLesson() {
        const pastLessons = state.pastLessons;
        setState({
            ...state,
            pastLessons: undefined,
        });
        const newLesson = await props.onCreateLesson(state.date, props.group);
        if (pastLessons) {
            setState({
                date: newLesson.date,
                lesson: newLesson,
                pastLessons: [...pastLessons, newLesson],
            });
        }
    }

    async function handleDeleteLesson() {
        const lessonToDelete = state.lesson;
        if (lessonToDelete) {
            setState({
                ...state,
                lesson: undefined,
                pastLessons: undefined,
            });

            await props.onDeleteLesson(lessonToDelete);
            const ls = await db.fetchLessonsByGroupId(props.group.id);
            sortLessons(ls);

            setState({
                date: state.date,
                lesson: undefined,
                pastLessons: ls,
            });
        }
    }
    return (
        <div className={classes.root}>
            <div className={classes.lessonSelector}>
                <Row>
                    <LessonDatePicker
                        selectedDate={state.date}
                        onChange={handleDateChange}
                        schedule={props.group.schedule}
                        pastLessons={state.pastLessons}
                    />
                    {selectedLessonNumber && (
                        <Typography className={classes.lessonNumber}>#{selectedLessonNumber}</Typography>
                    )}
                </Row>
                <IconButtonWithConfirmation
                    id="qa-delete-button"
                    icon={Delete}
                    text={`Do you really want to delete lesson on ${state.date.format("D MMMM YYYY")}?`}
                    onClick={handleDeleteLesson}
                    disabled={!state.lesson}
                />
            </div>
            {state.pastLessons ? (
                state.lesson ? (
                    <LessonView
                        lesson={state.lesson}
                        studentsInGroup={props.group.students}
                        onLessonChanged={handleLessonChanged}
                    />
                ) : (
                    <div className={classes.createLessonButtonContainer}>
                        <BrightButton
                            variant="outlined"
                            className={classes.createLessonButton}
                            onClick={handleCreateLesson}
                        >
                            Create lesson
                        </BrightButton>
                    </div>
                )
            ) : (
                <PageLoading />
            )}
        </div>
    );
}
