import {Theme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/styles";
import moment, {Moment} from "moment";
import React, {useState} from "react";
import {GroupScheduleDayAttendTime} from "../../../DB/entities/groups/group";
import {Row} from "../Div";
import TimePicker from "./TimePicker";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        checkbox: {
            width: theme.spacing(8),
        },
        separator: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
    });
});

interface Props {
    time: GroupScheduleDayAttendTime;
    onChange(newTime?: GroupScheduleDayAttendTime): void;
}

function LessonTime({time, onChange}: Props) {
    const classes = useStyles();

    const [startTime, setStartTime] = useState(time.start);
    const [endTime, setEndTime] = useState(time.start.clone().add(time.duration));

    function handleStartTimeChange(newTime: Moment) {
        setStartTime(newTime);
        onChange({
            start: newTime,
            duration: moment.duration(endTime.diff(newTime)),
        });
    }
    function handleEndTimeChange(newTime: Moment) {
        setEndTime(newTime);
        onChange({
            start: startTime,
            duration: moment.duration(newTime.diff(startTime)),
        });
    }

    return (
        <Row>
            <TimePicker value={startTime} onChange={handleStartTimeChange} />
            <Typography className={classes.separator}>-</Typography>
            <TimePicker value={endTime} onChange={handleEndTimeChange} />
        </Row>
    );
}

export default LessonTime;
