import moment from "moment";
// @ts-ignore
import localization from "moment/locale/uk";
import React, {useContext, useEffect} from "react";
import {Redirect, Route, Switch} from "react-router";
import Action from "../components/AuthAction";
import LogIn from "../components/LogIn";
import {ResetPassword} from "../components/ResetPassword";
import {UserContext} from "../context";
import {StudentModel} from "../DB/entities/student";
import {TeacherModel} from "../DB/entities/teacher";
import {UserRole} from "../DB/entities/user";
import {AuthRoutes} from "../util/routes/routes";
import AdminIndex from "./admin/AdminIndex";
import StudentIndex from "./student/StudentIndex";
import TeacherIndex from "./teacher/TeacherIndex";

function Root() {
    const user = useContext(UserContext);
    useEffect(() => {
        if (user && (user.role === UserRole.Student || user.role === UserRole.Admin)) {
            moment.updateLocale("uk", localization);
        } else {
            moment.updateLocale("en", null);
        }
    }, [user]);

    if (!user) {
        return (
            <Switch>
                <Route exact path={AuthRoutes.LogIn.route} component={LogIn} />
                <Route exact path={AuthRoutes.ResetPassword.route} component={ResetPassword} />
                <Route path={AuthRoutes.Action.route} component={Action} />

                <Redirect to={AuthRoutes.LogIn.route} />
            </Switch>
        );
    } else {
        switch (user.role) {
            case UserRole.Teacher:
                return <TeacherIndex teacher={user as TeacherModel} />;
            case UserRole.Student:
                return <StudentIndex student={user as StudentModel} />;
            case UserRole.Admin:
                return <AdminIndex />;
            default:
                return <div />;
        }
    }
}

export default Root;
