import {Theme} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {Delete, Replay} from "@material-ui/icons";
import {createStyles, makeStyles} from "@material-ui/styles";
import {Moment} from "moment";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {GroupId} from "../../DB/entities/entity";
import {GroupExtended} from "../../DB/entities/groups/group";
import {GroupModel} from "../../DB/entities/groups/groupModel";
import {createNewStudent, StudentModel} from "../../DB/entities/student";
import {useInputValidation} from "../../hooks/useInputValidation";
import useRouter from "../../hooks/useRouter";
import {AdminRoutes} from "../../util/routes/routes";
import {isValidEmail} from "../../util/Util";
import BrightInput from "../common/BrightInput";
import DateInput from "../common/DateInput";
import IconButtonWithConfirmation from "../common/IconButtonWithConfirmation";
import {Column, Row} from "../common/Div";
import PropWithLabel from "../common/PropWithLabel";
import {SaveButtonWithText} from "../common/SaveButtonWithText";
import Splitter from "../common/Splitter";
import MultipleEntitiesSelection from "./MultipleEntitiesSelection";
import classJ from "../../util/joinClasses";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.palette.secondaryBackground.main,
        },
        mainArea: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(5),
        },
        row: {
            marginBottom: theme.spacing(2),
        },
        field: {
            width: theme.spacing(40),
        },
        fieldWithTopMargin: {
            marginTop: theme.spacing(3),
            width: theme.spacing(40),
        },
        groupName: {
            marginRight: theme.spacing(2),
            "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
            },
        },
    });
});

interface Props {
    student?: StudentModel;
    allGroups: GroupExtended[];

    onStudentChanged?(student: StudentModel, studentGroups: GroupModel[], prevStudentGroups: GroupModel[]): void;

    message?: string;

    onEdited?(): void;

    onStudentDeleted?(student: StudentModel): void;

    onStudentReset?(student: StudentModel): void;
}

export default function AdminStudent(props: Props) {
    const classes = useStyles();
    const router = useRouter();

    const [studentModel, setStudentModel] = useState(props.student ? props.student : createNewStudent());

    const {validations, isValid, validate} = useInputValidation(
        {
            email: studentModel.email,
            name: studentModel.name,
        },
        {
            name: value => (value.trim().length <= 0 ? "Треба заповнити ім'я" : undefined),

            email: value => {
                const trimmedValue = value.trim();
                if (trimmedValue.length > 0 && !isValidEmail(trimmedValue)) {
                    return "Невірний формат email-a";
                }
                return undefined;
            },
        },
    );

    const [studentGroups, setStudentGroups] = useState<GroupExtended[]>([]);

    const prevStudentGroups = useRef<GroupModel[]>([]);

    useEffect(() => {
        if (props.student) {
            const sg = props.allGroups.filter(g => g.studentIds.includes(studentModel.id));
            setStudentGroups(sg);
            prevStudentGroups.current = sg;
        } else {
            setStudentGroups(props.allGroups.slice(0, 1));
        }
    }, [props.allGroups, props.student, studentModel.id]);

    const studentTeachers = useMemo(() => {
        return studentGroups
            .map(g => g.teachers)
            .reduce((acc, cur) => {
                cur.forEach(t => {
                    if (!acc.find(st => st.id === t.id)) {
                        acc.push(t);
                    }
                });
                return acc;
            }, []);
    }, [studentGroups]);

    const [saveEnabled, setSaveEnabled] = useState(false);

    function change(studentProps: Partial<StudentModel>) {
        setStudentModel(prevState => ({
            ...prevState,
            ...studentProps,
        }));
        setSaveEnabled(true);
        if (props.onEdited) {
            props.onEdited();
        }
    }

    function handleEmailChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        change({email: event.target.value.trim()});
    }

    function handleNameChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        change({name: event.target.value});
    }

    function handlePhoneChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        change({phone: event.target.value});
    }

    function handleBirthDateChange(newDate?: Moment) {
        change({birthDate: newDate});
    }

    function handleCommentChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        change({comment: event.target.value});
    }

    const onGroupClicked = (groupId: GroupId) => {
        AdminRoutes.EditGroup.navigate(router, {id: groupId});
    };

    function handleSave() {
        if (props.onStudentChanged) {
            if (!validate()) {
                setSaveEnabled(false);
                return;
            }

            props.onStudentChanged(studentModel, studentGroups, prevStudentGroups.current);

            setSaveEnabled(false);
        }
    }

    function handleGroupsChange(newGroups: GroupId[]) {
        setStudentGroups(props.allGroups.filter(g => newGroups.includes(g.id)));
        setSaveEnabled(true);
        if (props.onEdited) {
            props.onEdited();
        }
    }

    function handleDelete() {
        if (props.onStudentDeleted && props.student) {
            props.onStudentDeleted(props.student);
        }
    }

    function handleReset() {
        if (props.onStudentReset && props.student) {
            props.onStudentReset(props.student);
        }
    }

    return (
        <div className={classes.root}>
            <Toolbar>
                <Typography variant="h5">{props.student ? "Редагувати" : "Додати студента"}</Typography>
                <SaveButtonWithText
                    disabled={!saveEnabled || !isValid}
                    buttonId={"qa-save-student-btn"}
                    message={props.message}
                    onClick={handleSave}
                />

                {props.student && (
                    <Row>
                        {props.student.authId && props.student.email && (
                            <IconButtonWithConfirmation
                                id="qa-reset-button"
                                tooltip="Перегенерувати пароль"
                                icon={Replay}
                                text={`Ви дійсно бажаєте згенерувати новий пароль для користувача (${props.student.email})?`}
                                onClick={handleReset}
                            />
                        )}
                        <IconButtonWithConfirmation
                            id="qa-delete-button"
                            icon={Delete}
                            tooltip="Видалити сдуента"
                            text="Ви дійсно бажаєте видалити студента?"
                            onClick={handleDelete}
                        />
                    </Row>
                )}
            </Toolbar>
            <Splitter
                className={classes.mainArea}
                leftArea={
                    <Column>
                        <PropWithLabel label="Ім'я" required className={classes.row}>
                            <BrightInput
                                className={classes.field}
                                autoFocus
                                id="qa-student-name"
                                placeholder="Ім'я"
                                value={studentModel.name}
                                onChange={handleNameChange}
                                {...validations.name}
                            />
                        </PropWithLabel>
                        <PropWithLabel label="Email" className={classes.row}>
                            {props.student && props.student.email ? (
                                <Typography className={classes.field}>{studentModel.email}</Typography>
                            ) : (
                                <BrightInput
                                    className={classJ(classes.field, "qa-student-email")}
                                    placeholder="Email"
                                    value={studentModel.email}
                                    onChange={handleEmailChange}
                                    {...validations.email}
                                />
                            )}
                        </PropWithLabel>
                        <PropWithLabel label="Телефон" className={classes.row}>
                            <BrightInput
                                className={classes.field}
                                placeholder="Телефон"
                                id="qa-student-phone"
                                value={studentModel.phone}
                                onChange={handlePhoneChange}
                            />
                        </PropWithLabel>
                        <PropWithLabel label="День народження" className={classes.row}>
                            <DateInput
                                className={classJ(classes.field, "qa-student-birthday")}
                                placeholder="Формат: дд.мм.рррр"
                                date={studentModel.birthDate}
                                onChange={handleBirthDateChange}
                            />
                        </PropWithLabel>
                        <PropWithLabel label={studentGroups.length > 1 ? "Групи" : "Група"} className={classes.row}>
                            <MultipleEntitiesSelection
                                className={classJ(classes.field, "qa-student-groups")}
                                selectedEntities={studentGroups.map(g => g.id)}
                                allEntities={props.allGroups}
                                onChange={handleGroupsChange}
                                allowEmptySelection
                            />
                        </PropWithLabel>
                        <PropWithLabel label="Коментар" className={classes.row}>
                            <BrightInput
                                className={classes.field}
                                id="qa-student-comment"
                                multiline
                                rows={4}
                                value={studentModel.comment}
                                onChange={handleCommentChange}
                            />
                        </PropWithLabel>
                    </Column>
                }
                rightArea={
                    studentTeachers.length > 0 && (
                        <Column>
                            <PropWithLabel
                                label={studentTeachers.length > 1 ? "Вчителі" : "Вчитель"}
                                className={classes.row}
                            >
                                <Typography className={classJ(classes.field, "qa-student-teachers")}>
                                    {studentTeachers.map(t => t.name).join(", ")}
                                </Typography>
                            </PropWithLabel>

                            <PropWithLabel label={studentGroups.length > 1 ? "Групи" : "Група"} className={classes.row}>
                                <Row>
                                    {studentGroups.map((g, index) => (
                                        <Typography
                                            key={index}
                                            onClick={() => onGroupClicked(g.id)}
                                            className={classJ(classes.groupName, "qa-group-name#" + index)}
                                        >
                                            {g.name}
                                        </Typography>
                                    ))}
                                </Row>
                            </PropWithLabel>
                        </Column>
                    )
                }
            />
        </div>
    );
}
