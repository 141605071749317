import {Button, Theme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/styles";
import React, {useState} from "react";
import {PrimaryFullPageContainer} from "../App";
import {auth} from "../init-firebase";
import SunLogo from "../resources/icons/SunLogo";
import {isValidEmail} from "../util/Util";
import BrightInput from "./common/BrightInput";

const useStyles = makeStyles(({spacing, palette}: Theme) => {
    return createStyles({
        icon: {
            marginTop: spacing(8),
            height: spacing(25),
            width: spacing(25),
        },
        input: {
            width: spacing(40),
            marginBottom: spacing(2),
        },

        buttonRoot: {
            borderRadius: spacing(1),
            borderColor: palette.primaryBackground.main,
            color: palette.primaryBackground.main,
        },
        buttonDisabled: {
            backgroundColor: palette.primaryBackground.main,
            opacity: 0.7,
        },
        error: {
            color: palette.secondary.main,
            marginBottom: spacing(2),
            maxWidth: spacing(40),
        },
        message: {
            marginBottom: spacing(2),
            color: palette.primaryBackground.main,
        },
    });
});

export function ResetPassword() {
    const classes = useStyles();

    const [state, setState] = useState({
        email: "",
        error: "",
    });

    const [emailSent, setEmailSent] = useState(false);

    function handleEmailChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        setState({
            email: event.target.value.trim(),
            error: "",
        });
    }

    const onSubmit = () => {
        auth.sendPasswordResetEmail(state.email)
            .then(() => setEmailSent(true))
            .catch(er => setState({...state, error: er.message}));
    };

    return (
        <PrimaryFullPageContainer>
            <SunLogo className={classes.icon} dark />
            {emailSent ? (
                <Typography className={classes.message}>
                    Check your email and follow instructions to finish password reset
                </Typography>
            ) : (
                <>
                    <Typography className={classes.message}>
                        Reset password link will be sent to the entered email address
                    </Typography>
                    <BrightInput
                        placeholder="Email"
                        type="email"
                        className={classes.input}
                        onChange={handleEmailChange}
                    />

                    {state.error && <Typography className={classes.error}>{state.error}</Typography>}

                    <Button
                        variant="outlined"
                        disabled={!isValidEmail(state.email)}
                        classes={{
                            root: classes.buttonRoot,
                            disabled: classes.buttonDisabled,
                        }}
                        onClick={onSubmit}
                    >
                        Submit
                    </Button>
                </>
            )}
        </PrimaryFullPageContainer>
    );
}
