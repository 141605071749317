import {EntityData} from "../../entities/entity";
import {UserModel} from "../../entities/user";
import {FbUserModelData, ToFbData, ToModel} from "../types";
import {fbTimeToMoment, momentToFbTime, removeUndefinedFields} from "./helpers";

export const fbDataToUser: ToModel<UserModel, FbUserModelData> = data => {
    const res: EntityData<UserModel> = {
        name: data.name,
        role: data.role,
        email: data.email,
        authId: data.authId,
        phone: data.phone,
        avatar: data.avatar,
        birthDate: data.birthDate ? fbTimeToMoment(data.birthDate) : undefined,
        comment: data.comment,
    };
    return res;
};

export const userToFbData: ToFbData<UserModel, FbUserModelData> = userData => {
    const res: FbUserModelData = {
        name: userData.name,
        role: userData.role,
        email: userData.email,
        authId: userData.authId,
        phone: userData.phone,
        avatar: userData.avatar,
        birthDate: userData.birthDate ? momentToFbTime(userData.birthDate) : undefined,
        comment: userData.comment,
    };
    return removeUndefinedFields(res);
};
