import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

const curProj = process.env.REACT_APP_PROJECT_ID;

const PROD_PROJ_ID = "brightproduction-fed0b";
const DEV_PROJ_ID = "brightt-8115e";

let firebaseApp;
switch (curProj) {
    case PROD_PROJ_ID:
        firebaseApp = firebase.initializeApp({
            apiKey: "AIzaSyBJLCSfXpNaKHqPevqMQoMTFBHWiidYBVk",
            authDomain: "brightproduction-fed0b.firebaseapp.com",
            databaseURL: "https://brightproduction-fed0b.firebaseio.com",
            projectId: "brightproduction-fed0b",
            storageBucket: "gs://brightproduction-fed0b.appspot.com",
            messagingSenderId: "586287233423",
            appId: "1:586287233423:web:a7adda618885427d84b3fa",
            measurementId: "G-V41VS4GX95",
        });
        break;

    case DEV_PROJ_ID:
        firebaseApp = firebase.initializeApp({
            apiKey: "AIzaSyDaSUbAEDed83452b8JDUIqo3T98qIOHMw",
            authDomain: "brightt-8115e.firebaseapp.com",
            databaseURL: "https://brightt-8115e.firebaseio.com",
            projectId: "brightt-8115e",
            messagingSenderId: "256701216494",
            appId: "1:256701216494:web:bb794034433938e9",
            storageBucket: "gs://brightt-8115e.appspot.com",
        });
        break;
    default:
        throw new Error("Unrecognized Firebase project ID");
}

export default firebaseApp;
export const firestore = firebaseApp.firestore();
export const auth = firebaseApp.auth();
export const functions = firebaseApp.functions("europe-west1");
export const storage = firebaseApp.storage();
