import {Period} from "../../../util/Range";
import {Entity, GroupId, Id, StudentId} from "../entity";
import {Mark} from "./mark";

export interface Attendance {
    attended: number;
    all: number;
}

export interface ProgressReportModel extends Entity<Id> {
    studentId: StudentId;
    groupId: GroupId;
    period: Period;
    attendance: Attendance;
    reading: Mark;
    writing: Mark;
    listening: Mark;
    speaking: Mark;
    homework: Mark;
    participation: Mark;
    testScore?: number;
    comment: string;
    draft: boolean;
}

export const Skills = {
    reading: {
        en: "Reading",
        ua: "Читання",
    },
    listening: {
        en: "Listening",
        ua: "Слухання",
    },
    writing: {
        en: "Writing",
        ua: "Письмо",
    },
    speaking: {
        en: "Speaking",
        ua: "Говоріння",
    },
    homework: {
        en: "Homework",
        ua: "Виконання д/з",
    },
    participation: {
        en: "Participation",
        ua: "Активність на уроках",
    },
};
