import {Moment} from "moment";
import * as React from "react";
import {useContext} from "react";
import {RouteComponentProps} from "react-router";
import PageLoading from "../../components/common/PageLoading";
import TeacherOneGroup, {GroupTab} from "../../components/teacher/TeacherOneGroup";
import {DbContext, UserContext} from "../../context";
import {Group, GroupExtended} from "../../DB/entities/groups/group";
import {createLessonData, LessonModel} from "../../DB/entities/lesson";
import {useDb} from "../../hooks/useDb";
import {TeacherGroupParams, TeacherGroupView} from "../../util/routes/routes";

function viewToGroupTab(view: TeacherGroupView | undefined): GroupTab | undefined {
    switch (view) {
        case "lessons":
            return GroupTab.LESSONS;
        case "reports":
            return GroupTab.REPORTS;
    }

    return undefined;
}

export default function TeacherOneGroupContainer(props: RouteComponentProps<TeacherGroupParams>) {
    const groupId = props.match.params.id;
    const view = props.match.params.view;

    const [groupWithStudents] = useDb(
        async db => {
            const groupModel = await db.fetchGroup(groupId);
            const students = await db.fetchStudentsById(groupModel.studentIds);
            const teachers = (await db.fetchTeachers()).filter(t => groupModel.teacherIds.some(tId => t.id === tId));

            return new GroupExtended(groupModel, teachers, students);
        },
        [groupId],
    );

    const user = useContext(UserContext);
    const database = useContext(DbContext);

    function handleCreateLesson(date: Moment, group: Group): Promise<LessonModel> {
        return database.createLesson(createLessonData(date, group.id, group.teacherIds));
    }

    function handleDeleteLesson(lesson: LessonModel): Promise<void> {
        return database.deleteLesson(lesson);
    }

    if (groupWithStudents) {
        if (user && !groupWithStudents.teacherIds.some(i => user.id === i)) {
            return (
                <div style={{margin: "0 auto", marginTop: "50px"}}>You don't have permissions to see this group</div>
            );
        }

        return (
            <TeacherOneGroup
                group={groupWithStudents}
                onCreateLesson={handleCreateLesson}
                onDeleteLesson={handleDeleteLesson}
                openedTab={viewToGroupTab(view)}
            />
        );
    }
    return <PageLoading />;
}
