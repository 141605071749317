import React from "react";
import AdminGroups from "../../components/admin/AdminGroups";
import PageLoading from "../../components/common/PageLoading";
import {GroupExtended} from "../../DB/entities/groups/group";
import {useDb} from "../../hooks/useDb";

function AdminGroupsContainer() {
    const [groups] = useDb(async db => {
        const teachersPromise = db.fetchTeachers();
        const groupModels = await db.fetchGroups();

        return Promise.all(
            groupModels.map(async group => {
                const students = await db.fetchStudentsById(group.studentIds);
                return new GroupExtended(
                    group,
                    (await teachersPromise).filter(t => group.teacherIds.some(tId => t.id === tId)),
                    students,
                );
            }),
        );
    }, []);

    return groups ? <AdminGroups groups={groups} /> : <PageLoading />;
}

export default AdminGroupsContainer;
