import {Theme, Typography} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/styles";
import moment, {Moment} from "moment";
import React, {useState} from "react";
import BrightInput from "../BrightInput";
import {Row} from "../Div";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        input: {
            width: theme.spacing(8),
        },
        separator: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    });
});

interface Props {
    value: Moment;
    onChange?(newValue: Moment): void;
}

export default function TimePicker(props: Props) {
    const classes = useStyles();

    const [hours, setHours] = useState(props.value.format("HH"));
    const [minutes, setMinutes] = useState(props.value.format("mm"));

    function handleHoursChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        const newValue = event.target.value;
        const h = parseInt(newValue, 10);
        if (newValue.trim() === "" || (h >= 0 && h <= 24)) {
            setHours(newValue);
        }
    }

    function handleMinutesChange(event: React.ChangeEvent<{name?: string; value: string}>) {
        const newValue = event.target.value;
        const m = parseInt(newValue, 10);
        if (newValue.trim() === "" || (m >= 0 && m <= 59)) {
            setMinutes(newValue);
        }
    }

    function handleOnBlur() {
        if (props.onChange) {
            const hour = parseInt(hours, 10);
            const minute = parseInt(minutes, 10);
            const newTime = moment()
                .hour(hour >= 0 && hour <= 24 ? hour : 0)
                .minutes(minute >= 0 && minute <= 59 ? minute : 0);
            setHours(newTime.format("HH"));
            setMinutes(newTime.format("mm"));
            props.onChange(newTime);
        }
    }

    return (
        <Row>
            <BrightInput value={hours} className={classes.input} onChange={handleHoursChange} onBlur={handleOnBlur} />
            <Typography className={classes.separator}>:</Typography>
            <BrightInput
                value={minutes}
                className={classes.input}
                onChange={handleMinutesChange}
                onBlur={handleOnBlur}
            />
        </Row>
    );
}
