import React from "react";
import AdminStudents from "../../components/admin/AdminStudents";
import PageLoading from "../../components/common/PageLoading";
import {Group} from "../../DB/entities/groups/group";
import {StudentInfo} from "../../DB/entities/student";
import {useDb} from "../../hooks/useDb";

function AdminStudentsContainer() {
    const [studentsExtended] = useDb(async db => {
        const teacherPromise = db.fetchTeachers();
        const students = await db.fetchStudents();
        return Promise.all(
            students.map(async student => {
                const studentGroups = (await db.fetchGroupsByStudentId(student.id)).map(g => new Group(g));
                return new StudentInfo(
                    student,
                    studentGroups,
                    (await teacherPromise).filter(t => studentGroups.find(g => g.teacherIds.includes(t.id))),
                );
            }),
        );
    }, []);

    return studentsExtended ? <AdminStudents students={studentsExtended} /> : <PageLoading />;
}

export default AdminStudentsContainer;
