import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/styles";
import React from "react";
import {UserModel} from "../../DB/entities/user";
import TopHeader from "./TopHeader";
import UserTopHeaderAvatar from "./UserTopHeaderAvatar";

const useStyles = makeStyles(() => {
    return createStyles({
        name: {
            color: "inherit",
        },
    });
});

interface Props {
    mainText: string;
    person?: UserModel;
}

export default function UserTopHeader({mainText, person}: Props) {
    const classes = useStyles();

    return (
        <TopHeader mainText={mainText} isLoading={!person}>
            {person && (
                <>
                    <Typography id="qa-user" className={classes.name} variant="subtitle1">
                        {person.name}
                    </Typography>
                    <UserTopHeaderAvatar user={person} />
                </>
            )}
        </TopHeader>
    );
}
