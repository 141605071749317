import {Box, Divider} from "@material-ui/core";
import React from "react";
import {Group} from "../../DB/entities/groups/group";
import {LessonModel} from "../../DB/entities/lesson";
import {StudentInfo} from "../../DB/entities/student";
import {Column} from "../common/Div";
import StudentGroupView from "./StudentGroupView";

export interface LessonsWithGroup {
    group: Group;
    lessons: LessonModel[];
}

interface Props {
    studentInfo: StudentInfo;
    studentLessonsWithGroup: LessonsWithGroup[];
}

export default function StudentMain(props: Props) {
    return (
        <>
            {props.studentLessonsWithGroup.map((groupWithLessons) => (
                <Column>
                    <Divider />
                    <StudentGroupView lessonsWithGroup={groupWithLessons} />
                    <Divider />
                    {props.studentLessonsWithGroup.length > 1 && <Box marginTop={1} marginBottom={1} />}
                </Column>
            ))}
        </>
    );
}
