import {Theme, Typography} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/styles";
import {Mark, markToColor, markToString} from "../../DB/entities/reports/mark";
import {Row} from "./Div";

const useStyles = makeStyles(({spacing}: Theme) => {
    return createStyles({
        label: {
            width: spacing(38),
        },
        progressBar: {
            width: "100%",
            border: "1px solid",
            borderRadius: spacing(1),
            borderColor: "rgba(0, 0, 0, 0.15)",
        },
        description: {
            position: "absolute",
            zIndex: 100,
            marginTop: 5,
            marginLeft: 8
        },
    });
});

interface Props {
    label: string;
    value: number;
    maxValue: number;
    description: string;
    color: string;
    className?: string;
}

export function FilledDiv(props: Props) {
    const classes = useStyles();

    const fillWidth = Math.round((100 / props.maxValue) * props.value);
    const isMaxValue = props.value === props.maxValue;

    return (
        <Row className={props.className}>
            <Typography className={classes.label}>{props.label}:</Typography>

            <div className={classes.progressBar}>
                <Typography className={classes.description}>{props.description}</Typography>
                <div
                    style={{
                        borderRadius: 7,
                        borderBottomRightRadius: isMaxValue ? 7 : 0,
                        borderTopRightRadius: isMaxValue ? 7 : 0,
                        backgroundColor: props.color,
                        height: 30,
                        width: `${fillWidth}%`,
                        opacity: 0.9,
                    }}
                />
            </div>
        </Row>
    );
}

interface MarkProps {
    mark: Mark;
    label: string;
    className?: string;
}

export function MarkFilledDiv(props: MarkProps) {
    return (
        <FilledDiv
            label={props.label}
            value={props.mark}
            maxValue={Mark.A}
            description={markToString(props.mark)}
            color={markToColor(props.mark)}
            className={props.className}
        />
    );
}
