import {GroupId, StudentId} from "../../DB/entities/entity";
import {LessonModel} from "../../DB/entities/lesson";
import {Mark} from "../../DB/entities/reports/mark";
import {Attendance, ProgressReportModel} from "../../DB/entities/reports/report";
import {StudentModel} from "../../DB/entities/student";
import {Period} from "../../util/Range";

export interface ProgressReport extends Omit<ProgressReportModel, "id" | "studentId" | "period"> {
    student: StudentModel;
}

export function createEmptyProgressReport(student: StudentModel, groupId: GroupId): ProgressReport {
    return {
        student,
        groupId,
        reading: Mark.NA,
        writing: Mark.NA,
        listening: Mark.NA,
        speaking: Mark.NA,
        homework: Mark.NA,
        participation: Mark.NA,
        comment: "",
        attendance: {all: 0, attended: 0},
        draft: true,
    };
}

export function calculateAttendance(studentId: StudentId, period: Period, lessons: LessonModel[]): Attendance {
    const lessonsDuringPeriod = lessons.filter(l => l.date.isBetween(period.start, period.end));
    const attended = lessonsDuringPeriod.reduce((acc, cur) => {
        if (cur.studentsAttended.includes(studentId)) {
            return acc + 1;
        }
        return acc;
    }, 0);

    return {
        all: lessonsDuringPeriod.length,
        attended,
    };
}

export function periodToString(period: Period): string {
    return `${period.start.format("DD.MM.YYYY")} - ${period.end.format("DD.MM.YYYY")}`;
}
