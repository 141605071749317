import MomentUtils from "@date-io/moment";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import * as moment from "moment";
import "moment/locale/en-gb";
import * as React from "react";
import {BrowserRouter} from "react-router-dom";
import styled, {ThemeProvider} from "styled-components";
import PageLoading from "./components/common/PageLoading";
import Root from "./containers/Root";
import {UserContext} from "./context";
import useAuthentication from "./hooks/useAuthantication";
import createBrightTheme from "./styles/BrightTheme";

const theme = createBrightTheme();

export const PrimaryFullPageContainer = styled.div`
    height: 100vh;
    background-color: ${p => p.theme.palette.primary.main};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
`;

function App() {
    moment.locale("en-GB");

    const authStatus = useAuthentication();

    return (
        <UserContext.Provider value={authStatus.user}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <MuiThemeProvider theme={theme}>
                    <ThemeProvider theme={theme}>
                        <BrowserRouter>
                            {authStatus.isLoading ? (
                                <PrimaryFullPageContainer>
                                    <PageLoading />
                                </PrimaryFullPageContainer>
                            ) : (
                                <Root />
                            )}
                        </BrowserRouter>
                    </ThemeProvider>
                </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        </UserContext.Provider>
    );
}
export default App;
